import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SwitchListNImage from "../../switch/SwitchListNImage";
import SearchBar from "../../search/SearchBar";
import classNames from "classnames";
import ContentThumbnail from "../../image/ContentThumbnail";
import Size from "../../utils/Size";
import {contentService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import Filter from "../../filter/Filter";
import PerfectScrollbar from 'perfect-scrollbar';
import DateToStr from "../../utils/DateToStr";
import {Loading} from "../../loading/Loading";
import ContentTypeFilter from "../../filter/ContentTypeFilter";
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import MyGroups from "./MyGroups";
import CategoryButton from "../../category/CategoryButton";
import {getErrorMessage} from "../../../helper/responseHandler";
import {CONTENT_SCHEDULE_CONFIG} from "../../../constants";

const ListView = ({multiple, contents, selectedId, selectedIds, handleSelect}) => {

    const checkSelectedContent = (contentId) => {
        if (multiple) {
            return selectedIds.find(s=> s === contentId) !== undefined
        } else {
            if (selectedIds !== undefined && selectedIds.length > 0) {
                return selectedIds[0] === contentId
            }
        }
        return false;
    }

    return (
        <table style={{width: '100%'}}>
            <colgroup>
                <col width="87px"/>
                <col width=""/>
            </colgroup>
            <tbody>
            {
                contents !== undefined && contents.length > 0 &&
                contents.map(
                    item  =>
                        <tr key={item.contentId} className={classNames({'on' : checkSelectedContent(item.contentId)})} onClick={()=>handleSelect(item.contentId, item.contentName, item.thumbFileId, item.mainFileName, item.totalSize, item.mediaType)}>
                            <td className="thum">
                                <div className="thumb_wrap">
                                    <div style={{width:'75px',height:'42px',textAlign:'center',lineHeight:'42px'}}>
                                        <ContentThumbnail id={item.thumbFileId} width={75} height={42} icon={true} contentType={item.mediaType}/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h4 className="contentName">
                                    <span className="text-overflow" style={{width:'300px'}}>{item.contentName}</span>
                                </h4>
                                <ul>
                                    <li className="supportedIcon">
                                        <SupportedDeviceIcon deviceType={item.deviceType} deviceTypeVersion={item.deviceTypeVersion} mediaType={item.mediaType}/>
                                    </li>
                                    <li><span><Size size={item.totalSize}/></span></li>
                                    <li><span><DateToStr date={item.lastModifiedDate} newLine={false}/></span></li>
                                </ul>
                            </td>
                        </tr>
                )
            }
            </tbody>
        </table>
    )
}

const ImageView  = ({multiple, contents, selectedIds, selectedId, handleSelect}) => {

    const checkSelectedContent = (contentId) => {
        if (multiple) {
            return selectedIds.find(s=> s === contentId) !== undefined
        } else {
            if (selectedIds !== undefined && selectedIds.length > 0) {
                return selectedIds[0] === contentId
            }
        }
        return false;
    }

    return (
        <div className={'thumbnailView_wrap'}>
            <ul className="clearfix mt20">
                {
                    contents !== undefined && contents.length > 0 &&
                    contents.map(
                        item =>
                            <li>
                                <div style={{cursor:'pointer'}} className={classNames('thumbview_box', {'on' : checkSelectedContent(item.contentId)})} onClick={()=>handleSelect(item.contentId, item.contentName, item.thumbFileId, item.mainFileName, item.totalSize, item.mediaType)}>
                                    <div style={{width:75,height:42,textAlign:'center'}}>
                                        <ContentThumbnail id={item.thumbFileId} width={75} height={42} icon={true} contentType={item.mediaType}/>
                                    </div>
                                    <div className="device_info"><span className="contentName">{item.contentName}</span></div>
                                    <div className="check"><span></span></div></div>
                            </li>
                    )
                }
            </ul>
        </div>
    )
}


export const Contents = ({filter, mode='content', onSelect, selectedId = undefined, selectedIds, programType = 'LFD', multiple = false, isVwlMode = false}) => {

    const {t} = useTranslation();

    const {deviceType, deviceTypeVersion} = filter;

    const [content, setContent] = useState({
        filter: filter,
        list: [],
        isFetching: false,
        loading: false,
        count: 0
    })
    const [active, setActive] = useState('ALL');

    const [end, setEnd] = useState(false);

    const [view, setView] = useState('LIST');

    const handleSelect = (id, name, thumbFileId, mainFileName, totalSize, contentType) => {
        onSelect(id, name, thumbFileId, mainFileName, totalSize, contentType);
    }

    const fetchContents = () => {
        setContent({...content, loading: true})
        contentService.fetchContentFilter(content.filter)
            .then(res => {
                setContent({
                    ...content,
                    list: content.list.concat(res.items),
                    isFetching: false,
                    loading: false,
                    count: content.count + 1,
                    totalCount: res.totalCount,
                    expirationStatus: 'VALID',
                    approvalStatus: "APPROVED",
                    filter: {
                        ...content.filter,
                        startIndex: content.filter.startIndex + res.items.length,
                    }
                })
            }).catch((e) => {
                if(e.errorCode === 408900) {
                    return;
                }

                toastr.error(getErrorMessage(e));
            });
    }

    const saveFilter = (saveFilter) => {
        const {contents, users, mediaTags, fileSizes} = saveFilter;
        const contentTypes = () => {
            if (contents !== undefined && contents.length === 1 && contents[0] === 'ALL') {
                if (isVwlMode) {
                    return ['VWL','IMAGE','MOVIE'];
                } else {
                    return CONTENT_SCHEDULE_CONFIG[programType].supportedContentFilter;
                }
            } else {
                return contents;
            }
        }

        setContent({
            ...content,
            list: [],
            loading: false,
            isFetching: false,
            count: 0, filter: {
                ...content.filter,
                startIndex: 1,
                creatorIds: users,
                tagIds: mediaTags,
                fileSizes: fileSizes,
                contentTypes: contentTypes()
            }
        })
    }

    const handleContentTypes = (selected) => {        
        setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, startIndex: 1, pages: 0, contentTypes: selected.length === 0?["none"] :selected}})
    }

    const handleListNImage = () => {
        setView(view === 'LIST' ? 'IMAGE' : 'LIST');
        resetScrollPosition();
    }

    const onSearch = (value) => {
        setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, startIndex: 1, pages: 0, searchText: value}});
    }

    const activeMenu = (menu) => {
        if (menu === 'ALL') {
            // init
            setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, startIndex: 1, pages: 0, groupId: undefined, groupType: 'ALL'}});
        }
        setActive(menu);
    }

    const selectGroup = (groupId) => {
        if (groupId) {
            resetScrollPosition();
            setContent({...content, list: [], loading: false, isFetching: false, count: 0, filter: {...content.filter, startIndex: 1, pages: 0, groupId: groupId, groupType: 'GROUPED'}});
        }
    }

    const resetScrollPosition = () => {
        const container = document.querySelector('#select_content_scroll_wrap');
        if (container) {
            container.scrollTop = 0;
        }
    }

    const onCategoryCheck = (checkedIds) => {
        setContent({...content, loading: false, isFetching: false, list:[], count: 0, filter: {...content.filter, startIndex: 1, pages: 0, categoryIds: checkedIds}})
    };

    const initScroll = useRef(false);
    const perfectScrollbar = useRef(undefined);

    useEffect(()=>{
        if(!initScroll.current) {
            const ps = new PerfectScrollbar('#select_content_scroll_wrap', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            document.querySelector('#select_content_scroll_wrap').addEventListener('ps-y-reach-end', () => {
                setEnd(true);
            });
            perfectScrollbar.current = ps;
            initScroll.current = true;
        } else {
            perfectScrollbar.current.update();

        }
    }, [content.list]);

    useEffect(()=>{
        if (content !== undefined && !content.loading && !content.isFetching && content.list !== undefined && content.list.length === 0 && content.count < 1) {
            resetScrollPosition();
            fetchContents();
        }
        if (content !== undefined && !content.loading && content.isFetching) {
            fetchContents();
        }
    }, [content]);

    useEffect(()=> {
        if (end) {
            if (content.totalCount > content.filter.startIndex) {
                setContent({
                    ...content,
                    filter: {
                        ...content.filter,
                    },
                    isFetching: true
                });
            }
            setEnd(false);
        }
    }, [end]);

    useEffect(() => {
        perfectScrollbar && perfectScrollbar.current.update();
    }, [view]);

    return (
        <div className="" style={{display: 'inline-flex'}}>
            <div className="lnb">
                <ul>
                    <li className={classNames({'on' : active === 'ALL'})} onClick={()=>activeMenu('ALL')}>
                        <a href="#" id="allContentListBtn">{t("COM_SID_ALL")}</a>
                    </li>
                    {
                        mode !== 'bgm' && mode !== 'default_content_on_frame' &&
                        <li className={classNames({'on' : active === 'MY_CONTENT'})} onClick={()=>activeMenu('MY_CONTENT')}>
                            <a href="#" id="myContentListBtn">{t("TEXT_TITLE_MY_CONTENT_VIEW_P")}</a>
                            {
                                active === 'MY_CONTENT' && <MyGroups mode={'CONTENT'} selectGroup={selectGroup} deviceType={deviceType} deviceTypeVersion={deviceTypeVersion} mediaTypes={content.filter.contentTypes}/>
                            }
                        </li>
                    }
                </ul>
            </div>
            <div className="schedule_content_wrap">
                <div className="mt10 mr10 mb10 ml20" style={{height: 30}}>
                    <div style={{float: 'left'}}>
                        <span className="space"></span>
                    </div>
                    <div className={'buttons'} style={{float: 'right', display: 'flex'}}>
                        {
                            mode !== 'bgm' && mode !== 'default_content_on_frame'
                            && <CategoryButton defaultCheckedKeys={content.filter.categoryIds || []} onCategoryCheck={onCategoryCheck}/>
                        }
                        {
                            mode !== 'bgm' && mode !== 'default_content_on_frame'
                            && <Filter id={'content_popup'} type={'content_popup'} width={750} onSaveFilter={(filter)=>saveFilter(filter)} position={{x: -300, y: 30}} mediaTypes={content.filter.contentTypes}/>
                        }
                        {
                            mode ===  'default_content_on_frame' &&
                                <ContentTypeFilter contentTypes={["MOVIE","FLASH"]} onChange={(selected)=>handleContentTypes(selected)}/>
                        }
                        {
                            mode !== 'bgm' && mode !== 'default_content_on_frame'
                            && <SwitchListNImage view={view} onClick={handleListNImage}/>
                        }
                        <SearchBar width={150} onClickSearch={onSearch} placeholder={t("TEXT_CONTENT_NAME_P")}/>
                    </div>
                </div>
                <div className="pop_list" id={'select_content_scroll_wrap'} style={{height:365, borderTop: '1px solid #e7e7e7', display: 'block', position: 'absolute', width: '100%'}}>
                    {
                        content.loading &&
                        <div style={{display: 'flex', justifyContent: 'center'}}><Loading /></div>

                    }
                    {
                        view === 'LIST' &&
                            <ListView multiple={multiple} contents={content.list} selectedIds={selectedIds} selectedId={selectedId} handleSelect={handleSelect}/>
                    }
                    {
                        view === 'IMAGE' &&
                            <ImageView multiple={multiple} contents={content.list} selectedIds={selectedIds} selectedId={selectedId} handleSelect={handleSelect}/>
                    }

                </div>
            </div>
        </div>
    )

}