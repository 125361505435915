import React, {useEffect, useState} from 'react';
import './Dashboard.css';
import {useTranslation} from "react-i18next";
import {settingService} from "../../services";
import PrivacyPolicyFooter from "../../components/link/PrivacyPolicyFooter";
import {settingConstants} from "../../constants";
import {isEmpty} from 'lodash';
import DashboardContainer from "./DashboardContainer";

const Dashboard = (props) => {

    const {t} = useTranslation();

    const [privacyPolicyList, setPrivacyPolicyList] = useState('');
    const [privacyPolicySettings, setPrivacyPolicySettings] = useState('');

    useEffect(()=> {
        fetchPrivacyPolicy();
    }, []);

    const fetchPrivacyPolicy = () => {
        settingService.fetchServerSettings().then(res => {

            if(!isEmpty(res) && !isEmpty(res.items)){
                setPrivacyPolicySettings(res.items.privacyPolicy);
            }
        }).catch(err => console.log(err));

        const privacyPolicyList = localStorage.getItem(settingConstants.PRIVACY_POLICY);
        if (isEmpty(privacyPolicyList)) {
            settingService.fetchPrivacyPolicyList().then(res => {
                if(!isEmpty(res) && !isEmpty(res.items)){
                    setPrivacyPolicyList(res.items);
                    localStorage.setItem(settingConstants.PRIVACY_POLICY,res.items);
                }

            }).catch(err => console.log(err));
        }else{
            setPrivacyPolicyList(JSON.parse(privacyPolicyList));
        }

    };

    return (
        <div className="dashboardWrap">
            <div className="btnWrap">
                <DashboardContainer />

                <div className='copyRight'>
                    Copyright @ 2009 - 2021 Samsung Electronics Co., Ltd.<br/>
                    {window.globalConfig.magicInfoFrontEndVersion}&nbsp;|&nbsp;
                    {
                        privacyPolicyList && <><PrivacyPolicyFooter login={true} privacyPolicyList={privacyPolicyList} privacyPolicySettings={privacyPolicySettings}/></>
                    }
                    <a href="https://displaysolutions.samsung.com/about-us/contact-us" target="_blank">{t('MIS_SID_CONTACT_US')}</a>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
