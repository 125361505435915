import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {deviceService, settingService, userService} from "../../../services";
import {useTranslation} from "react-i18next";
import {isEmpty} from 'lodash';
import Select from "../../selectbox/Select";
import SearchBar from "../../search/SearchBar";
import ReactTable from "react-table";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import {commonConstants, contentConstants, settingConstants} from "../../../constants";
import Checkbox from "../../checkbox/Checkbox";
import TagNameCell from "./TagNameCell";
import TagValueSelectCell from "./TagValueSelectCell";
import bullet2Icon from "../../../images/icon/bullet_2.png";
import {SearchButton} from "../../button/SearchButton";
import {useMISOpt} from "../../misopt";

const TagSettingView = ({tagListType, reload = false, showDevice, devices, showTagValue, selectedTags, onChangeTagInfo, size='medium', onChangeDevices, updateSelectedTags, resizable = false}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misOption} = useMISOpt();
    const needToUpdate = useRef(false);

    if (size === 'small') {
        updateSelectedTags = () => {};
    }

    const [filter, setFilter] = useState({
        organId: 'all',
        searchText: '',
        startIndex: 1,
        pageSize: 1000
    });

    const [data, setData] = useState({
        loading: false,
        tagList: [],
        selectedTagList: selectedTags || [],
    });

    const [deviceGroupIds, setDeviceGroupIds] = useState([]);

    const [organizationList, setOrganizationList] =  useState([]);
    
    const [checkBox, setCheckBox] = useState({
        checkBox: [],
    });

    const handleClickOutside = (event, tagList) => {
        if (event.target.className === 'tag_condition_btn' ||
            event.target.className === 'checkCondition_add' ||
            event.target.className === 'tag_condition_title' ||
            event.target.localName === 'label' ||
            event.target.localName === 'button' ||
            event.target.localName === 'input') {
            return;
        }

        tagList.map(tag => {tag.showCondition = false;});
        setData({...data, tagList: tagList});
    };

    const fetchTags = () => {
        setData({...data, loading: true});
        Promise.all([
            userService.fetchOrganizations(),
            settingService.fetchTags({
                organId: filter.organId,
                startIndex: filter.startIndex,
                pageSize: filter.pageSize,
                searchText: filter.searchText,
                type: tagListType
            }),
        ]).then(values => {
            let orgList = [];
            orgList.push({value: 'all', title: t('COM_SID_ALL')});
            orgList = orgList.concat(values[0].items.filter(item => item.groupId !== 0).map(org => {
                    return {value: org.organizationId, title: org.groupName};
                })
            );


            const tagList = values[1].items.map(tag => {

                const isSelected = selectedTags && selectedTags.find((selectedTag) => selectedTag.tagId === tag.tagId);
                let tagConditionTitle = '';
                let defaultBooleanTagInfo = {};

                if (tag.tagType === contentConstants.TAG_TYPE_BOOLEAN) {
                    if (isSelected) {
                        tagConditionTitle = isSelected.tagConditionTitle === t("MIS_TEXT_TRUE_P") ? t("MIS_TEXT_TRUE_P") : t("MIS_TEXT_FALSE_P");
                        defaultBooleanTagInfo = {
                            tagId: tag.tagId,
                            tagConditionId: -3,
                            tagCondition: isSelected.tagConditionTitle === t("MIS_TEXT_TRUE_P"),
                        };
                    } else {
                        defaultBooleanTagInfo = {
                            tagId: tag.tagId,
                            tagConditionId: -3,
                            tagCondition: false,
                        };
                    }
                } else {
                    if (isSelected) {
                        tagConditionTitle = isSelected.tagConditionTitle;
                    }
                }

                return {
                    isSelected: isSelected ? true : false,
                    tagId: tag.tagId,
                    tagName: tag.tagName,
                    tagValue: tag.tagValue,
                    tagType: tag.tagType,
                    tagDesc: tag.tagDesc,
                    tagConditionList: [],
                    showCondition: false,
                    tagConditionTitle: tagConditionTitle,
                    contentTagConditions: tag.tagType === contentConstants.TAG_TYPE_BOOLEAN ? [defaultBooleanTagInfo] : []
                }
            });

            setData({...data, selectedTagList : selectedTags, tagList: tagList, loading: false});
            setOrganizationList(orgList);
            document.addEventListener('click', (evt) => {handleClickOutside(evt, tagList)}, true);
            updateSelectedTags(selectedTags);

        }).catch(
            error => console.log(error)
        );
    }

    useEffect(() => {
        fetchTags();
    }, [filter]);

    useEffect(()=> {
        fetchTags();
    }, [reload])

    const parseTagType = (tagType) => {
        let parsedTagType = '';
        switch (tagType) {
            case 0:
                parsedTagType = t('DID_LFD_TEXT');
                break;
            case 1:
                parsedTagType = t('COM_ADMIN_CONTENT_TYPEINFO_CONTENTS_LIST_JSP_NUMBER');
                break;
            case 2:
                parsedTagType = t('MIS_TEXT_TRUE_OR_FALSE_P');
                break;

        }
        return parsedTagType;
    };

    const renderCheckCell = ({original}) => {
        const isSelected = selectedTagList.find(tag => tag.tagId.toString() === original.tagId.toString()); // original.tagId 가 숫자형으로 들어올때가 있에 대한 방어코드
        return (
            <Checkbox id={'ContentDetailTag_'+original.tagId} classname="table" name="check" checked={isSelected}
                      ref={ref => checkBox.checkBox[original.tagId] = ref}
                      onChange={() => toggleRow(original)}/>
        );
    };

    const toggleRow = originalTag => {
        if(checkBox.checkBox.length > 0) {
            if (checkBox.checkBox[originalTag.tagId].checked) {
                if(selectedTagList.find(tagId => tagId === originalTag.tagId) === undefined) {
                    originalTag.isSelected = true;
                    selectedTagList.push(originalTag);
                }
                tagList.map((tag) => {
                    if (tag.tagId === originalTag.tagId) {
                        tag.isSelected = true;
                    }
                });
            } else {
                originalTag.isSelected = false;
                selectedTagList.splice(selectedTagList.findIndex(tag => tag.tagId === originalTag.tagId), 1);
                tagList.map((tag) => {
                    if (tag.tagId === originalTag.tagId) {
                        tag.showCondition = false;
                        tag.isSelected = false;
                        tag.tagConditionTitle = '';
                        tag.contentTagConditions.map(condition =>  condition.checkCondition = false);
                    }
                });
            }

            tagList.map(tag => {tag.showCondition = false;});

            setData({...data, tagList: tagList, selectedTagList: selectedTagList});

            updateSelectedTags(selectedTagList);

            onChangeTagInfo && onChangeTagInfo(selectedTagList);

            if (!needToUpdate.current) {
                needToUpdate.current = true;
            }
        }
    };

    const renderTagName = ({original}) => <TagNameCell tag={original}/>;

    const renderTagType = ({original}) => <div>{parseTagType(original.tagType)}</div>;

    const renderSelectCell = ({original}) => {
        let selectedTagList = data.selectedTagList;
        return <TagValueSelectCell mode={'popup'} tagListType={tagListType === 'variable' ? 'variable' : ''}
                                   originalTag={original} tagList={tagList} selectedTagList={selectedTagList}
                                   onChangeCondition={onChangeSelectedTagListCondition}
                                   onChangeTagList={onChangeTagListCondition}
                                   useAdd={size === 'small'}
                                   useSelectAll={size !== 'small' && tagListType === 'media'}
        />
    };

    const onChangeSelectedTagListCondition = (changedSelectedTagList) => {
        setData({...data, selectedTagList: changedSelectedTagList});

        updateSelectedTags(changedSelectedTagList);
        onChangeTagInfo && onChangeTagInfo(changedSelectedTagList);
    };

    const onChangeTagListCondition = (changedTagList) => {
        setData({...data, tagList: changedTagList});
       
    };

    const renderViewCell = (row) => {
        return (
            <button className='content_tag_view_btn' onClick={() => {
                dispatch(popupAction.addPopup({
                    type: settingConstants.VIEW_TAG_MIND_MAP_POPUP,
                    id: settingConstants.VIEW_TAG_MIND_MAP_POPUP,
                    title : tagListType === 'variable' ? t('MIS_SID_VARIABLE_TAG_STATUS') : t('MIS_SID_MEDIA_TAG_STATUS'),
                    tagId: row.original.tagId,
                    onClose: ()=>dispatch(popupAction.closePopup(settingConstants.VIEW_TAG_MIND_MAP_POPUP))
                }));
            }}/>
        );
    };

    const onDeleteDevice = e => {
        const deviceId = e.target.getAttribute('data-device-id');
        const newDevices = [...devices];
        newDevices.splice(newDevices.findIndex(d => d.deviceId === deviceId), 1);
        onChangeDevices && onChangeDevices(newDevices);
    };

    const {tagList, selectedTagList, loading} = data;
    let width = 1000;
    if(!showDevice) {
        width = (size === 'small' ? 500 : 782);
    }

    const bodyWidth = width - 25;
    const tagWidth = showDevice ? '660px' : '95%';

    const tableColumnsWithValue = useMemo(() => [
        {id: 'checkbox', width: 31, sortable: false, Cell: renderCheckCell},
        {Header: t('MIS_TEXT_TAG_NAME_P_KR_MIS20'),width: 166, sortable: false, resizable: false, Cell: renderTagName},
        {Header: t('MIS_SID_TAG_TYPE'),width: 100, sortable: false, resizable: false, Cell: renderTagType, show: tagListType === 'media'},
        {Header: t('MIS_TEXT_TAG_VALUE_P'),width: 160, sortable: false, resizable: false, Cell: renderSelectCell, show: showTagValue},
        {Header: t('TEXT_DESCRIPTION_P'), sortable: false, resizable: false, accessor: 'tagDesc'},
        {Header: t('BUTTON_VIEW_P'),width: 50, sortable: false, resizable: false, Cell: renderViewCell}
    ], [tagList, selectedTagList]);

    const tableColumnsWithSmallSize = useMemo(() => [
        {id: 'checkbox', width: 46, sortable: false, resizable: false, Cell: renderCheckCell},
        {Header: t('MIS_TEXT_TAG_NAME_P_KR_MIS20'),width: 116, sortable: false, resizable: false, Cell: renderTagName},
        {Header: t('MIS_SID_TAG_TYPE'),width: 112, sortable: false, resizable: false, Cell: renderTagType},
        {Header: t('MIS_TEXT_TAG_VALUE_P'),width: 206, sortable: false, resizable: false, Cell: renderSelectCell}
    ], [tagList, selectedTagList]);


    const onSelectChange = (e, selectedValue) => {
        setFilter({...filter, organId: selectedValue});
    };

    const onChangeSearchBar = (searchText) => {
        setFilter({...filter, searchText: searchText});
    };

    const onClickDeviceGroup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,
            type: commonConstants.DEVICE_GROUP_SELECTION,
            mode: 'device',
            checkbox: true,
            checkedKeys: deviceGroupIds,
            expandedKeys: deviceGroupIds,
            allEnabled: true,
            tagAssign: true,
            priority : 0,
            save: groups => {
                if(isEmpty(groups)) {
                    return;
                }

                const groupIds = groups.map(group => group.groupId);

                setDeviceGroupIds(groupIds);

                deviceService.fetchDeviceFilter({groupIds, pageSize: 1000}).then(res => {
                    if(res.items) {
                        onChangeDevices && onChangeDevices(res.items.filter(item => {
                            const deviceOption = misOption(item.deviceType, item.deviceTypeVersion);
                            return deviceOption && deviceOption.deviceInfo && deviceOption.deviceInfo.deviceTagMapping;
                        }));
                    }
                    dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION));
                }).catch(error => console.log(error));
            },
            close: () => dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION)),
        }));
    };

    const noDataText = t('MESSAGE_COMMON_NO_DATA_P');

    const getTrGroupProps = useCallback((state, rowInfo) => {
        if (rowInfo && rowInfo.original) {
            return {
                style: {
                    background: rowInfo.original.isSelected === true ? '#e6f2ff' : 'white'
                }
            }
        }else{
            return {}
        }
    },[tagList]);

    return (
        <div className='tag_setting_popup_wrap' style={{width: bodyWidth}}>
            {size === 'small' &&
                <div className='content_tag_wrap'>
                    { selectedTags && selectedTags.map((tag, index) => {
                        return <div className='tag_round_wrap'>
                            <input type='text' className='tag_round' disabled={true} readOnly={true} id={tag.tagId} value={tag.tagName} title={tag.tagName}/>
                        </div>
                    })}
                </div>
            }
            <div className={'tag_setting_body ' + (size === 'small' ? 'height_small' : 'mt10')}>
                {
                    showDevice &&
                    <div style={{marginLeft: 0,float:'left'}}>
                        <div style={{lineHeight: '40px',height:40, display: 'flex'}}>
                            <div >{t("TEXT_DEVICE_P")}</div>
                            <div style={{marginLeft: 30, marginTop: -7}}><SearchButton onClick={onClickDeviceGroup}/></div>
                        </div>
                        <div className={'tag_setting_device_list'} style={{height: (size === 'medium' ? '370px' : '270px'), width: (size === 'small' ? '490px' : '')}}>
                            <ul className='device_list_data'>
                            {
                                devices &&
                                devices.map(device => {
                                    return (
                                        <li key={device.deviceId}>
                                            <div className='device_name'>{`${device.deviceName} (${device.deviceId})`}</div>
                                            <div className="delete_btn" data-device-id={device.deviceId} onClick={onDeleteDevice}></div>
                                        </li>
                                    );
                                })
                            }
                            </ul>
                        </div>
                    </div>
                }
                <span className='tag_setting_tag_list' style={{width: tagWidth}}>

                    <div className={size === 'medium' ? 'leftButton' : 'small'} style={{display: 'flex', height: 40, lineHeight: '40px'}}>

                        <div style={{lineHeight: '25px', flexRow: 1}}>
                            {
                                !showDevice && <span><img src={bullet2Icon}/></span>
                            }
                            {t('TABLE_ORGANIZATION_P')}
                        </div>
                        <div style={{marginLeft: 20, flexGrow: 1}}>
                            <Select span1Style={{width: 220, display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                                    width={220}
                                    optionStyle={{zIndex: 2}}
                                    selects={organizationList} value={filter.organId} defaultTitle={'All'} onChange={onSelectChange}/>
                        </div>
                        {
                            showDevice &&
                            <div>
                                <SearchBar width={157} onClickSearch={onChangeSearchBar} placeholder={t("MIS_TEXT_TAG_NAME_P_KR_MIS20")} grayBorder={true}/>
                            </div>
                        }

                    </div>

                    <ReactTable
                        className={'-striped -highlight'}
                        loading={loading}
                        data={tagList}
                        style={{height: (size === 'medium' ? '370px' : '270px'), width: (size === 'small' ? '490px' : '')}}
                        minRows={0}
                        columns={size === 'medium' ? tableColumnsWithValue : tableColumnsWithSmallSize}
                        manual
                        showPagination={false}
                        resizable={resizable}
                        noDataText={loading ? '' : noDataText}
                        getTrGroupProps={getTrGroupProps}
                    />
                </span>
            </div>
        </div>
    );
};

TagSettingView.defaultProps = {
    tag: {},
    tagList: []
};

export default React.memo(TagSettingView);