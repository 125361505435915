import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import classNames from 'classnames';
import {get, isEmpty, isNil, toInteger, isArray, padStart} from 'lodash';
import RadioGroup from '../../../radio/RadioGroup';
import NumberInput from '../../../input/NumberInput';
import Checkbox from '../../../checkbox/Checkbox';
import Select from '../../../selectbox/Select';
import {deviceOptions} from '../../../misopt';
import {isAllNil} from '../../../../helper';
import TextInput from '../../../input/TextInput';
import {
    EditPresetAdvancedOsdDisplay,
    EditPresetCustomLogo,
    EditPresetWebBrowserUrl
} from '../preset/EditPresetDisplayPanel';
import WhiteButton from '../../../button/WhiteButton';
import TimeSelect from '../../../selectbox/TimeSelect';
import moment from 'moment';
import {APLAYER, LEDBOX, RLEDBOX, SIGNAGE, SIGNAGE_CHILD} from '../../../../constants';
import {isSupportFeature} from '../../../../helper/device/isSupportFeature';
import {getDeviceMessage} from '../../../../helper/device/getDeviceMessage';
import {commonConstants} from "../../../../constants";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../../actions";

const timeFormat = 'hh:mmA';
const displayTimeFormat = 'hh:mm A';
const defaultTime = '12:00AM';
const rOnOffOptions = deviceOptions.onOffOptions.slice().reverse();
const advancedResetOption1 = deviceOptions.advancedReset.slice(0, 3);
const advancedResetOption2 = deviceOptions.advancedReset.slice(3);

const EditWebBrowserUrl = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {webBrowserUrl} = {}, changed, responseWarning = {}} = displayInfo || {};
    const {webBrowserInterval, webBrowserZoom, webBrowserHomepage, webBrowserPageurl} = webBrowserUrl || {};

    const onChangeWebBrowserUrl = (e, value) => {
        onChange(e, value);
        onChange({'data-name': 'webBrowserUrl.webBrowserChanged'}, true);
    };

    return (
        <>
            {
                webBrowserUrl &&
                <>
                    <tr>
                        <th className={classNames({'changed': isEmpty(changed['webBrowserUrl']) !== true})}>{t('COM_TV_SID_WEB_BROWSER')}</th>
                        <td className={classNames({
                            'changed': get(changed, 'webBrowserUrl.webBrowserInterval'),
                            'response_warning': !isNil(get(responseWarning, 'webBrowserUrl.webBrowserInterval'))
                            })}>{t('TEXT_TITLE_REFRESH_INTERVAL_P')}</td>
                        <td>
                            <Select width={200} propertyName='webBrowserUrl.webBrowserInterval' selects={deviceOptions.webBrowserRefreshIntervalOptions} multiLang={true}
                                onChange={onChangeWebBrowserUrl} value={webBrowserInterval} />
                        </td>
                    </tr>
                    <tr>
                        <th></th>
                        <td className={classNames({
                            'changed': get(changed, 'webBrowserUrl.webBrowserZoom'),
                            'response_warning': !isNil(get(responseWarning, 'webBrowserUrl.webBrowserZoom'))
                            })}>{t('COM_DID_LFD_ZOOM')}</td>
                        <td>
                            <Select width={200} propertyName='webBrowserUrl.webBrowserZoom' selects={deviceOptions.zoomOptions} onChange={onChangeWebBrowserUrl}
                                value={webBrowserZoom} />
                        </td>
                    </tr>
                    <tr>
                        <th></th>
                        <td className={classNames({
                            'changed': get(changed, 'webBrowserUrl.webBrowserHomepage'),
                            'response_warning': !isNil(get(responseWarning, 'webBrowserUrl.webBrowserHomepage'))
                            })}>{t('COM_HOME_PAGE')}</td>
                        <td>
                            <RadioGroup selects={deviceOptions.homePage} propertyName='webBrowserUrl.webBrowserHomepage' onChange={onChangeWebBrowserUrl} 
                                value={webBrowserHomepage} />
                        </td>
                    </tr>
                    {
                        webBrowserHomepage == 1 &&
                        <tr>
                            <th></th>
                            <td className={classNames({
                                'changed': get(changed, 'webBrowserUrl.webBrowserPageurl'),
                                'response_warning': !isNil(get(responseWarning, 'webBrowserUrl.webBrowserPageurl'))
                                })}>{t('COM_LIST_TITLE_URL')}</td>
                            <td>
                                <TextInput propertyName='webBrowserUrl.webBrowserPageurl' onChange={onChangeWebBrowserUrl} value={webBrowserPageurl} />
                            </td>
                        </tr>
                    }
                </>
            }
        </>
    );
};

const EditAdvancedOsdDisplay = ({displayInfo, onChange}) => {
    const selectRef = useRef();
    const radioRef = useRef();
    const {t} = useTranslation();
    const {data: {deviceModelCode, miscOsd, advancedOsdDisplayType} = {}, changed, responseWarning = {}} = displayInfo || {};

    const {advOsdChanged = '0', advSourceOsd, advNotOptimimumModeOsd, advNoSignalOsd, advMdcOsd, advDownloadStatusOsd} = advancedOsdDisplayType || {};
    let {advOsdChangedStatus} = advancedOsdDisplayType || {};

    let osdDisplayOptions = deviceOptions.osdDisplayType;
    !advDownloadStatusOsd && (osdDisplayOptions = osdDisplayOptions.filter(function(val){
        if(val.value !== '4') return val;
    }));

    if(parseInt(deviceModelCode || '0') <= 55) {
        return null;
    }

    const optionValueArr = [advSourceOsd, advNotOptimimumModeOsd, advNoSignalOsd, advMdcOsd, advDownloadStatusOsd];
    advOsdChangedStatus = advOsdChangedStatus || optionValueArr[advOsdChanged*1];
    
    const onChangeOsdDisplayType = (e, type) => {
        onChange && onChange(e, type);
        if(e.target.getAttribute('data-name') == 'advancedOsdDisplayType.advOsdChanged'){
            let event = new Event('change', {bubbles:true});
            event['data-name'] = 'advancedOsdDisplayType.advOsdChangedStatus';
            onChange && onChange(event, radioRef.current.getAttribute('data-value'));
        }
        if(e.target.getAttribute('data-name') == 'advancedOsdDisplayType.advOsdChangedStatus'){
            let event = new Event('change', {bubbles:true});
            event['data-name'] = 'advancedOsdDisplayType.advOsdChanged';
            onChange && onChange(event, selectRef.current.getAttribute('data-value'));
        }
    }
    
    return (
        <>
            {
                advOsdChangedStatus && 
                <>
                <tr>
                    <th className={classNames({'changed': get(changed, 'advancedOsdDisplayType.advOsdChanged') || get(changed, 'advancedOsdDisplayType.advOsdChangedStatus')})}>{t('COM_DID_LFD_OSD_DISPLAY_TYPE')}</th>
                    <td colSpan='2'>
                        <Select ref={selectRef} width={200} propertyName='advancedOsdDisplayType.advOsdChanged' selects={osdDisplayOptions} value={advOsdChanged} onChange={onChangeOsdDisplayType} />                    
                    </td>
                </tr>
                <tr>
                    <th></th>
                    <td colSpan="2">
                        <RadioGroup ref={radioRef} propertyName='advancedOsdDisplayType.advOsdChangedStatus' selects={deviceOptions.onOffOptions} value={advOsdChangedStatus} onChange={onChangeOsdDisplayType} disabled={!advOsdChanged} />
                    </td>
                </tr>
                {
                    !isNil(advSourceOsd) &&
                    <tr>
                        <th className={classNames({'response_warning':!isNil(get(responseWarning, 'advancedOsdDisplayType.advSourceOsd'))})}>{t('DID_LFD_SOURCE_OSD')}</th>
                        <td colSpan='2'>
                            {advSourceOsd == '0' ? t('BUTTON_OFF') : t('TEXT_ON_P')}
                        </td>
                    </tr>
                }
                {
                    !isNil(advNotOptimimumModeOsd) &&
                    <tr>
                        <th className={classNames({'response_warning':!isNil(get(responseWarning, 'advancedOsdDisplayType.advNotOptimimumModeOsd'))})}>{t('TEXT_NOT_OPTIMUM_MODE_OSD_P')}</th>
                        <td colSpan='2'>
                            {advNotOptimimumModeOsd == '0' ? t('BUTTON_OFF') : t('TEXT_ON_P')}
                        </td>
                    </tr>
                }
                {
                    !isNil(advNoSignalOsd) &&
                    <tr>
                        <th className={classNames({'response_warning':!isNil(get(responseWarning, 'advancedOsdDisplayType.advNoSignalOsd'))})}>{t('COM_DID_LFD_NO_SIGNAL_OSD')}</th>
                        <td colSpan='2'>
                            {advNoSignalOsd == '0' ? t('BUTTON_OFF') : t('TEXT_ON_P')}
                        </td>
                    </tr>
                }
                {
                    !isNil(advMdcOsd) &&
                    <tr>
                        <th className={classNames({'response_warning':!isNil(get(responseWarning, 'advancedOsdDisplayType.advMdcOsd'))})}>{t('COM_DID_LFD_MDC_OSD')}</th>
                        <td colSpan='2'>
                            {advMdcOsd == '0' ? t('BUTTON_OFF') : t('TEXT_ON_P')}
                        </td>
                    </tr>
                }
                {
                    !isNil(advDownloadStatusOsd) &&
                    <tr>
                        <th className={classNames({'response_warning':!isNil(get(responseWarning, 'advancedOsdDisplayType.advDownloadStatusOsd'))})}>{t('COM_LFD_SID_DOWNLOAD_STATUS_MESSAGE')}</th>
                        <td colSpan='2'>
                            {advDownloadStatusOsd == '0' ? t('BUTTON_OFF') : t('TEXT_ON_P')}
                        </td>
                    </tr>
                }
                </>
            }
        </>
    );
};

const EditCustomLogo = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {customLogo} = {}, changed, responseWarning = {}} = displayInfo || {};
    const {type, displayTime, fileType} = customLogo || {};

    const customLogoDisplayTimeOptions = [
        {value: 3, title: getDeviceMessage('3_seconds', t)},
        {value: 5, title: getDeviceMessage('5_seconds', t)},
        {value: 7, title: getDeviceMessage('7_seconds', t)}
    ];

    const customLogoOptions = [
        {value: "0", title: "BUTTON_OFF"},
        {value: "1", title: "TEXT_IMAGE_P", hide: fileType === '01'},
        {value: "2", title: "COM_SID_VIDEO", hide: fileType === '10'}
    ];

    const onChangeCustomLogo = (e, value) => {
        onChange(e, value);
        onChange({'data-name': 'customLogo.changed'}, true);
    };

    return (
        <>
            {
                customLogo &&
                <>
                    {
                        fileType === undefined || fileType === '00'?
                        <tr>
                            <th>{t('COM_PJT_IDS_SETUP_14')}</th>
                            <td colSpan='2'>
                                {t('COM_SID_NOT_AVAILABLE_KR_USE')}
                            </td>
                        </tr> :
                        <>
                            <tr>
                                <th className={classNames({
                                    'changed': get(changed, 'customLogo.type'),
                                    'response_warning': !isNil(get(responseWarning, 'customLogo.type'))
                                    })}>{t('COM_PJT_IDS_SETUP_14')}</th>
                                <td colSpan='2'>
                                    <RadioGroup propertyName='customLogo.type' selects={customLogoOptions} value={type} onChange={onChangeCustomLogo} />
                                </td>
                            </tr>
                            {
                                fileType !== '01' && type == 1 &&
                                <tr>
                                    <th></th>
                                    <td className={classNames({
                                        'changed': get(changed, 'customLogo.displayTime'),
                                        'response_warning': !isNil(get(responseWarning, 'customLogo.displayTime'))
                                        })}>{t('COM_LFD_SID_LOGO_DISPLAY_TIME')}</td>
                                    <td>
                                        <Select width={200} propertyName='customLogo.displayTime' selects={customLogoDisplayTimeOptions} value={displayTime} 
                                            onChange={onChangeCustomLogo} />
                                    </td>
                                </tr>
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export const EditGeneral = ({preset, displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const dehumidifyRef = useRef();
    const {data: {deviceType, basicPower, basicPanelStatus, inputSourceList = [], basicSource, basicVolume, basicMute, 
        diagnosisPanelOnTime, miscOsd, advancedOsdDisplayType, screenMute, screenFreeze, osdMenuSize, /*installEnvironment,*/ dehumidify} = {}, changed, responseWarning = {}} = displayInfo || {};
    const [dehumidifyInitial, setDehumidifyInitial] = useState();

    const is = preset ? deviceOptions.inputSource : inputSourceList.map(e => {
        return {
            value: e.code,
            title: e.text,
        };
    });

    const onChangePower = e => {
        if(e.target.checked) {
            onChange(e, '0');
        } else {
            onChange(e, '');
        }
    };

    const onChangeDehumidify = e => {
        if(e.target.checked) {
            if(dehumidifyInitial){
                onChange(e, '0');
            } else {
                onChange(e, '1');
            }            
        } else {
            if(dehumidifyInitial) {
                onChange(e, '1');
            } else {
                onChange(e, '0');
            }
        }
    }

    useEffect(() => {
        if(dehumidify && !get(changed, 'dehumidify.enable')){
            setDehumidifyInitial(dehumidify.enable);
        }
    }, [dehumidify]);

    useEffect(() => {
        if(dehumidifyRef && dehumidifyRef.current){
            dehumidifyRef.current.checked = false;
        }
    }, [dehumidifyInitial]);

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_TEXT_GENERAL_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(basicPower) && deviceType !== SIGNAGE_CHILD &&
                            <tr>
                                <th className={classNames({'changed': changed['basicPower']})}>{t('TABLE_POWER_P')}</th>
                                <td colSpan='2'>
                                    <Checkbox id='basicPower' propertyName='basicPower' checked={basicPower == '0'} onChange={onChangePower} name={t('BUTTON_OFF')} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(basicPanelStatus) && deviceType !== APLAYER &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['basicPanelStatus'],
                                    'response_warning': !isNil(get(responseWarning, 'basicPanelStatus'))
                                    })}>
                                    {(deviceType === LEDBOX || deviceType === RLEDBOX) ? t('MIS_SID_DISPLAY_STATUS') : t('MIS_SID_EMBEDDED_CBJAN_DISPLAY_PANEL')}
                                </th>
                                <td colSpan='2'>
                                    <RadioGroup propertyName='basicPanelStatus' selects={deviceOptions.panelStatus} value={basicPanelStatus} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            (preset || (!isNil(basicSource) && deviceType !== APLAYER)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='basicSourceEnable' name={t('COM_DID_LFD_SOURCE')} checked={changed['basicSource'] || false}
                                            propertyName='basicSource' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['basicSource'],
                                        'response_warning': !isNil(get(responseWarning, 'basicSource'))
                                    })}>{t('COM_DID_LFD_SOURCE')}</th>
                                }
                                <td colSpan='2'>
                                    <Select width={200} propertyName='basicSource' selects={is} value={basicSource} onChange={onChange} 
                                        disabled={preset && !changed['basicSource']} />
                                </td>
                            </tr>
                        }
                        {
                            preset &&
                            <EditPresetWebBrowserUrl displayInfo={displayInfo} onChange={onChange} onChangeChecked={onChangeChecked} />
                        }
                        {
                            !preset &&
                            <EditWebBrowserUrl displayInfo={displayInfo} onChange={onChange} />
                        }
                        {
                            (preset || (!isNil(basicVolume) && isSupportFeature(deviceType, 'basicVolume'))) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='basicVolumeEnable' name={t('TABLE_VOLUME_P')} checked={changed['basicVolume'] || false}
                                            propertyName='basicVolume' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['basicVolume'],
                                        'response_warning': !isNil(get(responseWarning, 'basicVolume'))
                                    })}>{t('TABLE_VOLUME_P')}</th>
                                }
                                <td colSpan='2'>
                                    <NumberInput propertyName='basicVolume' value={basicVolume} onChange={onChange} disabled={(preset && !changed['basicVolume']) || basicVolume === -1} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            (preset || (!isNil(basicMute) && isSupportFeature(deviceType, 'basicMute'))) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='basicMuteEnable' name={t('TABLE_MUTE_P')} checked={changed['basicMute'] || false}
                                            propertyName='basicMute' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['basicMute'],
                                        'response_warning': !isNil(get(responseWarning, 'basicMute'))
                                    })}>{t('TABLE_MUTE_P')}</th>
                                }
                                <td colSpan='2'>
                                    <RadioGroup propertyName='basicMute' selects={preset ? deviceOptions.onOffOptions : deviceOptions.muteOptions} value={basicMute} 
                                        onChange={onChange} disabled={(preset && !changed['basicMute']) || basicMute === -1} />
                                </td>
                            </tr>
                        }
                        {
                            preset ?
                            <EditPresetCustomLogo displayInfo={displayInfo} onChange={onChange} onChangeChecked={onChangeChecked} /> :
                            <EditCustomLogo displayInfo={displayInfo} onChange={onChange} />
                        }
                        {
                            !preset && !isNil(screenMute) && screenMute >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['screenMute'],
                                    'response_warning': !isNil(get(responseWarning, 'screenMute'))
                                    })}>{t('TABLE_MUTE_P')}</th>
                                <td colSpan='2'>
                                    <RadioGroup propertyName='screenMute' selects={rOnOffOptions} value={screenMute} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !preset && !isNil(screenFreeze) && screenFreeze >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['screenFreeze'],
                                    'response_warning': !isNil(get(responseWarning, 'screenFreeze'))
                                    })}>{t('COM_TV_SID_FREEZE')}</th>
                                <td colSpan='2'>
                                    <RadioGroup propertyName='screenFreeze' selects={rOnOffOptions} value={screenFreeze} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            preset &&
                            <tr>
                                <th>
                                    <Checkbox id='osdMenuSizeEnable' name={t('COM_LFD_SID_MENU_SIZE')} checked={changed['osdMenuSize'] || false}
                                        propertyName='osdMenuSize' onChange={onChangeChecked} />
                                </th>
                                <td colSpan='2'>
                                    <Select width={200} selects={deviceOptions.osdMenuSize} value={osdMenuSize} onChange={onChange}
                                        propertyName='osdMenuSize' disabled={preset && !changed['osdMenuSize']} />
                                </td>
                            </tr>
                        }
                        {
                            preset && <EditPresetAdvancedOsdDisplay displayInfo={displayInfo} onChange={onChange} onChangeChecked={onChangeChecked} />
                        }
                        {
                            !isNil(diagnosisPanelOnTime) && isSupportFeature(deviceType, 'panelOnTime') && deviceType !== APLAYER &&
                            <tr>
                                <th>{t('TABLE_PANEL_ON_TIME_P')}</th>
                                <td colSpan='2'>{`${diagnosisPanelOnTime} ${t('COM_SID_HOURS')}`}</td>
                            </tr>
                        }
                        {
                            (preset || (!isNil(miscOsd) && deviceType !== APLAYER)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='miscOsdEnable' name={t('DID_LFD_OSD')} checked={changed['miscOsd'] || false}
                                            propertyName='miscOsd' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['miscOsd'],
                                        'response_warning': !isNil(get(responseWarning, 'miscOsd'))
                                    })}>{t('DID_LFD_OSD')}</th>
                                }
                                <td colSpan='2'>
                                    <RadioGroup propertyName='miscOsd' selects={deviceOptions.osdOptions} value={miscOsd} onChange={onChange}
                                        disabled={(preset && !changed['miscOsd']) || miscOsd === -1} />
                                </td>
                            </tr>
                        }
                        {
                            !preset && <EditAdvancedOsdDisplay displayInfo={displayInfo} onChange={onChange} />
                        }
                        {
                            !preset && !isNil(osdMenuSize) &&
                            <tr>
                                <th className={classNames({
                                        'changed': changed['osdMenuSize'],
                                        'response_warning': !isNil(get(responseWarning, 'osdMenuSize'))
                                    })}>{t('COM_LFD_SID_MENU_SIZE')}</th>
                                <td colSpan='2'>
                                    <Select width={200} propertyName='osdMenuSize' selects={deviceOptions.osdMenuSize} value={osdMenuSize} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !preset && !isNil(dehumidify) && 
                            <tr>
                                <th className={classNames({
                                        'changed': get(changed, 'dehumidify.enable'),
                                        'response_warning': !isNil(get(responseWarning, 'dehumidify.enable'))
                                    })}>{t('COM_SID_LED_DEHUMIDIFY')}</th>
                                <td>
                                    <Checkbox id='dehumidify' name={dehumidifyInitial == '1'? t('COM_BUTTON_STOP_P') : t('COM_BUTTON_START_P')} 
                                        propertyName='dehumidify.enable' onChange={onChangeDehumidify} ref={dehumidifyRef}/>
                                </td>
                                <td>
                                    {
                                        !isNil(dehumidify.hour) && !isNil(dehumidify.minute) && dehumidifyInitial == 1 &&
                                        <span>
                                            {
                                                t('MIS_SID_MIXCBFEB_DEHUMIDIFICATION_PROGRESS')
                                                    .replace('%', '')
                                                    .replace('<<A>>', padStart(dehumidify.hour, 2, '0') + ':' + padStart(dehumidify.minute, 2, '0'))
                                            }
                                        </span>
                                    }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export const EditFanNTemp = ({preset, displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {deviceType, diagnosisMonitorTemperature, diagnosisAlarmTemperature, advancedFanControl, advancedFanSpeed, sensorInternalTemperature, sensorInternalHumidity, 
        sensorEnvironmentTemperature, sensorFrontglassTemperature, sensorFrontglassHumidity} = {}, changed, responseWarning = {}} = displayInfo || {};

    if(!preset && isAllNil(diagnosisMonitorTemperature, diagnosisAlarmTemperature, advancedFanControl, advancedFanSpeed, sensorInternalTemperature, sensorInternalHumidity, 
        sensorEnvironmentTemperature, sensorFrontglassTemperature, sensorFrontglassHumidity)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_IDS_TXT_FAN_AND_TEMPERATURE')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            isAllNil(sensorInternalTemperature, sensorInternalHumidity, sensorEnvironmentTemperature, sensorFrontglassTemperature, sensorFrontglassHumidity) ?
                            <>
                            {
                                !preset && !isNil(diagnosisMonitorTemperature) &&
                                <tr>
                                    <th>{t('COM_IDS_TXT_CURRENT_TEMPERATURE')}</th>
                                    <td>
                                        {diagnosisMonitorTemperature} °C
                                    </td>
                                </tr>
                            }
                            {
                                (preset || !isNil(diagnosisAlarmTemperature)) &&
                                <tr>
                                    {
                                        preset ?
                                        <th>
                                            <Checkbox id='diagnosisAlarmTemperatureEnable' name={t('COM_IDS_TXT_TEMPERATURE_CONTROL')} checked={changed['diagnosisAlarmTemperature'] || false}
                                                propertyName='diagnosisAlarmTemperature' onChange={onChangeChecked} />
                                        </th> :
                                        <th className={classNames({
                                            'changed': changed['diagnosisAlarmTemperature'],
                                            'response_warning': !isNil(get(responseWarning, 'diagnosisAlarmTemperature'))
                                        })}>{t('COM_IDS_TXT_TEMPERATURE_CONTROL')}</th>
                                    }
                                    <td>
                                        <NumberInput propertyName='diagnosisAlarmTemperature' value={diagnosisAlarmTemperature} onChange={onChange} min={75} max={124}
                                            disabled={(preset && !changed['diagnosisAlarmTemperature']) || diagnosisAlarmTemperature === -1} forceInt />
                                        °C
                                    </td>
                                </tr>
                            }
                            </> : 
                            !preset &&
                            <>
                                {
                                    !isNil(sensorInternalTemperature) &&
                                    <tr>
                                        <th>{t('MIS_SID_SERVER_INTERNAL_TEMPERATURE')}</th>
                                        <td>{sensorInternalTemperature} °C</td>
                                    </tr>
                                }
                                {
                                    !isNil(sensorInternalHumidity) &&
                                    <tr>
                                        <th>{t('MIS_SID_SERVER_INTERNAL_HUMIDITY')}</th>
                                        <td>{sensorInternalHumidity} %</td>
                                    </tr>
                                }
                                {
                                    !isNil(sensorEnvironmentTemperature) &&
                                    <tr>
                                        <th>{t('MIS_SID_SERVER_AMBIENT_TEMPERATURE')}</th>
                                        <td>{sensorEnvironmentTemperature} °C</td>
                                    </tr>
                                }
                                {
                                    !isNil(sensorFrontglassTemperature) &&
                                    <tr>
                                        <th>{t('MIS_SID_SERVER_FRONT_GLASS_TEMPERATURE')}</th>
                                        <td>{sensorFrontglassTemperature} °C</td>
                                    </tr>
                                }
                                {
                                    !isNil(sensorFrontglassHumidity) &&
                                    <tr>
                                        <th>{t('MIS_SID_SERVER_FRONT_GLASS_HUMIDITY')}</th>
                                        <td>{sensorFrontglassHumidity} %</td>
                                    </tr>
                                }
                            </>
                        }
                        {
                            (preset || (!isNil(advancedFanControl) && advancedFanControl >= 0)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='advancedFanControlEnable' name={t('TEXT_FAN_CONTROL_P')} checked={changed['advancedFanControl'] || false}
                                            propertyName='advancedFanControl' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['advancedFanControl'],
                                        'response_warning': !isNil(get(responseWarning, 'advancedFanControl'))
                                    })}>{t('TEXT_FAN_CONTROL_P')}</th>
                                }
                                <td>
                                    <RadioGroup propertyName='advancedFanControl' selects={(deviceType === LEDBOX || deviceType === RLEDBOX) ? deviceOptions.ledFanControl : deviceOptions.fanControl} onChange={onChange} value={advancedFanControl} 
                                        disabled={preset && !changed['advancedFanControl']} />
                                </td>
                            </tr>
                        }
                        {
                            (!preset && !isNil(advancedFanSpeed) && advancedFanSpeed >= 0 && advancedFanControl === 0) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['advancedFanSpeed'],
                                    'response_warning': !isNil(get(responseWarning, 'advancedFanSpeed'))
                                    })}>{t('TEXT_FAN_SPEED_SETTING_P')}</th>
                                <td>
                                    <NumberInput propertyName='advancedFanSpeed' onChange={onChange} value={advancedFanSpeed} min={0} max={100} forceInt />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export const EditPicture = ({pcMode, displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {deviceType, ppcMagicBright, mntManual, ppcContrast, ppcBrightness, ppcColortone, ppcColorTemperature, ppcSize, pvDigitalnr, 
        pvFilmmode, ledPictureSize, ledHdr, ledHdrDre, ppcGamma, ppcHdmiBlackLevel, displayOutputMode} = {}, changed, responseWarning = {}} = displayInfo || {};

    if(!pcMode && isAllNil(ledPictureSize, ledHdr) && !(displayOutputMode >= 0)) {
        return null;
    }

    if(isAllNil(ppcMagicBright, ppcContrast, ppcBrightness, ppcColortone, ppcColorTemperature, ppcSize, pvDigitalnr, pvFilmmode, 
        ledPictureSize, ledHdr, ledHdrDre, ppcGamma, ppcHdmiBlackLevel, displayOutputMode)) {
        return null;
    }
    
    const pcColorTone = [
        {"value": 1, "title": `${t("DID_LFD_COOL")} 1`},
        {"value": 0, "title": `${t("DID_LFD_COOL")} 2`},
        {"value": 2, "title": t('COM_SID_STANDARD')},
        {"value": 3, "title": `${t("DID_LFD_WARM")} 1`},
        {"value": 4, "title": `${t("DID_LFD_WARM")} 2`},
        {"value": 5, "title": t('TEXT_NATURAL_P')},
        {"value": 80, "title": t('BUTTON_OFF')}
    ];
    //[V9 RC #5-2] 2. Update in max and min values of supported resolution.
    const getResolutionValues= () =>{
        let maxResolution = [];
        let minResolution = [];
        if(displayOutputMode !== undefined && displayOutputMode >1) {
            //displayOutputMode = 3 for 8k, displayOutputMode = 2 for 4k. 
            maxResolution = displayOutputMode == 3 ? [7680, 4320] : [3840, 2160]; 
            minResolution = displayOutputMode == 3 ? [3840, 2160] : [1920, 1080]; 
        }
        else{
            maxResolution = displayInfo.supportUhdDisplay ? [3840, 2160] : [1920, 1080];
            minResolution = displayInfo.supportUhdDisplay ? [408, 408] : [182, 182];
        }
        return [maxResolution, minResolution];
    }


    //const maxResolution = displayInfo.supportUhdDisplay ? [3840, 2160] : [1920, 1080];
    //const minResolution = displayInfo.supportUhdDisplay ? [408, 408] : [182, 182];
    const [maxResolution, minResolution] = getResolutionValues();
    const [ledPictureSizeValue, ledPictureSizeResolution, ledPictureSizeFitToScreen, ledPictureSizeScreenPosition] = (ledPictureSize || '').split(';');
    const [ledPictureSizeResolutionX, ledPictureSizeResolutionY] = (ledPictureSizeResolution || '').split('x');
    const [ledPictureSizeScreenPositionX, ledPictureSizeScreenPositionY] = (ledPictureSizeScreenPosition || '').split('x');
    const [toneMapping, dynamicPeaking, colorMapping] = (ledHdr || '').split(';');

    const onChangeLedPictureSize = index => (e, value) => {
        let ledPictureSizeArr = ledPictureSize && ledPictureSize.split(';');
        if(index === 0) {
            ledPictureSizeArr[0] = value;
        } else if(index === 1) {
            ledPictureSizeArr[1] = value + 'x' + ledPictureSizeResolutionY;
        } else if(index === 2) {
            ledPictureSizeArr[1] = ledPictureSizeResolutionX + 'x' + value;
        } else if(index === 3) {
            ledPictureSizeArr[2] = value;
        } else if(index === 4) {
            ledPictureSizeArr[3] = value + 'x' + ledPictureSizeScreenPositionY;
        } else {
            ledPictureSizeArr[3] = ledPictureSizeScreenPositionX + 'x' + value;
        }
        onChange(e, ledPictureSizeArr.join(';'));
    };

    const onChangeLedHdr = index => (e, value) => {
        if(index === 0) {
            onChange(e, `${value};${dynamicPeaking};${colorMapping}`);
        } else if(index === 1) {
            onChange(e, `${toneMapping};${value};${colorMapping}`);
        } else {
            onChange(e, `${toneMapping};${dynamicPeaking};${value}`);
        }
    };
    //[V9 RC #5-2] 1. Support for 4K and 8K Display output modes added.
    const getDisplayOutputModesList = () =>{
        return (displayOutputMode >=2) ? deviceOptions.outputResolution_TZ6 : deviceOptions.outputResolution;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{`${t('COM_IDS_TXT_PICTURE')} (${'PC'})`}</h3>
            {
                pcMode &&
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            {
                                !isNil(ppcMagicBright) && deviceType !== SIGNAGE_CHILD &&
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['ppcMagicBright'],
                                        'response_warning': !isNil(get(responseWarning, 'ppcMagicBright'))
                                        })}>{t('TEXT_MAGICBRIGHT_P')}</th>
                                    <td>
                                        <Select width={240} propertyName='ppcMagicBright' value={ppcMagicBright} selects={deviceOptions.pcMagicBright} onChange={onChange} />
                                    </td>
                                </tr>
                            }
                            {
                                !isNil(mntManual) && isSupportFeature(deviceType, 'lampControl') &&
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['mntManual'],
                                        'response_warning': !isNil(get(responseWarning, 'mntManual'))
                                        })}>{`${t('COM_TEXT_LAMP_CONTROL_P')}(${t('TEXT_MANUAL_P')})`}</th>
                                    <td>
                                        <NumberInput width={70} propertyName='mntManual' value={mntManual} onChange={onChange} min={0} max={100} forceInt />
                                    </td>
                                </tr>
                            }
                            {
                                !isNil(ppcContrast) &&
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['ppcContrast'],
                                        'response_warning': !isNil(get(responseWarning, 'ppcContrast'))
                                        })}>{t('TEXT_CONTRAST_P')}</th>
                                    <td>
                                        <NumberInput width={70} propertyName='ppcContrast' value={ppcContrast} onChange={onChange} min={0} max={100} forceInt />
                                    </td>
                                </tr>
                            }
                            {
                                !isNil(ppcBrightness) &&
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['ppcBrightness'],
                                        'response_warning': !isNil(get(responseWarning, 'ppcBrightness'))
                                        })}>{t('DID_LFD_BRIGHTNESS')}</th>
                                    <td>
                                        <NumberInput width={70} propertyName='ppcBrightness' value={ppcBrightness} onChange={onChange} min={0} max={100} forceInt />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            pcMode && !isNil(ppcColortone) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['ppcColortone'],
                                    'response_warning': !isNil(get(responseWarning, 'ppcColortone'))
                                    })}>{t('TEXT_COLOR_TONE_P')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='ppcColortone' value={ppcColortone} selects={pcColorTone} onChange={onChange} multiLang={false} />
                                </td>
                            </tr>
                        }
                        {
                            pcMode && !isNil(ppcColorTemperature) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['ppcColorTemperature'],
                                    'response_warning': !isNil(get(responseWarning, 'ppcColorTemperature'))
                                    })}>{t('TEXT_COLOR_TEMPERATURE_P')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='ppcColorTemperature' value={ppcColorTemperature} selects={deviceOptions.colorTemperature} multiLang={false} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            pcMode && !isNil(ppcSize) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['ppcSize'],
                                    'response_warning': !isNil(get(responseWarning, 'ppcSize'))
                                    })}>{t('COM_SID_SIZE_M_FILE_SIZE')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='ppcSize' value={ppcSize} selects={deviceOptions.pcSize} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            pcMode && !isNil(ppcGamma) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['ppcGamma'],
                                    'response_warning': !isNil(get(responseWarning, 'ppcGamma'))
                                    })}>{t('COM_DID_LFD_GAMMA')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='ppcGamma' selects={deviceOptions.gammaOptions} onChange={onChange} value={ppcGamma} />
                                </td>
                            </tr>
                        }
                        {
                            pcMode && !isNil(ppcHdmiBlackLevel) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['ppcHdmiBlackLevel'],
                                    'response_warning': !isNil(get(responseWarning, 'ppcHdmiBlackLevel'))
                                    })}>{t('COM_DID_LFD_HDMI_BLACK_LEVEL')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='ppcHdmiBlackLevel' selects={deviceOptions.hdmiBalckLevel} onChange={onChange} value={ppcHdmiBlackLevel} />
                                </td>
                            </tr>
                        }
                        {
                            pcMode && !isNil(pvDigitalnr) && deviceType !== SIGNAGE_CHILD &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvDigitalnr'],
                                    'response_warning': !isNil(get(responseWarning, 'pvDigitalnr'))
                                    })}>{t('COM_TV_SID_DIGITAL_CLEAN_VIEW')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='pvDigitalnr' selects={deviceOptions.pcDigitalCleanView} value={pvDigitalnr} 
                                        onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            pcMode && !isNil(pvFilmmode) && deviceType !== SIGNAGE_CHILD &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvFilmmode'],
                                    'response_warning': !isNil(get(responseWarning, 'pvFilmmode'))
                                    })}>{t('DID_LFD_FILM_MODE')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='pvFilmmode' selects={deviceOptions.filmMode} value={pvFilmmode} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            //[V9 RC #5-2] 1. Support for 4K and 8K Display output modes added.
                            !isNil(displayOutputMode) && displayOutputMode >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['displayOutputMode'],
                                    'response_warning': !isNil(get(responseWarning, 'displayOutputMode'))
                                    })}>{t('COM_SID_PICTURE_LED_OUTPUT_RESOLUTION')}</th>
                                <td>
                                    <RadioGroup propertyName='displayOutputMode' selects={getDisplayOutputModesList()} value={displayOutputMode} onChange={onChange} multiLang={false} />
                                </td>
                            </tr>
                        }
                        {
                            ledPictureSize &&
                            <>
                                <tr>
                                    <th className={classNames({'changed': changed['ledPictureSize']})}>{t('COM_LFD_SID_SIGNAGE_LED_PICTURE_SIZE')}</th>
                                    <td colSpan="2">
                                        <Select width={200} propertyName='ledPictureSize' selects={deviceOptions.ledPictureSize} value={ledPictureSizeValue} onChange={onChangeLedPictureSize(0)} />
                                    </td>
                                </tr>
                                {
                                    ledPictureSizeValue != 0 && 
                                    <>
                                        <tr>
                                            <th></th>
                                            <td>{t('TABLE_RESOLUTION_P')}</td>
                                            <td>
                                                <NumberInput propertyName='ledPictureSize' value={ledPictureSizeResolutionX} onChange={onChangeLedPictureSize(1)} min={minResolution[0]} max={maxResolution[0]} forceInt />
                                                <NumberInput propertyName='ledPictureSize' value={ledPictureSizeResolutionY} onChange={onChangeLedPictureSize(2)} min={minResolution[1]} max={maxResolution[1]} forceInt />
                                            </td>
                                        </tr>
                                        {   
                                            //[V9 RC #5-2] 2. Do not show menu in UI if info not received from device
                                            ledPictureSizeFitToScreen && 
                                            <tr>
                                                <th></th>
                                                <td>{t('COM_TV_SID_FIT_TO_SCREEN')}</td>
                                                <td>
                                                    <RadioGroup propertyName='ledPictureSize' selects={deviceOptions.onOffOptions} value={ledPictureSizeFitToScreen} onChange={onChangeLedPictureSize(3)} />
                                                </td>
                                            </tr>
                                        }
                                        
                                        {
                                            ledPictureSizeScreenPosition &&
                                            <tr>
                                                <th></th>
                                                <td>{t('COM_IDS_TXT_SCREEN_POSITION_KR_POS')}</td>
                                                <td>
                                                    <NumberInput propertyName='ledPictureSize' value={ledPictureSizeScreenPositionX} onChange={onChangeLedPictureSize(4)} min={0} max={maxResolution[0]-ledPictureSizeResolutionX} forceInt />
                                                    <NumberInput propertyName='ledPictureSize' value={ledPictureSizeScreenPositionY} onChange={onChangeLedPictureSize(5)} min={0} max={maxResolution[1]-ledPictureSizeResolutionY} forceInt />
                                                </td>
                                            </tr>
                                        }
                                    </>
                                }
                            </>
                        }
                        {
                            ledHdr &&
                            <>
                                <tr>
                                    <th className={classNames({'changed': changed['ledHdr'] || changed['ledHdrDre']})}>{t('COM_LFD_SID_SIGNAGE_LED_HDR')}</th>
                                    <td>{t('COM_LFD_SID_SIGNAGE_INVERSE_TONE_MAPPING')}</td>
                                    <td>
                                        <RadioGroup propertyName='ledHdr' selects={deviceOptions.onOffOptions} value={toneMapping < 0 ? 0 : toneMapping} onChange={onChangeLedHdr(0)} disabled={toneMapping < 0}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>{t('COM_LFD_SID_SIGNAGE_DYNAMIC_PEAKING')}</td>
                                    <td>
                                        <RadioGroup propertyName='ledHdr' selects={deviceOptions.onOffOptions} value={dynamicPeaking < 0 ? 0 : dynamicPeaking} onChange={onChangeLedHdr(1)} disabled={dynamicPeaking < 0}/>
                                    </td>
                                </tr>
                                {
                                    !isNil(ledHdrDre) && dynamicPeaking > 0 &&
                                    <tr>
                                        <th></th>
                                        <td className={classNames({'changed': changed['ledHdrDre']})}>{t('COM_SID_TIZEN_LFD_NON_DRE')}</td>
                                        <td>
                                            <Select width={200} propertyName='ledHdrDre' selects={deviceOptions.ledHdrDre} value={ledHdrDre} onChange={onChange} />
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <th></th>
                                    <td>{t('COM_LFD_SID_SIGNAGE_COLOR_MAPPING')}</td>
                                    <td>
                                        <RadioGroup propertyName='ledHdr' selects={deviceOptions.onOffOptions} value={colorMapping < 0 ? 0 : colorMapping} onChange={onChangeLedHdr(2)} disabled={colorMapping < 0}/>
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

//[V9 RC #5-2] 4. Addition of new MO for Specialized Picture Mode support.
const EditVideoMode = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {deviceType, pvSplPictureMode, pvMode, mntManual, pvContrast, pvBrightness, pvSharpness, pvColor, pvTint, pvColortone, pvColorTemperature, 
        pvSize, pvDigitalnr, pvFilmmode, pvHdmiBlackLevel, autoMotionPlus, autoMotionPlusJudderReduction, pvMpegNoiseFilter} = {}, 
        changed, responseWarning = {}} = displayInfo || {};

    if(isAllNil(pvSplPictureMode, pvMode, mntManual, pvContrast, pvBrightness, pvSharpness, pvColor, pvTint, pvColortone, pvColorTemperature, 
        pvSize, pvDigitalnr, pvFilmmode, pvHdmiBlackLevel, autoMotionPlus, autoMotionPlusJudderReduction, pvMpegNoiseFilter)) {
        return null;
    }


    const getPvModeValue = () =>{
        if(!isNil(pvSplPictureMode) && pvSplPictureMode > 0){
            return -1;
        }
        return pvMode;
    }

    const getPvModeDefaultValue=()=>{
        if(!isNil(pvSplPictureMode) && pvSplPictureMode > 0) {
            const curTitle =  deviceOptions.splPictureMode.find((e) => e.value ==  pvSplPictureMode).title;
            return t(curTitle);
        }
        return ''; 
    }
    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_IDS_TXT_PICTURE') + " (VIDEO)"}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(pvSplPictureMode) && pvSplPictureMode >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvSplPictureMode'],
                                    'response_warning': !isNil(get(responseWarning, 'pvSplPictureMode'))
                                    })}>{t('MIS_SID_CBAUG_SPECIALIZED_PICTURE_MODE')}</th>
                                <td>
                                <RadioGroup propertyName={'pvSplPictureMode'} selects={deviceOptions.splPictureMode} 
                                    value= {pvSplPictureMode}
                                    onChange={onChange}  /> 
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvMode) && pvMode >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvMode'],
                                    'response_warning': !isNil(get(responseWarning, 'pvMode'))
                                    })}>{t('COM_SID_PICTURE_MODE')}</th>
                                <td>
                                    <Select width={240} propertyName='pvMode' selects={deviceOptions.pictureMode} 
                                    value={getPvModeValue()} onChange={onChange} defaultTitle={getPvModeDefaultValue()}
                                    disabled={!isNil(pvSplPictureMode) && pvSplPictureMode > 0 }/>
                                </td>
                            </tr>
                        }
                        {
                            !isNil(mntManual) && isSupportFeature(deviceType, 'lampControl') &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['mntManual'],
                                    'response_warning': !isNil(get(responseWarning, 'mntManual'))
                                    })}>{`${t('COM_TEXT_LAMP_CONTROL_P')}(${t('TEXT_MANUAL_P')})`}</th>
                                <td>
                                    <NumberInput propertyName='mntManual' value={mntManual} onChange={onChange} min={0} max={100} disabled={mntManual == -1} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvContrast) && pvContrast >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvContrast'],
                                    'response_warning': !isNil(get(responseWarning, 'pvContrast'))
                                    })}>{t('TEXT_CONTRAST_P')}</th>
                                <td>
                                    <NumberInput propertyName='pvContrast' value={pvContrast} onChange={onChange} min={0} max={100} forceInt
                                        disabled={pvMode == 5} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvBrightness) && pvBrightness >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvBrightness'],
                                    'response_warning': !isNil(get(responseWarning, 'pvBrightness'))
                                    })}>{t('DID_LFD_BRIGHTNESS')}</th>
                                <td>
                                    <NumberInput propertyName='pvBrightness' value={pvBrightness} onChange={onChange} min={0} max={100} forceInt
                                        disabled={pvMode == 5} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvSharpness) && pvSharpness >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvSharpness'],
                                    'response_warning': !isNil(get(responseWarning, 'pvSharpness'))
                                    })}>{t('DID_LFD_SHARPNESS')}</th>
                                <td>
                                    <NumberInput propertyName='pvSharpness' value={pvSharpness} onChange={onChange} min={0} max={100} forceInt
                                        disabled={pvMode == 5} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvColor) && pvColor >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvColor'],
                                    'response_warning': !isNil(get(responseWarning, 'pvColor'))
                                    })}>{t('DID_LFD_COLOR')}</th>
                                <td>
                                    <NumberInput propertyName='pvColor' value={pvColor} onChange={onChange} min={0} max={100} forceInt
                                        disabled={pvMode == 5 || pvMode == 33 || pvMode == 35 || pvMode == 37 || pvMode == 39} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvTint) && pvTint >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvTint'],
                                    'response_warning': !isNil(get(responseWarning, 'pvTint'))
                                    })}>{t('COM_TV_SID_TINT_GR')}</th>
                                <td>
                                    <NumberInput propertyName='pvTint' value={pvTint} onChange={onChange} min={0} max={100} forceInt
                                        disabled={pvMode == 5 || pvMode == 33 || pvMode == 35 || pvMode == 37 || pvMode == 39} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(pvColortone) && pvColortone >= 0 &&
                            <>
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['pvColortone'],
                                        'response_warning': !isNil(get(responseWarning, 'pvColortone'))
                                        })}>{t('TEXT_COLOR_TONE_P')}</th>
                                    <td>
                                        <RadioGroup propertyName='pvColortone' selects={deviceOptions.colorTone1} value={pvColortone} onChange={onChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>
                                        <RadioGroup propertyName='pvColortone' selects={deviceOptions.colorTone2} value={pvColortone} onChange={onChange} />
                                    </td>
                                </tr>
                            </>
                        }
                        {
                            !isNil(pvColorTemperature) && pvColorTemperature >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvColorTemperature'],
                                    'response_warning': !isNil(get(responseWarning, 'pvColorTemperature'))
                                    })}>{t('TEXT_COLOR_TEMPERATURE_P')}</th>
                                <td>
                                    <Select width={200} propertyName='pvColorTemperature' selects={deviceOptions.colorTemperature} value={pvColorTemperature} multiLang={false} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvSize) && pvSize >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvSize'],
                                    'response_warning': !isNil(get(responseWarning, 'pvSize'))
                                    })}>{t('COM_SID_PICTURE_SIZE')}</th>
                                <td>
                                    <Select width={200} propertyName='pvSize' selects={deviceOptions.pictureSize} value={pvSize} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvDigitalnr) && deviceType !== SIGNAGE_CHILD && pvDigitalnr >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvDigitalnr'],
                                    'response_warning': !isNil(get(responseWarning, 'pvDigitalnr'))
                                    })}>{t('COM_TV_SID_DIGITAL_CLEAN_VIEW')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='pvDigitalnr' selects={deviceOptions.pcDigitalCleanView} value={pvDigitalnr} 
                                        onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvFilmmode) && deviceType !== SIGNAGE_CHILD && pvFilmmode >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvFilmmode'],
                                    'response_warning': !isNil(get(responseWarning, 'pvFilmmode'))
                                    })}>{t('DID_LFD_FILM_MODE')}</th>
                                <td>
                                    <Select width={200} propertyName='pvFilmmode' selects={deviceOptions.filmMode} value={pvFilmmode} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvHdmiBlackLevel) && pvHdmiBlackLevel >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvHdmiBlackLevel'],
                                    'response_warning': !isNil(get(responseWarning, 'pvHdmiBlackLevel'))
                                    })}>{t('COM_DID_LFD_HDMI_BLACK_LEVEL')}</th>
                                <td>
                                    <RadioGroup propertyName='pvHdmiBlackLevel' selects={deviceOptions.hdmiBalckLevel} value={pvHdmiBlackLevel} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(autoMotionPlus) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['autoMotionPlus'],
                                    'response_warning': !isNil(get(responseWarning, 'autoMotionPlus'))
                                    })}>{t('COM_SID_AUTO_MOTION_PLUS')}</th>
                                <td>
                                    <Select width={200} propertyName='autoMotionPlus' selects={deviceOptions.autoMotionPlus} value={autoMotionPlus} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(autoMotionPlus) && autoMotionPlus == 4 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['autoMotionPlusJudderReduction'],
                                    'response_warning': !isNil(get(responseWarning, 'autoMotionPlusJudderReduction'))
                                    })}>{t('COM_SID_JUDDER_REDUCTION')}</th>
                                <td>
                                    <NumberInput propertyName='autoMotionPlusJudderReduction' value={autoMotionPlusJudderReduction} onChange={onChange} min={0} max={10} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pvMpegNoiseFilter) && pvMpegNoiseFilter >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pvMpegNoiseFilter'],
                                    'response_warning': !isNil(get(responseWarning, 'pvMpegNoiseFilter'))
                                    })}>{t('COM_SID_MPEG_NOISE_FILTER')}</th>
                                <td>
                                    <RadioGroup propertyName='pvMpegNoiseFilter' value={pvMpegNoiseFilter} selects={rOnOffOptions} onChange={onChange} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export const EditSound = ({preset, displayInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {deviceType, soundMode, soundSrs} = {}, changed, responseWarning} = displayInfo || {};

    if(!preset && isAllNil(soundMode, soundSrs)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('TEXT_TITLE_SOUND_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            (preset || (!isNil(soundMode) && isSupportFeature(deviceType, 'soundMode'))) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='soundModeEnable' name={t('COM_SID_SOUND_MODE')} checked={changed['soundMode'] || false}
                                            propertyName='soundMode' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['soundMode'],
                                        'response_warning': !isNil(get(responseWarning, 'soundMode'))
                                    })}>{t('COM_SID_SOUND_MODE')}</th>
                                }
                                <td>
                                    <Select width={200} propertyName='soundMode' selects={deviceOptions.soundMode} value={soundMode} onChange={onChange} 
                                        disabled={preset && !changed['soundMode']} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(soundSrs) && isSupportFeature(deviceType, 'soundSrs') &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['soundSrs'],
                                    'response_warning': !isNil(get(responseWarning, 'soundSrs'))
                                    })}>{t('TEXT_SRS_P')}</th>
                                <td>
                                    <RadioGroup width={200} propertyName='soundSrs' selects={deviceOptions.onOffOptions} value={soundSrs} onChange={onChange} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
 };

const EditWhiteBalance = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {deviceType, sbStatus, sbRgain, sbGgain, sbBgain, sbRoffset, sbGoffset, sbBoffset} = {}, changed, responseWarning = {}} = displayInfo || {};

    if(isAllNil(sbStatus, sbRgain, sbGgain, sbBgain, sbRoffset, sbGoffset, sbBoffset)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_SID_WHITE_BALANCE')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(sbStatus) && deviceType !== SIGNAGE_CHILD &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['sbStatus'],
                                    'response_warning': !isNil(get(responseWarning, 'sbStatus'))
                                    })}>{t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS')}</th>
                                <td>
                                    <RadioGroup propertyName='sbStatus' selects={rOnOffOptions} value={sbStatus} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(sbRgain) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['sbRgain'],
                                    'response_warning': !isNil(get(responseWarning, 'sbRgain'))
                                    })}>{t('COM_SID_R_GAIN')}</th>
                                <td>
                                    <NumberInput width={80} propertyName='sbRgain' value={sbRgain} min={0} max={255} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(sbGgain) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['sbGgain'],
                                    'response_warning': !isNil(get(responseWarning, 'sbGgain'))
                                    })}>{t('COM_SID_G_GAIN')}</th>
                                <td>
                                    <NumberInput width={80} propertyName='sbGgain' value={sbGgain} min={0} max={255} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(sbBgain) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['sbBgain'],
                                    'response_warning': !isNil(get(responseWarning, 'sbBgain'))
                                    })}>{t('COM_SID_B_GAIN')}</th>
                                <td>
                                    <NumberInput width={80} propertyName='sbBgain' value={sbBgain} min={0} max={255} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(sbRoffset) && deviceType !== SIGNAGE_CHILD &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['sbRoffset'],
                                    'response_warning': !isNil(get(responseWarning, 'sbRoffset'))
                                    })}>{t('COM_SID_R_OFFSET')}</th>
                                <td>
                                    <NumberInput width={80} propertyName='sbRoffset' value={sbRoffset} min={0} max={255} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(sbGoffset) && deviceType !== SIGNAGE_CHILD &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['sbGoffset'],
                                    'response_warning': !isNil(get(responseWarning, 'sbGoffset'))
                                    })}>{t('COM_SID_G_OFFSET')}</th>
                                <td>
                                    <NumberInput width={80} propertyName='sbGoffset' value={sbGoffset} min={0} max={255} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(sbBoffset) && deviceType !== SIGNAGE_CHILD &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['sbBoffset'],
                                    'response_warning': !isNil(get(responseWarning, 'sbBoffset'))
                                    })}>{t('COM_SID_B_OFFSET')}</th>
                                <td>
                                    <NumberInput width={80} propertyName='sbBoffset' value={sbBoffset} min={0} max={255} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditMaintenance = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {deviceType, mntAuto} = {}, changed, responseWarning = {}} = displayInfo || {};
    const {mntAutoIsEnable, mntAutoMaxTime, mntAutoMaxValue, mntAutoMinTime, mntAutoMinValue} = mntAuto || {};

    if(isNil(mntAuto) || !isSupportFeature(deviceType, 'screenLampSchedule')) {
        return null;
    }

    const onChangeMntAuto = (e, value) => {
        onChange(e, value);
        onChange({'data-name': 'mntAuto.mntAutoChanged'}, true);
    };

    const onChangeAutoMaxTime = value => {
        if(value) {
            onChange({'data-name': 'mntAuto.mntAutoMaxTime'}, value.format(timeFormat));
            onChange({'data-name': 'mntAuto.mntAutoChanged'}, true);
        }
    };

    const onChangeAutoMinTime = value => {
        if(value) {
            onChange({'data-name': 'mntAuto.mntAutoMinTime'}, value.format(timeFormat));
            onChange({'data-name': 'mntAuto.mntAutoChanged'}, true);
        }
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('TEXT_TITLE_MAINTENANCE_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    {
                        mntAutoIsEnable !== '2' &&
                        <tr>
                            <th className={classNames({
                                'changed': !isNil(changed['mntAuto']),
                                'response_warning': !isNil(get(responseWarning, 'mntAuto.mntAutoIsEnable'))
                                })}>{t('COM_TV_SID_SCREEN_LAMP_SCHEDULE')}</th>
                            <td colSpan="2">
                                <RadioGroup propertyName='mntAuto.mntAutoIsEnable' selects={deviceOptions.onOffOptions} value={mntAutoIsEnable} onChange={onChangeMntAuto} />
                            </td>
                        </tr>
                    }
                        <tr>
                            {
                                mntAutoIsEnable === '2' ? <th className={classNames({'changed': !isNil(changed['mntAuto'])})}>{t('COM_TV_SID_SCREEN_LAMP_SCHEDULE')}</th> : <th></th>
                            }
                            <td className={classNames({
                                'changed': get(changed, 'mntAuto.mntAutoMaxTime'),
                                'response_warning': !isNil(get(responseWarning, 'mntAuto.mntAutoMaxTime'))
                                })}>{t('COM_TEXT_MAX_TIME_P')}</td>
                            <td>
                                <TimeSelect id='mntAuto.mntAutoMaxTime' width={100} value={moment(mntAutoMaxTime || defaultTime, timeFormat)} format={displayTimeFormat} onChange={onChangeAutoMaxTime} showSecond={false} />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className={classNames({
                                'changed': get(changed, 'mntAuto.mntAutoMaxValue'),
                                'response_warning': !isNil(get(responseWarning, 'mntAuto.mntAutoMaxValue'))
                                })}>{t('TEXT_MAX_VALUE_P')}</td>
                            <td><NumberInput propertyName='mntAuto.mntAutoMaxValue' value={mntAutoMaxValue} onChange={onChangeMntAuto} min={0} max={100} /></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className={classNames({
                                'changed': get(changed, 'mntAuto.mntAutoMinTime'),
                                'response_warning': !isNil(get(responseWarning, 'mntAuto.mntAutoMinTime'))
                                })}>{t('COM_TEXT_MIN_TIME_P')}</td>
                            <td>
                                <TimeSelect id='mntAuto.mntAutoMinTime' width={100} value={moment(mntAutoMinTime || defaultTime, timeFormat)} format={displayTimeFormat} onChange={onChangeAutoMinTime} showSecond={false} />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className={classNames({
                                'changed': get(changed, 'mntAuto.mntAutoMinValue'),
                                'response_warning': !isNil(get(responseWarning, 'mntAuto.mntAutoMinValue'))
                                })}>{t('COM_DID_LFD_MIN_VALUE')}</td>
                            <td><NumberInput propertyName='mntAuto.mntAutoMinValue' value={mntAutoMinValue} onChange={onChangeMntAuto} min={0} max={100} forceInt/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditScreenBurnProtection = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {deviceType, mntSafetyScreenTimer, mntSafetyScreenRun, mntPixelShift} = {}, changed, responseWarning = {}} = displayInfo || {};
    const {scrSafeTimer, scrSafeMode, scrSafePeriod, scrSafeTime, scrSafeStartTime = defaultTime, scrSafeEndTime = defaultTime} = mntSafetyScreenTimer || {};
    const {pixelShiftEnable, pixelShiftH, pixelShiftV, pixelShiftTime} = mntPixelShift || {};

    if(isAllNil(mntSafetyScreenTimer, mntSafetyScreenRun, mntPixelShift) || 
        (!isSupportFeature(deviceType, 'safetyScreenTimer') && !isSupportFeature(deviceType, 'safetyScreenRun') && !isSupportFeature(deviceType, 'pixelShift'))) {
        return null;
    }

    const onChangeStartTime = value => {
        if(value) {
            onChange({'data-name': 'mntSafetyScreenTimer.scrSafeStartTime'}, value.format(timeFormat));
            onChange({'data-name': 'mntSafetyScreenTimer.scrSafeChanged'}, true);
        }
    };

    const onChangeEndTime = value => {
        if(value) {
            onChange({'data-name': 'mntSafetyScreenTimer.scrSafeEndTime'}, value.format(timeFormat));
            onChange({'data-name': 'mntSafetyScreenTimer.scrSafeChanged'}, true);
        }
    };

    const onChangePeriod = (e, value) => {
        if(scrSafeTimer == 0) {
            if(value == 1) {
                if(isNil(scrSafePeriod)) {
                    onChange({'data-name': 'mntSafetyScreenTimer.scrSafePeriod'}, 0);
                }
            } else if(value == 2) {
                if(isNil(scrSafeStartTime)) {
                    onChange({'data-name': 'mntSafetyScreenTimer.scrSafeStartTime'}, defaultTime);
                }
                if(isNil(scrSafeEndTime)) {
                    onChange({'data-name': 'mntSafetyScreenTimer.scrSafeEndTime'}, defaultTime);
                }
            }
        }

        onChange(e, value);
        onChange({'data-name': 'mntSafetyScreenTimer.scrSafeChanged'}, true);
    };

    const onChangeSafetyScreenTimer = (e, value) => {
        if(value == 1) {
            onChange({'data-name': 'mntSafetyScreenTimer.scrSafeMode'}, '3');
        } else if(value == 2) {
            onChange({'data-name': 'mntSafetyScreenTimer.scrSafeMode'}, '131');
        }
        
        if(scrSafeTimer == 0) {
            if(value == 1) {
                if(isNil(scrSafePeriod)) {
                    onChange({'data-name': 'mntSafetyScreenTimer.scrSafePeriod'}, 0);
                }
                if(isNil(scrSafeTime)) {
                    onChange({'data-name': 'mntSafetyScreenTimer.scrSafeTime'}, 0);
                }
            } else if(value == 2) {
                if(isNil(scrSafeStartTime)) {
                    onChange({'data-name': 'mntSafetyScreenTimer.scrSafeStartTime'}, defaultTime);
                }
                if(isNil(scrSafeEndTime)) {
                    onChange({'data-name': 'mntSafetyScreenTimer.scrSafeEndTime'}, defaultTime);
                }
            }
        }

        onChange(e, value);
        onChange({'data-name': 'mntSafetyScreenTimer.scrSafeChanged'}, true);
    };

    const onChangePixelShift = (e, value) => {
        onChange(e, value);
        onChange({'data-name': 'mntPixelShift.pixelShiftChanged'}, true);
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_SID_SCREEN_BURN_PROTECTION')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width="120px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            mntSafetyScreenTimer && isSupportFeature(deviceType, 'safetyScreenTimer') &&
                            <>
                            {
                                !isNil(scrSafeTimer) &&
                                <tr>
                                    <th className={classNames({'changed': get(changed, 'mntSafetyScreenTimer.scrSafeTimer')})}>{t('DID_ADMIN_URGENT_TIMER')}</th>
                                    <td className={classNames({
                                        'changed': get(changed, 'mntSafetyScreenTimer.scrSafeTimer'),
                                        'response_warning': !isNil(get(responseWarning, 'mntSafetyScreenTimer.scrSafeTimer'))
                                        })}>{t('DID_ADMIN_URGENT_TIMER')}</td>
                                    <td>
                                        <RadioGroup propertyName='mntSafetyScreenTimer.scrSafeTimer' value={scrSafeTimer} selects={deviceOptions.screenBurnProtectionTimer} 
                                            onChange={onChangeSafetyScreenTimer} />
                                    </td>
                                </tr>
                            }
                            {
                                scrSafeTimer != 0 &&
                                <>
                                    <tr>
                                        <th></th>
                                        <td className={classNames({
                                            'changed': get(changed, 'mntSafetyScreenTimer.scrSafeMode'),
                                            'response_warning': !isNil(get(responseWarning, 'mntSafetyScreenTimer.scrSafeMode'))
                                            })}>{t('DID_LFD_MODE')}</td>
                                        <td>
                                            <RadioGroup propertyName='mntSafetyScreenTimer.scrSafeMode' value={scrSafeMode} selects={scrSafeTimer == 1 ? deviceOptions.burnProtectionModeRepeat1 : deviceOptions.burnProtectionModeInterval1} onChange={onChangeSafetyScreenTimer} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td></td>
                                        <td>
                                            <RadioGroup propertyName='mntSafetyScreenTimer.scrSafeMode' value={scrSafeMode} selects={scrSafeTimer == 1 ? deviceOptions.burnProtectionModeRepeat2 : deviceOptions.burnProtectionModeInterval2} onChange={onChangeSafetyScreenTimer} />
                                        </td>
                                    </tr>
                                </>
                            }
                            {
                                scrSafeTimer == 1 &&
                                <>
                                    <tr>
                                        <th></th>
                                        <td className={classNames({
                                            'changed': get(changed, 'mntSafetyScreenTimer.scrSafePeriod'),
                                            'response_warning': !isNil(get(responseWarning, 'mntSafetyScreenTimer.scrSafePeriod'))
                                            })}>{t('TEXT_PERIOD_P')}</td>
                                        <td>
                                            <NumberInput propertyName='mntSafetyScreenTimer.scrSafePeriod' value={toInteger(scrSafePeriod)} min={0} max={10} onChange={onChangePeriod} forceInt />
                                            {t('COM_TEXT_HOUR_P')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td className={classNames({
                                            'changed': get(changed, 'mntSafetyScreenTimer.scrSafeTime'),
                                            'response_warning': !isNil(get(responseWarning, 'mntSafetyScreenTimer.scrSafeTime'))
                                            })}>{t('TEXT_TIME_P')}</td>
                                        <td>
                                            <NumberInput propertyName='mntSafetyScreenTimer.scrSafeTime' value={toInteger(scrSafeTime)} min={1} max={50} step={(scrSafeTime % 10) === 0 ? 10 : 10 - (scrSafeTime % 10)} onChange={onChangeSafetyScreenTimer} forceInt />
                                            {t('COM_SID_SEC')}
                                        </td>
                                    </tr>
                                </>
                            }
                            {
                                scrSafeTimer == 2 &&
                                <>
                                    <tr>
                                        <th></th>
                                        <td className={classNames({
                                            'changed': get(changed, 'mntSafetyScreenTimer.scrSafeStartTime'),
                                            'response_warning': !isNil(get(responseWarning, 'mntSafetyScreenTimer.scrSafeStartTime'))
                                            })}>{t('COM_SID_START_TIME')}</td>
                                        <td>
                                            <TimeSelect id='mntSafetyScreenTimer.scrSafeStartTime' width={120} value={moment(scrSafeStartTime || defaultTime, timeFormat)} format={displayTimeFormat} onChange={onChangeStartTime} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td className={classNames({
                                            'changed': get(changed, 'mntSafetyScreenTimer.scrSafeEndTime'),
                                            'response_warning': !isNil(get(responseWarning, 'mntSafetyScreenTimer.scrSafeEndTime'))
                                            })}>{t('COM_SID_END_TIME')}</td>
                                        <td>
                                            <TimeSelect id='mntSafetyScreenTimer.scrSafeEndTime' width={120} value={moment(scrSafeEndTime || defaultTime, timeFormat)} format={displayTimeFormat} onChange={onChangeEndTime} />
                                        </td>
                                    </tr>
                                </>
                            }
                            </>
                        }
                        {
                            !isNil(mntSafetyScreenRun) && isSupportFeature(deviceType, 'safetyScreenRun') &&
                            <tr>
                                <th className={classNames({
                                    'changed': get(changed, 'mntSafetyScreenRun'),
                                    'response_warning': !isNil(get(responseWarning, 'mntSafetyScreenRun'))
                                    })}>{t('COM_TV_IMMEDIATE_DISPLAY')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='mntSafetyScreenRun' value={mntSafetyScreenRun} selects={deviceOptions.immediateDisplay} 
                                        onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(mntPixelShift) && isSupportFeature(deviceType, 'pixelShift') &&
                            <>
                                <tr>
                                    <th className={classNames({'changed': get(changed, 'mntPixelShift.pixelShiftEnable')})}>{t('TEXT_PIXEL_SHIFT_P')}</th>
                                    <td className={classNames({
                                        'changed': get(changed, 'mntPixelShift.pixelShiftEnable'),
                                        'response_warning': !isNil(get(responseWarning, 'mntPixelShift.pixelShiftEnable'))
                                        })}>{t('TEXT_PIXEL_SHIFT_P')}</td>
                                    <td>
                                        <RadioGroup propertyName='mntPixelShift.pixelShiftEnable' value={pixelShiftEnable} selects={deviceOptions.onOffOptions} 
                                            onChange={onChangePixelShift} />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({
                                        'changed': get(changed, 'mntPixelShift.pixelShiftH'),
                                        'response_warning': !isNil(get(responseWarning, 'mntPixelShift.pixelShiftH'))
                                        })}>{t('TEXT_HORIZONTAL_P')}</td>
                                    <td>
                                        <NumberInput propertyName='mntPixelShift.pixelShiftH' value={toInteger(pixelShiftH)} min={0} max={4} onChange={onChangePixelShift} forceInt />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({
                                        'changed': get(changed, 'mntPixelShift.pixelShiftV'),
                                        'response_warning': !isNil(get(responseWarning, 'mntPixelShift.pixelShiftV'))
                                        })}>{t('TEXT_VERTICAL_P')}</td>
                                    <td>
                                        <NumberInput propertyName='mntPixelShift.pixelShiftV' value={toInteger(pixelShiftV)} min={0} max={4} onChange={onChangePixelShift} forceInt />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({
                                        'changed': get(changed, 'mntPixelShift.pixelShiftTime'),
                                        'response_warning': !isNil(get(responseWarning, 'mntPixelShift.pixelShiftTime'))
                                        })}>{t('TEXT_SHIFT_TIME_P')}</td>
                                    <td>
                                        <NumberInput propertyName='mntPixelShift.pixelShiftTime' value={toInteger(pixelShiftTime)} min={1} max={4} onChange={onChangePixelShift} forceInt />
                                        {t('COM_SID_MIN')}
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
 };

const EditPictureInfo = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {imageCoarse, imageFine, imageHpos, imageVpos, imageAuto}, changed, responseWarning = {}} = displayInfo || {};

    if(isAllNil(imageCoarse, imageFine, imageHpos, imageVpos, imageAuto)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_IDS_TXT_PICTURE')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(imageCoarse) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['imageCoarse'],
                                    'response_warning': !isNil(get(responseWarning, 'imageCoarse'))
                                    })}>{t('TEXT_COARSE_P')}</th>
                                <td>
                                    <RadioGroup propertyName='imageCoarse' selects={deviceOptions.incDecOptions} value={imageCoarse} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(imageFine) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['imageFine'],
                                    'response_warning': !isNil(get(responseWarning, 'imageFine'))
                                    })}>{t('TEXT_FINE_P')}</th>
                                <td>
                                    <RadioGroup propertyName='imageFine' selects={deviceOptions.incDecOptions} value={imageFine} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(imageHpos) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['imageHpos'],
                                    'response_warning': !isNil(get(responseWarning, 'imageHpos'))
                                    })}>{t('TEXT_H_POSITION_P')}</th>
                                <td>
                                    <RadioGroup propertyName='imageHpos' selects={deviceOptions.rightLeftOptions} value={imageHpos} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(imageVpos) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['imageVpos'],
                                    'response_warning': !isNil(get(responseWarning, 'imageVpos'))
                                    })}>{t('TEXT_V_POSITION_P')}</th>
                                <td>
                                    <RadioGroup propertyName='imageVpos' selects={deviceOptions.bottomTopOptions} value={imageVpos} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(imageAuto) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['imageAuto'],
                                    'response_warning': !isNil(get(responseWarning, 'imageAuto'))
                                    })}>{t('TEXT_AUTO_ADJUSTMENT_P')}</th>
                                <td>
                                    <Select width={200} propertyName='imageAuto' selects={[]} value={imageAuto} onChange={onChange} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
     );
 };

const EditAdvancedSetting = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {deviceType, deviceModelCode, advancedAutoPower, advancedStandBy, networkStandbyMode, advancedReset, ecoSensor, minBrightness, liveMode, advancedUserAutoColor, 
        autoSourceSwitching, maxPowerSaving, brightnessLimit, blackTone, fleshTone, colorSpace, pictureEnhancer, rgbOnlyMode} = {}, changed, responseWarning = {}} = displayInfo || {};
    const {autoSourceSwitching: asw, autoSourceRecovery, autoSourcePrimary, autoSourceSecondary, autoSourceDefaultInput} = autoSourceSwitching || {};

    if(parseInt(deviceModelCode || '0') <= 55) {
        return null;
    }

    if(isAllNil(advancedAutoPower, advancedStandBy, networkStandbyMode, advancedReset, ecoSensor, liveMode, advancedUserAutoColor, 
        autoSourceSwitching, maxPowerSaving, brightnessLimit, blackTone, fleshTone, colorSpace, pictureEnhancer, rgbOnlyMode)) {
        return null;
    }

    const onChangeAutoSourceSwitching = (e, value) => {
        onChange(e, value);
        onChange({'data-name': 'autoSourceSwitching.autoSourceChanged'}, true);
    };
    const primarySourceOptionsExclude = ['96', '99', '101', '97', '103']; // MagicInfo S/Lite, URL Launcher, WebBrowser, WiDi, Kiosk
    const secondarySourceOptionsExclude = ['97', '103']; // WiDi, Kiosk

    let primarySourceOptions = [...deviceOptions.inputSource];
    primarySourceOptions.splice(0, 0, {value:"0", title:"ALL", hide: autoSourceRecovery == 1});
    primarySourceOptions = primarySourceOptions.filter((source) => !primarySourceOptionsExclude.includes(source.value));

    const secondarySourceOptions = deviceOptions.inputSource && deviceOptions.inputSource.filter((source) => !secondarySourceOptionsExclude.includes(source.value));
    const defaultInputSourceOptions = deviceOptions.inputSource && [{value:"0", title:"Last Input"}].concat(deviceOptions.inputSource.filter((source) => !secondarySourceOptionsExclude.includes(source.value)));

    const advancedResetOption = [
        {...advancedResetOption1[0], hide: !isSupportFeature(deviceType, 'pictureReset')},
        {...advancedResetOption1[1], hide: !isSupportFeature(deviceType, 'soundReset')},
        {...advancedResetOption1[2]}
    ];

    return (
        <div className="devicepop setup mb26">
            <h3>{t('TEXT_TITLE_ADVANCED_SETTING_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(blackTone) && blackTone >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['blackTone'],
                                    'response_warning': !isNil(get(responseWarning, 'blackTone'))
                                    })}>{t('COM_SID_BLACK_TONE')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='blackTone' selects={deviceOptions.blackTone} value={blackTone} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(fleshTone) && fleshTone <= 15 && fleshTone >= -15 && deviceType !== SIGNAGE &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['fleshTone'],
                                    'response_warning': !isNil(get(responseWarning, 'fleshTone'))
                                    })}>{t('COM_SID_FLESH_TONE')}</th>
                                <td colSpan="2">
                                    <NumberInput propertyName='fleshTone' value={fleshTone} min={-15} max={15} forceInt onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(pictureEnhancer) && pictureEnhancer >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['pictureEnhancer'],
                                    'response_warning': !isNil(get(responseWarning, 'pictureEnhancer'))
                                    })}>{t('COM_SID_LFD_PICTURE_ENHANCER')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='pictureEnhancer' selects={rOnOffOptions} value={pictureEnhancer} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(colorSpace) && 
                            <tr>
                                <th className={classNames({
                                    'changed': changed['colorSpace'],
                                    'response_warning': !isNil(get(responseWarning, 'colorSpace'))
                                    })}>{t('COM_SID_COLOR_SPACE')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='colorSpace' selects={deviceOptions.colorSpace} value={(colorSpace+'').split(';')[0]} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(autoSourceSwitching) &&
                            <>
                                <tr>
                                    <th className={classNames({
                                        'changed': get(changed, 'autoSourceSwitching.autoSourceSwitching'),
                                        'response_warning': !isNil(get(responseWarning, 'autoSourceSwitching.autoSourceSwitching'))
                                        })}>{t('COM_TV_SID_AUTO_SOURCE_SWITCHING')}</th>
                                    <td colSpan="2">
                                        <RadioGroup propertyName='autoSourceSwitching.autoSourceSwitching' selects={isNil(autoSourceDefaultInput) ? rOnOffOptions : deviceOptions.autoSourceSwitchingOptions} value={asw} onChange={onChangeAutoSourceSwitching} 
                                            disabled={asw == -1} />
                                    </td>
                                </tr>
                                {
                                    asw === '1' &&
                                    <>
                                        <tr>
                                            <th></th>
                                            <td className={classNames({
                                                'changed': get(changed, 'autoSourceSwitching.autoSourceRecovery'),
                                                'response_warning': !isNil(get(responseWarning, 'autoSourceSwitching.autoSourceRecovery'))
                                                })}>{t('MIS_SID_PREMIUM_RESTORE_PRIMARY_SOURCE')}</td>
                                            <td>
                                                <RadioGroup propertyName='autoSourceSwitching.autoSourceRecovery' selects={rOnOffOptions} value={autoSourceRecovery} onChange={onChangeAutoSourceSwitching} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <td className={classNames({
                                                'changed': get(changed, 'autoSourceSwitching.autoSourcePrimary'),
                                                'response_warning': !isNil(get(responseWarning, 'autoSourceSwitching.autoSourcePrimary'))
                                                })}>{t('COM_LFD_TXT_PRIMARY_SOURCE')}</td>
                                            <td>
                                                <Select width={200} propertyName='autoSourceSwitching.autoSourcePrimary' selects={primarySourceOptions} value={autoSourcePrimary} onChange={onChangeAutoSourceSwitching} multiLang={false} />
                                            </td>
                                        </tr>
                                        {
                                            autoSourcePrimary != 0 &&
                                            <tr>
                                                <th></th>
                                                <td className={classNames({
                                                    'changed': get(changed, 'autoSourceSwitching.autoSourceSecondary'),
                                                    'response_warning': !isNil(get(responseWarning, 'autoSourceSwitching.autoSourceSecondary'))
                                                    })}>{t('COM_LFD_TXT_SECONDARY_SOURCE')}</td>
                                                <td>
                                                    <Select width={200} propertyName='autoSourceSwitching.autoSourceSecondary' selects={secondarySourceOptions} value={autoSourceSecondary} onChange={onChangeAutoSourceSwitching} multiLang={false} />
                                                </td>
                                            </tr>
                                        }
                                    </>
                                }
                                {
                                    !isNil(autoSourceDefaultInput) && asw === '2' && 
                                    <tr>
                                        <th></th>
                                        <td className={classNames({
                                            'changed': get(changed, 'autoSourceSwitching.autoSourceDefaultInput'),
                                            'response_warning': !isNil(get(responseWarning, 'autoSourceSwitching.autoSourceDefaultInput'))
                                            })}>{t('COM_SID_TIZENLFD_NEW_INPUT')}</td>
                                        <td>
                                            <Select width={200} propertyName='autoSourceSwitching.autoSourceDefaultInput' selects={defaultInputSourceOptions} value={autoSourceDefaultInput} onChange={onChangeAutoSourceSwitching} multiLang={false} />
                                        </td>
                                    </tr>
                                }
                            </>
                        }
                        {
                            !isNil(brightnessLimit) && brightnessLimit >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['brightnessLimit'],
                                    'response_warning': !isNil(get(responseWarning, 'brightnessLimit'))
                                })}>{t('COM_LFD_SID_BRIGHTNESS_LIMIT')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='brightnessLimit' selects={rOnOffOptions} value={brightnessLimit} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(maxPowerSaving) && maxPowerSaving >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['maxPowerSaving'],
                                    'response_warning': !isNil(get(responseWarning, 'maxPowerSaving'))
                                    })}>{t('COM_SID_MAX_ECO_MODE_KOR_MSG')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='maxPowerSaving' selects={rOnOffOptions} value={maxPowerSaving} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(advancedAutoPower) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['advancedAutoPower'],
                                    'response_warning': !isNil(get(responseWarning, 'advancedAutoPower'))
                                    })}>{t('DID_LFD_AUTO_POWER')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='advancedAutoPower' selects={rOnOffOptions} value={advancedAutoPower} onChange={onChange} disabled={advancedAutoPower == -1} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(advancedStandBy) && advancedStandBy >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['advancedStandBy'],
                                    'response_warning': !isNil(get(responseWarning, 'advancedStandBy'))
                                    })}>{t('TEXT_STANDBY_CONTROL_P')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='advancedStandBy' selects={deviceOptions.standbyControl} value={advancedStandBy} 
                                        onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(networkStandbyMode) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['networkStandbyMode'],
                                    'response_warning': !isNil(get(responseWarning, 'networkStandbyMode'))
                                    })}>{t('COM_LFD_SID_TIZEN_CAMAR_REMOTE_CONFIGURATION')}</th>
                                <td colSpan="2">
                                    {
                                        networkStandbyMode === 'N' ?
                                        t('COM_TV_SID_UNSUPPORTED_VERSION') :
                                        <RadioGroup propertyName='networkStandbyMode' selects={rOnOffOptions} value={networkStandbyMode} 
                                            onChange={onChange} disabled={networkStandbyMode == -1} />
                                    }
                                </td>
                            </tr>
                        }
                        {
                            !isNil(ecoSensor) && ecoSensor >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['ecoSensor'],
                                    'response_warning': !isNil(get(responseWarning, 'ecoSensor'))
                                    })}>{t('COM_SID_ECO_SENSOR')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='ecoSensor' selects={rOnOffOptions} value={ecoSensor} 
                                        onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(ecoSensor) && ecoSensor == 1 && !isNil(minBrightness) && minBrightness >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['minBrightness'],
                                    'response_warning': !isNil(get(responseWarning, 'minBrightness'))
                                    })}>{deviceType === LEDBOX || deviceType === RLEDBOX ? t('COM_SID_MIN_BRIGHTNESS') : t('COM_SID_MIN_BACKLIGHT')}</th>
                                <td colSpan="2">
                                    <NumberInput propertyName='minBrightness' value={minBrightness} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(liveMode) && liveMode >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['liveMode'],
                                    'response_warning': !isNil(get(responseWarning, 'liveMode'))
                                    })}>{t('COM_SID_LEDSIGNAGE_LIVE_MODE')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='liveMode' selects={rOnOffOptions} value={liveMode} 
                                        onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(advancedUserAutoColor) && advancedUserAutoColor >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['advancedUserAutoColor'],
                                    'response_warning': !isNil(get(responseWarning, 'advancedUserAutoColor'))
                                    })}>{t('TEXT_USER_AUTO_COLOR_P')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='advancedUserAutoColor' selects={deviceOptions.userAutoColor} value={advancedUserAutoColor} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(rgbOnlyMode) && rgbOnlyMode >= 0 &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['rgbOnlyMode'],
                                    'response_warning': !isNil(get(responseWarning, 'rgbOnlyMode'))
                                    })}>{t('COM_SID_RGB_ONLY_MODE')}</th>
                                <td colSpan="2">
                                    <Select width={200} propertyName='rgbOnlyMode' selects={deviceOptions.rgbOnlyMode} value={rgbOnlyMode} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        <tr>
                            <th className={classNames({
                                'changed': changed['advancedReset'],
                                'response_warning': !isNil(get(responseWarning, 'advancedReset'))
                                })}>{t('COM_DID_LFD_RESET')}</th>
                            <td colSpan="2">
                                <RadioGroup propertyName='advancedReset' selects={advancedResetOption} value={advancedReset} onChange={onChange} />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td colSpan="2">
                                <RadioGroup propertyName='advancedReset' selects={advancedResetOption2} value={advancedReset} onChange={onChange} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditLedDimming = ({displayInfo, onChange}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {data: {dimmingOption, dimmingNightTimeOverride, dimmingEcoSensor, dimmingSunriseSunset, dimmingSunriseSunsetTimes, dimmingBrightnessOutput}, changed, responseWarning = {}} = displayInfo || {};

    if(isNil(dimmingOption) || isNil(dimmingNightTimeOverride) || isNil(dimmingEcoSensor) || isNil(dimmingSunriseSunset) || isNil(dimmingBrightnessOutput)) {
        return null;
    }

    const onClickViewSunsetSunriseTimes = (data) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.LED_SUNRISE_SUNSET_TIMES,
            type: commonConstants.LED_SUNRISE_SUNSET_TIMES,
            sunriseSunsetData: data,
            onOk: () => dispatch(popupAction.closePopup(commonConstants.LED_SUNRISE_SUNSET_TIMES)),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.LED_SUNRISE_SUNSET_TIMES)),
        }));        
    }

    const showHide = item => {
        let ret = true;
        switch(item){
            case 'dimmingNightTimeOverride':
                ret = !isNil(dimmingOption) && dimmingOption != 0;
                break;
            case 'dimmingEcoSensor':
                ret = !isNil(dimmingOption) && dimmingOption != 0 && dimmingOption != 3;
                break;
            case 'dimmingSunriseSunset':
                ret = !isNil(dimmingOption) && dimmingOption != 0;
                if(dimmingOption == 2 && dimmingNightTimeOverride == 0)
                    ret = false;
                break;
        }
        return ret;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('TMP_TSIM_LED_LED_DIMMING')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width="161px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            showHide('dimmingOption') &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['dimmingOption'],
                                    'response_warning': !isNil(get(responseWarning, 'dimmingOption'))
                                    })}>{t('TMP_TSIM_LED_DIMMING_OPTION')}</th>
                                <td colspan={2}>
                                    <Select width={200} propertyName='dimmingOption' selects={deviceOptions.ledDimmingOption} value={dimmingOption} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            showHide('dimmingNightTimeOverride') && 
                            <tr>
                                <th className={classNames({
                                    'changed': changed['dimmingNightTimeOverride'],
                                    'response_warning': !isNil(get(responseWarning, 'dimmingNightTimeOverride'))
                                    })}>{t('TMP_TSIM_LED_NIGHT_TIME_OVERRIDE')}</th>
                                <td colspan={2}>
                                    <RadioGroup propertyName='dimmingNightTimeOverride' selects={deviceOptions.onOffOptions} value={dimmingNightTimeOverride} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            showHide('dimmingEcoSensor') && 
                            <tr>
                                <th className={classNames({
                                    'changed': changed['dimmingEcoSensor'],
                                    'response_warning': !isNil(get(responseWarning, 'dimmingEcoSensor'))
                                    })}>{t('COM_SID_ECO_SENSOR')}</th>
                                <td className={classNames({
                                    'changed': get(changed, 'dimmingEcoSensor.maxValue'), 
                                    'response_warning': !isNil(get(responseWarning, 'dimmingEcoSensor.maxValue'))
                                    })}>{t('TMP_TSIM_LED_MAX').replace('<<A>>', t('TMP_TSIM_LED_UNIT_LUMEN'))}</td>                                    
                                <td>
                                    <NumberInput propertyName='dimmingEcoSensor.maxValue' value={dimmingEcoSensor.maxValue} min={10} max={11000} step={10} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            showHide('dimmingEcoSensor') && 
                            <tr>
                                <th></th>
                                <td className={classNames({
                                    'changed': get(changed, 'dimmingEcoSensor.minValue'),
                                    'response_warning': !isNil(get(responseWarning, 'dimmingEcoSensor.minValue'))
                                    })}>{t('TMP_TSIM_LED_MIN').replace('<<A>>', t('TMP_TSIM_LED_UNIT_LUMEN'))}</td>                                    
                                <td>
                                    <NumberInput propertyName='dimmingEcoSensor.minValue' value={dimmingEcoSensor.minValue} min={10} max={11000} step={10} onChange={onChange} forceInt />
                                </td>
                            </tr>
                        }                        
                        {
                            showHide('dimmingSunriseSunset') && 
                            <tr>
                                <th className={classNames({
                                    'changed': changed['dimmingSunriseSunset'],
                                    'response_warning': !isNil(get(responseWarning, 'dimmingSunriseSunset'))
                                    })}>{t('TMP_TSIM_LED_SUNRISE_SUNSET')}</th>
                                <td colspan={2}>{(dimmingSunriseSunset.latitude & dimmingSunriseSunset.longitude)? dimmingSunriseSunset.latitude + ', ' + dimmingSunriseSunset.longitude: ''}</td>
                            </tr>
                        }
                        {
                            showHide('dimmingSunriseSunset') && 
                            <tr>
                                <th></th>
                                <td className={classNames({
                                    'changed': get(changed, 'dimmingSunriseSunset.rampTime'),
                                    'response_warning': !isNil(get(responseWarning, 'dimmingSunriseSunset.rampTime'))
                                    })}>{t('TMP_TSIM_LED_SUNRISE_SUNSET_RAMP_TIME')}</td>
                                <td>
                                    <Select width={200} propertyName='dimmingSunriseSunset.rampTime' selects={deviceOptions.time10Interval70MinOption} value={dimmingSunriseSunset.rampTime} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            showHide('dimmingSunriseSunset') && 
                            <tr>
                                <th></th>
                                <td>{t('TMP_TSIM_LED_SUNRISE_SUNSET_TIME')}</td>
                                <td><WhiteButton id='VIEW_SUNSET_SUNRISE_TIMES' name={t('BUTTON_VIEW_P')} width={70} onClick={() => onClickViewSunsetSunriseTimes({dimmingSunriseSunset, dimmingSunriseSunsetTimes})} disable={!dimmingSunriseSunsetTimes || !isArray(dimmingSunriseSunsetTimes)}/></td>
                            </tr>
                        }
                        {
                            showHide('dimmingBrightnessOutput') && 
                            <tr>
                                <th className={classNames({
                                    'changed': changed['dimmingBrightnessOutput'],
                                    'response_warning': !isNil(get(responseWarning, 'dimmingBrightnessOutput'))
                                    })}>{t('TMP_TSIM_LED_BRIGHTNESS_OUTPUT')}</th>
                                <td className={classNames({
                                    'changed': get(changed, 'dimmingBrightnessOutput.defaultValue'),
                                    'response_warning': !isNil(get(responseWarning, 'dimmingBrightnessOutput.defaultValue'))
                                    })}>{t('TEXT_DEFAULT_SCHEDULE_P')}</td>
                                <td>
                                    <Select width={200} propertyName='dimmingBrightnessOutput.defaultValue' selects={deviceOptions.percent10IntervalOption} value={dimmingBrightnessOutput.defaultValue} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            showHide('dimmingBrightnessOutput') && 
                            <tr>
                                <th></th>
                                <td className={classNames({
                                    'changed': get(changed, 'dimmingBrightnessOutput.maxValue'),
                                    'response_warning': !isNil(get(responseWarning, 'dimmingBrightnessOutput.maxValue'))
                                    })}>{t('TMP_TSIM_LED_MAX').replace('<<A>>', '%')}</td>
                                <td>
                                    <Select width={200} propertyName='dimmingBrightnessOutput.maxValue' selects={deviceOptions.percent10IntervalOption} value={dimmingBrightnessOutput.maxValue} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            showHide('dimmingBrightnessOutput') && 
                            <tr>
                                <th></th>
                                <td className={classNames({
                                    'changed': get(changed, 'dimmingBrightnessOutput.minValue'),
                                    'response_warning': !isNil(get(responseWarning, 'dimmingBrightnessOutput.minValue'))
                                    })}>{t('TMP_TSIM_LED_MIN').replace('<<A>>', '%')}</td>
                                <td>
                                    <Select width={200} propertyName='dimmingBrightnessOutput.minValue' selects={deviceOptions.percent10IntervalOption} value={dimmingBrightnessOutput.minValue} onChange={onChange} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
     );
 };

const isPcMode = basicSource => {
    switch(basicSource){
        case 20: // PC
        case 30: // BNC
        case 24: // DVI
        case 37: // Display Port
        case 38: // Display Port2
        case 32: // MagicInfo
        case 34: // HDMI1_PC
        case 36: // HDMI2_PC
        case 50: // HDMI3_PC
        case 52: // HDMI4_PC
        case 80: // Plug In Module
            return true;
        default:
            return false;
    }
};

const EditDisplay = (props) => {
    const {t} = useTranslation();
    const [viewMore, setViewMore] = useState(false);
    const {displayInfo: {data: {deviceType, basicSource, mdcUpdateTime, networkStandbyMode} = {}} = {}} = props;
    const nBasicSource = toInteger(basicSource);
    const pcMode = isPcMode(nBasicSource);

    const onClickViewMore = () => {
        const {power, onClickViewMore} = props;
        if(power) {
            if(onClickViewMore) {
                let beforeOneHour = new Date();
                beforeOneHour.setHours(beforeOneHour.getHours() - 1);

                if(isNil(mdcUpdateTime) || isNil(basicSource) || isNil(networkStandbyMode)|| new Date(mdcUpdateTime) < beforeOneHour)
                    onClickViewMore(() => setViewMore(true));
                else
                    setViewMore(true)
            }
        } else {
            toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        }
    };

    return (
        <div className='device_edit_panel_wrap mt28'>
            <EditGeneral {...props} />
            {
                //(deviceType === LEDBOX || deviceType === RLEDBOX) && <EditLedDimming {...props} />
            }
            {
                deviceType !== APLAYER && <EditFanNTemp {...props} />
            }
            {
                !viewMore && deviceType !== APLAYER &&
                <div className='devicepop setup mb26' style={{height: '100px'}}>
                    <WhiteButton id="viewMore" name={t('BUTTON_MORE_VIEW_P')} onClick={onClickViewMore} />
                </div>
            }
            {
                viewMore &&
                <>
                    {
                        !pcMode && <EditVideoMode {...props} />
                    }
                    <EditPicture {...props} pcMode={pcMode} />
                    {
                        deviceType === SIGNAGE_CHILD && (nBasicSource === 20 || nBasicSource === 30) &&
                        <EditPictureInfo {...props} />
                    }
                    <EditSound {...props} />
                    {
                        (nBasicSource === 20 || nBasicSource === 30) &&
                        <EditWhiteBalance {...props} />
                    }
                    <EditMaintenance {...props} />
                    <EditScreenBurnProtection {...props} />
                    <EditAdvancedSetting {...props} />
                    <div className='devicepop setup mb26' style={{height: '100px'}}>
                        <WhiteButton id="close" name={t('COM_BUTTON_CLOSE_P')} onClick={() => setViewMore(false)} />
                    </div>
                </>
            }
        </div>
    );
};

EditDisplay.defaultProps = {
    displayInfo: {
        data: {},
        changed: {},
        errors: {},
    },
    onChange: () => {},
}

export default EditDisplay;