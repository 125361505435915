
export const settingConstants = {
    ADD_TAG_POPUP: 'ADD_TAG_POPUP',
    EDIT_TAG_POPUP: 'EDIT_TAG_POPUP',
    EDIT_EDGE_POPUP: 'EDIT_EDGE_POPUP',
    ADD_REMOTE_CONTROL_SERVER_POPUP: 'ADD_REMOTE_CONTROL_SERVER_POPUP',
    EDIT_REMOTE_CONTROL_SERVER_POPUP: 'EDIT_REMOTE_CONTROL_SERVER_POPUP',
    ADD_DATALINK_SERVER_POPUP: 'ADD_DATALINK_SERVER_POPUP',
    EDIT_DATALINK_SERVER_POPUP: 'EDIT_DATALINK_SERVER_POPUP',
    ASSIGN_DATALINK_TABLE_POPUP: 'ASSIGN_DATALINK_TABLE_POPUP',
    FIRST_ACTIVATION_POPUP_INIT : 'FIRST_ACTIVATION_POPUP_INIT',
    FIRST_ACTIVATION_POPUP_ONLINE : 'FIRST_ACTIVATION_POPUP_ONLINE',
    FIRST_ACTIVATION_POPUP_OFFLINE : 'FIRST_ACTIVATION_POPUP_OFFLINE',
    ADDITIONAL_ACTIVATION_POPUP_INIT :'ADDITIONAL_ACTIVATION_POPUP_INIT',
    ADDITIONAL_ACTIVATION_POPUP_ONLINE : 'ADDITIONAL_ACTIVATION_POPUP_ONLINE',
    ADDITIONAL_ACTIVATION_POPUP_OFFLINE : 'ADDITIONAL_ACTIVATION_POPUP_OFFLINE',
    RETURN_LICENSE_POPUP_INIT : 'RETURN_LICENSE_POPUP_INIT',
    RETURN_LICENSE_POPUP_CONFIRM : 'RETURN_LICENSE_POPUP_CONFIRM',
    FREE_LICENSE_POPUP : 'FREE_LICENSE_POPUP',
    LICENSE_HISTORY_POPUP : 'LICENSE_HISTORY_POPUP',
    LICENSE_ASSIGN_POPUP : 'LICENSE_ASSIGN_POPUP',
    E2E_COMPANY_INFO_POPUP : 'E2E_COMPANY_INFO_POPUP',
    E2E_LICENSE_HISTORY_POPUP : 'E2E_LICENSE_HISTORY_POPUP',
    E2E_LICENSE_SWAP_POPUP : 'E2E_LICENSE_SWAP_POPUP',
    NOTIFICATIONS_SETTING_POPUP: 'NOTIFICATIONS_SETTING_POPUP',
    NOTIFICATIONS_SETTING_2DIV_POPUP: 'NOTIFICATIONS_SETTING_2DIV_POPUP',
    LOG_DOWNLOAD_POPUP: 'LOG_DOWNLOAD_POPUP',
    DB_ACCESS_POPUP: 'DB_ACCESS_POPUP',
    VIEW_TAG_MIND_MAP_POPUP: 'VIEW_TAG_MIND_MAP_POPUP',
    SERVICE_HISTORY_POPUP: 'SERVICE_HISTORY_POPUP',
    PRIVACY_POLICY_POPUP: 'PRIVACY_POLICY_POPUP',
    LOAD_MY_ACCOUNT: 'LOAD_MY_ACCOUNT',
    UPDATE_MY_ACCOUNT: 'UPDATE_MY_ACCOUNT',
    CHECK_PASSWORD: 'CHECK_PASSWORD',
    PRIVACY_POLICY_LOCATION_WITHOUT_AGREE:'EU',
    LOAD_SERVER_SETTINGS : 'LOAD_SERVER_SETTINGS',
    UPDATE_SERVER_SETTINGS : 'UPDATE_SERVER_SETTINGS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    NO_CHANGED : "_nochanged",
    MFA_STORED_DEVICE : "STORED_DEVICE",
    DELETE_MFA_INFO_POPUP : 'DELETE_MFA_INFO_POPUP',
    MFA: 'MFA',
    ADD_EXTERNAL_LINK_POPUP: 'ADD_EXTERNAL_LINK_POPUP',
    //E2E License
    E2E_LICENSE_SYSTEM_SLM : 'SLM',
    E2E_LICENSE_SYSTEM_PBP : 'PBP',
    ADD_INSIGHT_INDEX_POPUP: 'ADD_INSIGHT_INDEX_POPUP',
    EDIT_INSIGHT_INDEX_POPUP: 'EDIT_INSIGHT_INDEX_POPUP',
    INSIGHT_INDEX_PRODUCT_CODE : 'PRODUCT_CODE',
    REGISTER_LOGIN_PAGE_IMAGE: 'REGISTER_LOGIN_PAGE_IMAGE'
}

export const SETTING_PAGE_SIZE_OPTIONS = [30, 50, 100];

export const SMTP_PASSWORD_LENGTH = 100

export const LICENSE_INFO = {
    MIGRATION_PRODUCT_CODE : '01010M',
    NOT_USED_PRODUCT_CODE_LIST : ['010120', '010121', '010V31', '01014A']
}

export const LICENSE_CHARGING_TYPE = {
    CHARGED : 'Charged',
    FREE_OF_CHARGED : 'Free of charged',
    FREE_TRIAL : 'Free Trial'
}

export const LICENSE_TYPE = {
    UNIFIED : 'Unified Player',
    LITE : 'Lite Player',
    RM : 'RM Player',
    ANDROID : 'Android Player'
}

export const getLocationSID = (location) => {

    switch(location){
        case 'US' :
            return 'COM_SETUP_COUNTRY_US';
        case 'EU' :
            return 'COM_EUROPE';
        case 'Korea' :
            return 'COM_TV_SID_KOREA';
        case 'China' :
            return 'COM_SID_COUNTRY_CHINA';
        case 'Others' :
            return 'COM_SID_COUNTRY_OTHERS';

    }
}

export const SETTING_FILTERS = {
    ALL_TAG : {
        page: 0,
        keyword: '',
        organId: 'ALL',
        sorted: [{id: 'create_date', desc: true}],
        reload: false
    },
    ALL_INSIGHT_INDEX : {
        page: 0,
        searchText: '',
        sorted: [{id: 'create_date', desc: true}],
        reload: false
    }
}
