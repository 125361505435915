import React from 'react';
import './ContentPopup.css';
import MISDialog from "../MISDialog";
import {withTranslation} from "react-i18next";
import SubTab from "../../tab/SubTab";
import {Contents} from "./Contents";
import {Playlists} from "./Playlists";
import {InputSources} from "./InputSources";
import {RuleSets} from './RuleSets';
import {isNil, snakeCase} from "lodash";
import {toastr} from 'helper/toastrIntercept';
import {MISOptContext} from "../../misopt";
import {
    CONTENT_SCHEDULE_CONFIG,
    CONTENT_SCHEDULE_PROGRAM_TYPE_LFD,
    CONTENT_SCHEDULE_PROGRAM_TYPE_VWL
} from "../../../constants";
import {getAllMediaTypesForFilter, getSupportedContentType} from "../../../helper/schedule/scheduleUtils";

class ContentPopup extends React.Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentTab:  props.defaultTab !== undefined ? props.defaultTab : 'CONTENT',
            subTab: {},
            contentFilter: {},
            subTabs: [],
            mode: props.mode || 'general',
            selected: [],
            selectedIds: []
        }

    }

    componentDidMount() {
        const {deviceType, deviceTypeVersion, programType = 'LFD', misOptionSchedule, isVwlMode } = this.props;
        let scheduleOption = {};
        if (deviceType!== undefined && deviceTypeVersion !== undefined) {
            if (misOptionSchedule !== undefined) {
                const {contentSchedule: {contents}} = misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion)
                if (contents !== undefined) {
                    scheduleOption = contents;
                    if(programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL){
                        scheduleOption = {...scheduleOption, ruleset: false}
                    }
                } else {
                    scheduleOption = {content: true, playlist: true, inputSource: true, ruleset: true};
                }
            }
        }

        let showContent = true, showPlaylist = true, showInputSource = true, showRuleset = true;
        let contentFilter = {
            startIndex: 1,
            pageSize: 25,
            contentTypes: programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL && isVwlMode ? ['VWL','IMAGE','MOVIE'] : getSupportedContentType(programType, deviceType),
            deviceType: deviceType !== undefined ? deviceType : undefined,
            deviceTypeVersion: deviceType !== undefined ? deviceTypeVersion : undefined,
            sortColumn: snakeCase('lastModifiedDate').toUpperCase(),
            expirationStatus: 'VALID',
            sortOrder: 'DESC',
            render: false,
            approvalStatus: "APPROVED"
        };

        let playlistFilter = {
            startIndex: 1,
            pageSize: 25,
            deviceType: deviceType !== undefined ? deviceType : undefined,
            deviceTypeVersion : deviceType !== undefined && deviceTypeVersion !== undefined ? deviceTypeVersion : undefined,
            playlistTypes: programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL && isVwlMode ? ['2'] : CONTENT_SCHEDULE_CONFIG[programType].supportedPlaylistFilter,
            sortColumn: snakeCase('lastModifiedDate'),
            sortOrder: 'desc'
        }

        if (this.state.mode !== undefined) {
            switch (this.state.mode) {
                case 'content_playlist_filter':
                    showContent = true;
                    showPlaylist = true;
                    showInputSource = false;
                    showRuleset = false;
                    contentFilter = {
                        ...contentFilter,
                        contentTypes: getAllMediaTypesForFilter()
                    }
                    break;
                case 'event_condition':
                    showPlaylist = false;
                    showInputSource = false;
                    showRuleset = false;
                    break;
                case 'bgm':
                    showPlaylist = false;
                    showInputSource = false;
                    showRuleset = false;
                    contentFilter = {
                        ...contentFilter,
                        contentTypes: ['SOUND']
                    }
                    break;
                case 'default_content_on_frame':
                    showPlaylist = false;
                    showInputSource = false;
                    showRuleset = false;
                    contentFilter = {
                        ...contentFilter,
                        contentTypes: ['MOVIE', 'FLASH']
                    }
                    break;
                case 'advertisement_schedule':
                    showContent = false;
                    showPlaylist = true;
                    showInputSource = false;
                    showRuleset = false;
                    playlistFilter = {
                        ...playlistFilter,
                        playlistTypes: [4]
                    }
                    break;
                default:
                    if (!scheduleOption.content) {
                        showContent = false;
                    }
                    if (!scheduleOption.playlist) {
                        showPlaylist = false;
                    }
                    if (!scheduleOption.inputSource) {
                        showInputSource = false;
                    }
                    if (!scheduleOption.ruleset) {
                        showRuleset = false
                    } else {
                        if (programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_LFD) {
                            showRuleset = false
                        }
                    }
                    break;
            }
        }
        const subTabs = [];
        if (showContent) {
            subTabs.push('TEXT_CONTENT_P');
        }
        if (showPlaylist) {
            subTabs.push('TEXT_TITLE_PLAYLIST_P');
        }
        if (showInputSource) {
            subTabs.push('TABLE_INPUT_SOURCE_P');
        }
        if (showRuleset) {
            subTabs.push('MIS_SID_RULESET');
        }

        this.setState({
            subTabs: subTabs,
            contentFilter: contentFilter,
            playlistFilter: playlistFilter,
            subTab: subTabs[0]
        })
        //this.activePopupTab(type);

    }

    onClickSubTab = (id) => {
        this.setState({subTab: id});
    };

    onSelect = (id, name, thumbFileId, mainFileName, totalSize, contentType, playlistType) => {
        

        const {multiple} = this.props;
        const selected = {
            selectedId: id,
            selectedName: name,
            selectedThumbFileId: thumbFileId,
            selectedFileName: mainFileName,
            selectedFileSize: totalSize,
            selectedContentType: contentType,
            selectedPlaylistType: contentType === 'PLAYLIST' ? playlistType : undefined
        }
        if (multiple) {

            const {selectedIds} = this.state;

            const find = selectedIds.find(s => s===id);
            if (find) {
                this.setState({
                    selected : this.state.selected.filter(s=> s.selectedId !== id),
                    selectedIds: this.state.selectedIds.filter(s=> s !== id)
                })
            } else {
                this.setState({
                    selected : this.state.selected.concat(selected),
                    selectedIds: this.state.selectedIds.concat(id)
                })
            }

        } else {
            this.setState({
                selectedId: id,
                selectedName: name,
                selectedThumbFileId: thumbFileId,
                selectedFileName: mainFileName,
                selectedFileSize: totalSize,
                selectedContentType: contentType,
                selectedPlaylistType: contentType === 'PLAYLIST' ? playlistType : undefined,
                selectedIds: [].concat(id)
            })
        }

    }

    onSelectContent = () => {
        const {t, selectContent, multiple} = this.props;
        const {selected} = this.state;
        if (multiple) {
            if (selected !== undefined && selected.length < 1) {
                toastr.error(t("COM_TEXT_SELECT_CONTENT_P"));
            } else {
                selectContent(selected);
            }
        } else {
            if (isNil(this.state.selectedId) || isNil(this.state.selectedName)) {
                toastr.error(t("COM_TEXT_SELECT_CONTENT_P"));
            } else {
                selectContent(this.state.selectedId, this.state.selectedName, this.state.selectedThumbFileId, this.state.selectedFileName, this.state.selectedFileSize, this.state.selectedContentType, this.state.selectedPlaylistType);
            }
        }
    }

    render() {
        const {t, closeContentPopup, selectContent, deviceType, deviceTypeVersion} = this.props;
        const {subTab, subTabs} = this.state;
        const dialogProps ={title: t("COM_TEXT_SELECT_CONTENT_P"), closeOnEscape : true, width :620, height :480 , /*position: {x: 680/2*-1, y: 480/2*-1},*/ modal:true, onClose: ()=>closeContentPopup()};

        //const scheduleInfo = getScheduleInfo(deviceType, deviceTypeVersion);

        const buttons = {
            rightButtons: [
                {id: 'SELECT_CONTENT', title: t("TEXT_SELECT_P"), onClick: ()=>this.onSelectContent()},
                {id: 'CLOSE_CONTENT_POPUP', title: t("COM_BUTTON_CLOSE_P"), onClick: ()=>closeContentPopup()}]}

        return (
            <div className="content_dialog">
                <MISDialog
                    {...dialogProps}
                    dialog = {dialogProps}
                    buttons = {buttons}
                >
                    <SubTab tabs={subTabs} selected={subTab} viewSize={4} onClick={this.onClickSubTab} />

                    { subTab === 'TEXT_CONTENT_P' && <Contents multiple={this.props.multiple} isVwlMode={this.props.isVwlMode} mode={this.props.mode} deviceType={deviceType} deviceTypeVersion={deviceTypeVersion} filter={this.state.contentFilter} onSelect={this.onSelect} selectedIds={this.state.selectedIds} programType={this.props.programType}/> }
                    { subTab === 'TEXT_TITLE_PLAYLIST_P' && <Playlists multiple={this.props.multiple} isVwlMode={this.props.isVwlMode} deviceType={deviceType} deviceTypeVersion={deviceTypeVersion} filter={this.state.playlistFilter}  onSelect={(id, name, thumbnail, playlistType, totalSize)=>this.onSelect(id, name, thumbnail, undefined, totalSize, 'PLAYLIST', playlistType)} selectedIds={this.state.selectedIds} programType={this.props.programType}/> }
                    { subTab === 'TABLE_INPUT_SOURCE_P' && <InputSources multiple={this.props.multiple} deviceType={deviceType} deviceTypeVersion={deviceTypeVersion} onSelect={(id, name)=>this.onSelect(id, name, null, undefined, undefined, 'HW_IS')} selectedId={this.state.selectedId}/>}
                    { subTab === 'MIS_SID_RULESET' && <RuleSets multiple={this.props.multiple} deviceType={deviceType} deviceTypeVersion={deviceTypeVersion}  onSelect={(id, name, totalSize, thumbnail)=>this.onSelect(id, name, thumbnail, undefined, totalSize, 'RULESET')} selectedId={this.state.selectedId}/>}

                </MISDialog>
            </div>
        )
    }

}
ContentPopup.defaultProps = {
    multiple: false,
    isVwlMode :false,
}
export default withTranslation()(ContentPopup);