import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {get, isNil, isObject, set} from 'lodash';
import {connect} from "react-redux";
import {popupAction} from "../../../../actions";
import MISDialog from '../../MISDialog';
import {deviceService} from "../../../../services";
import {deviceValidator} from '../../../../helper';
import './EditFlipDevice.css';
import DeviceTitle from '../../../device/DeviceTitle';
import EditFlipInformation from "./EditFlipInformation";
import {commonConstants} from "../../../../constants";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from 'helper/responseHandler';

const handleChange = (e, value, info, t) => {
    const name = e['data-name'] || e.target.getAttribute('data-name');
    const newInfo = {...info};
    const {data, changed, errors} = newInfo;

    set(changed, name, true);
    if (!isNil(value)) {
        set(data, name, value);
    } else if (!isNil(e.target.value)) {
        set(data, name, e.target.value);
    } else {
        set(data, name, e.target.getAttribute('data-value'));
    }

    const result = deviceValidator(name, get(data, name));
    if (result) {
        if (result.isValid) {
            delete errors[name];
        } else {
            errors[name] = t(result.messageId);
        }
    }
    return newInfo;
};

export const useDeviceInfo = (initialInfo, t) => {
    const [deviceInfo, setDeviceInfo] = useState(initialInfo);

    const onChangeDeviceInfo = (e, value) => {
        const newInfo = handleChange(e, value, deviceInfo, t);
        setDeviceInfo(newInfo);
    };
    return [deviceInfo, setDeviceInfo, onChangeDeviceInfo];
};

const EditFlipDevice = ({deviceIds, tabIndex, onSave, onClose, addPopup, closePopup}) => {
    const {t} = useTranslation();
    const timer = useRef(null);
    const checkCount = useRef(0);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [title, setTitle] = useState(`${t('COM_BUTTON_EDIT')}`);
    const [deviceTypeVersion, setDeviceTypeVersion] = useState(1);
    const [power, setPower] = useState(false);
    const [generalInfo, setGeneralInfo, onChangeGeneralInfo] = useDeviceInfo({
        data: {},
        changed: {},
        errors: {},
    }, t);
    const [setupInfo, setSetupInfo, onChangeSetupInfo] = useDeviceInfo({
        data: {},
        changed: {},
        errors: {},
    }, t);
    const [displayInfo, setDisplayInfo, onChangeDisplayInfo] = useDeviceInfo({
        data: {},
        changed: {},
        errors: {},
    }, t);
    const [securityInfo, setSecurityInfo, onChangeSecurityInfo] = useDeviceInfo({
        data: {},
        changed: {},
        errors: {},
    }, t);
    useEffect(() => {
        return () => {
            if (timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    useEffect(() => {
        handleSaveDisabled();
    }, [generalInfo, setupInfo, displayInfo, securityInfo]);

    const handleSaveDisabled = () => {
        const infoList = [generalInfo, setupInfo, displayInfo, securityInfo];

        let changeCnt = 0;
        let errorCount=0;
        
        infoList.map((info) => {
            if(errorCount==0) {
                if (Object.keys(info.changed).length > 0) {
                    changeCnt++;
                }                   
                if (Object.keys(info.errors).length > 0) {
                    errorCount++;
                }                 
            }
        });
        if(errorCount>0)
        {
            setSaveDisabled(true);
        }
        else 
        {
            if(changeCnt>0)
            {
                setSaveDisabled(false);
            }
            else
            {
                setSaveDisabled(true);
            }  
        }      

    };

    const createUpdateInfo = () => {
        const infoList = [generalInfo, setupInfo, displayInfo, securityInfo];
        const updateInfo = {};
        infoList.map(info => {
            for (let propName in info.changed) {
                set(updateInfo, convertSnakeToCamel(propName), get(info.data, propName));
            }
        });
        return updateInfo;
    };

    const getPropPath = (path, propName) => {
        return path ? `${path}.${propName}` : propName;
    };

    const setResponseWarning = (changed, data, updateResult, responseWarning, path) => {
        for(let propName in changed) {
            const changedProp = get(changed, propName);
            if(isObject(changedProp)) {
                setResponseWarning(changedProp, get(data, propName), get(updateResult, propName), responseWarning, getPropPath(path, propName));
            } else {
                if(get(data, propName) != get(updateResult, propName)) {
                    set(responseWarning, getPropPath(path, propName), get(updateResult, propName));
                }
            }
        }
    };

    const createResponseWarning = (info, updateResult) => {
        let responseWarning = {};
        const {data, changed} = info;
        setResponseWarning(changed, data, updateResult, responseWarning);
        return responseWarning;
    };

    const fetchUpdateResult = (fetch, updateInfo, requestId, timer, closePopup, checkCount, t) => {
        return fetch(updateInfo, requestId).then(res => {
            if(res.status === 'Fail') {
                checkCount.current ++;
                if(checkCount.current >= 60) {
                    clearInterval(timer.current);
                    timer.current = null;
                    checkCount.current = 0;
                    closePopup('EDIT_DEVICE_LOADING');
                    toastr.warning(t('MESSAGE_COMMON_ERROR_P'));
                }
                throw 'Fail';
            } else {
                clearInterval(timer.current);
                timer.current = null;
                checkCount.current = 0;
                closePopup('EDIT_DEVICE_LOADING');
                return res.items;
            }

        }).catch(error => {
            checkCount.current ++;
            if(checkCount.current >= 60) {
                clearInterval(timer.current);
                timer.current = null;
                checkCount.current = 0;
                closePopup('EDIT_DEVICE_LOADING');
                toastr.warning(t('MESSAGE_COMMON_ERROR_P'));
            }
            throw error;
        });
    };

    const convertSnakeToCamel = (snakeValue) => {
        let camelValue = '';
        const valueList = snakeValue.split('_');
        valueList.map((value, index) => {
            if(index === 0) {
                camelValue += value;
            } else {
                let firstChar = value.substring(0,1).toUpperCase();
                camelValue += firstChar.concat(value.substring(1));
            }
        });

        return camelValue;
    };

    const handleSave = () => {
        const updateInfo = Object.assign({}, {ids: deviceIds}, createUpdateInfo());
        deviceService.updateDeviceAllInfo(updateInfo).then(response => {
            toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
            if(deviceIds.length === 1) {
                
                if(isNil(response.items.requestId)){
                    setSecurityInfo({...securityInfo, changed: {}}); // Remote Control Server Lock only.
                    closePopup('EDIT_DEVICE_LOADING');
                    return;
                }

                deviceService.getDeviceCurrentAllInfo(deviceIds[0], res => {
                    closePopup('EDIT_DEVICE_LOADING');
                    toastr.success(t('MSG_FINISHED'));
                    const {successList = [{}]} = res.items;
                    setGeneralInfo({data: successList[0].general, changed: {}, errors: {}, responseWarning: createResponseWarning(generalInfo, successList[0].general)});
                    setSetupInfo({data: successList[0].setup, changed: {}, errors: {}, responseWarning: createResponseWarning(setupInfo, successList[0].setup)});
                    setDisplayInfo({data: successList[0].display, changed: {}, errors: {}, responseWarning: createResponseWarning(displayInfo, successList[0].display)});
                    setSecurityInfo({data: successList[0].security, changed: {}, errors: {}, responseWarning: createResponseWarning(securityInfo, successList[0].security)});
                }, 30, 1500).catch(error => {
                    closePopup('EDIT_DEVICE_LOADING');
                    toastr.error(getErrorMessage(error));
                });
            } else {
                closePopup('EDIT_DEVICE_LOADING');
                onClose();
            }
        }).catch(error => {
            toastr.error(t('MESSAGE_COMMON_ERROR_P'));
            closePopup('EDIT_DEVICE_LOADING');
        });
    };

    const onClickSave = () => {
        addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});
        const updateInfo = Object.assign({}, {deviceIds: deviceIds}, createUpdateInfo());
        deviceService.fetchDeviceConnectionCheck(deviceIds).then(res => {
            const {items: {successList = []} = {}} = res;
            if(successList.length === deviceIds.length) {
                handleSave();
            } else {
                closePopup('EDIT_DEVICE_LOADING');
                toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
            }
        }).catch(error => {
            closePopup('EDIT_DEVICE_LOADING');
            toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
        });
    };

    const onClickRefresh = () => {
        addPopup({id: 'EDIT_DEVICE_LOADING', type: commonConstants.LOADING_POPUP});

        deviceService.getDeviceCurrentAllInfo(deviceIds[0], res => {
            closePopup('EDIT_DEVICE_LOADING');
            toastr.success(t('MSG_FINISHED'));
            const {successList = [{}]} = res.items;
            setGeneralInfo({data: successList[0].general, changed: {}, errors: {}, responseWarning: createResponseWarning(generalInfo, successList[0].general)});
            setSetupInfo({data: successList[0].setup, changed: {}, errors: {}, responseWarning: createResponseWarning(setupInfo, successList[0].setup)});
            setDisplayInfo({data: successList[0].display, changed: {}, errors: {}, responseWarning: createResponseWarning(displayInfo, successList[0].display)});
            setSecurityInfo({data: successList[0].security, changed: {}, errors: {}, responseWarning: createResponseWarning(securityInfo, successList[0].security)});
        }, 30, 3000).catch(error => {
            closePopup('EDIT_DEVICE_LOADING');
            toastr.error(getErrorMessage(error));
        });        
    };

    const showCurrentStatusBtn = () => {
        return power ?
            [{
                id: 'COM_TV_SID_CURRENT_STATUS_KR_KOR',
                    title: t('COM_TV_SID_CURRENT_STATUS_KR_KOR'),
                onClick: onClickRefresh,
                disable: !power,
            }]
            :
            undefined;
    };

    const setPopupTitle = (device, count) => {
        setTitle(
            <span>
                {t('MIS_SID_SERVER_CBMAR_EDIT_DEVICE')} <DeviceTitle device={{...device}}/> {count > 1 && <>({t('SETUP_STRING028')} {deviceIds.length - 1})</>}
            </span>
        );
    }

    useEffect(() => {
        setPopupTitle({...generalInfo.data, power}, deviceIds.length);
    }, [power]);

    useEffect(() => {
        deviceService.fetchDeviceAllInfo({ids: deviceIds}).then(
            res => {
                setGeneralInfo({...generalInfo, data: res.items.successList[0].deviceControl.general, changed: {}, errors: {}});
                setDisplayInfo({...displayInfo, data: res.items.successList[0].deviceControl.display, changed: {}, errors: {}});
                setSecurityInfo({...securityInfo, data: res.items.successList[0].deviceControl.security, changed: {}, errors: {}});
                setSetupInfo({...setupInfo, data: res.items.successList[0].deviceControl.setup, changed: {}, errors: {}});
                setPopupTitle({...res.items.successList[0].deviceControl.general, power}, deviceIds.length);
                setPower(res.items.successList[0].power);
                setDeviceTypeVersion(res.items.successList[0].deviceTypeVersion);
            }
        );
    }, []);

    return (
        <div className='edit_device_pop_wrap'>
            <MISDialog
                dialog={{
                    title: title,
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 860,
                    height: 610,
                    onClose: onClose,
                }}
                buttons={{
                    leftButtons: showCurrentStatusBtn(),
                    rightButtons: [
                        {
                            id: 'DEVICE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onClickSave,
                            disable: saveDisabled,
                        },
                        {
                            id: 'DEVICE_CANCEL',
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: onClose,
                        }
                    ]
                }}>
                <EditFlipInformation deviceTypeVersion={deviceTypeVersion}
                                     generalInfo={generalInfo} setupInfo={setupInfo} displayInfo={displayInfo} securityInfo={securityInfo}
                                     onChangeGeneralInfo={onChangeGeneralInfo} onChangeSetupInfo={onChangeSetupInfo}
                                     onChangeDisplayInfo={onChangeDisplayInfo} onChangeSecurityInfo={onChangeSecurityInfo}/>
            </MISDialog>
        </div>
    );
};

EditFlipDevice.defaultProps = {
    tabIndex: 0,
    onSave: () => {
    },
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(EditFlipDevice);