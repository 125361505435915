import React, {useCallback, useEffect, useRef, useState} from 'react';

import {useDispatch, useSelector} from "react-redux";
import Moment from "moment";
import {createUUID, validThumbnailId, getFirstDayOfWeek} from "../../../helper/schedule/scheduleUtils";
import {menuAction, popupAction, scheduleAction} from "../../../actions";
import * as ReactDOM from "react-dom";
import ContentThumbnail from "../../../components/image/ContentThumbnail";
import {Calendar} from '../../../components/fullcalendar/core';
import dayGridPlugin from '../../../components/fullcalendar/daygrid'
import timeGridPlugin from '../../../components/fullcalendar/timegrid'
import listPlugin from '../../../components/fullcalendar/list';
import interactionPlugin from '../../../components/fullcalendar/interaction'
import {toastr} from 'helper/toastrIntercept';
import WhiteButton from "../../../components/button/WhiteButton";
import Channels from "../../../components/schedule/Channels";
import Frames from "../../../components/schedule/Frames";
import SupportedDeviceIcon from "../../../components/icon/SupportedDeviceIcon";
import {useTranslation} from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import './ScheduleManager.css';
import SelectContentsPlaylistsPopup from "../../../components/popup/schedule/content/SelectContentsPlaylistsPopup";
import {commonConstants, CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC, scheduleConstants} from "../../../constants";
import SaveContentSchedulePopup from "../../../components/popup/schedule/content/SaveContentSchedulePopup";
import AssociateTagPopup from "../../../components/popup/schedule/content/AssociateTagPopup";
import ContextMenu from "../../../components/tooltip/ContextMenu";
import TooltipThumbnail from "../../../components/tooltip/TooltipThumbnail";
import {bytesToSize, useCalendarOutsideClick, useResizeWindow} from "../../../helper";
import {useMISOpt} from "../../../components/misopt";
import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker";

//setting weeks start day for all date picker in MIS
import enUS from 'date-fns/locale/en-US';
enUS.options.weekStartsOn=getFirstDayOfWeek();
registerLocale('locale', enUS);
setDefaultLocale('locale'); 
let calendar = {};

const colors = ["#80cbff", "#6ee6a9", "#ff92b1", "#b22222", "#ff8c00", "#7B68EE"]

const ScheduleManager = ({mode, currContent}) => {

    const dispatch =  useDispatch();
    const {t} = useTranslation();

    const calendarRef = useRef();
    const currentChannelNoRef = useRef();
    const currentFrameIdRef = useRef();
    const moveCalendarRef = useRef();

    const {misopt} = useMISOpt();

    const getHeight = () => {
        const {innerHeight} = window;
        return innerHeight-170
    }

    const getTimeFormat = useCallback(()=> {
        const {user : {timeFormat}} = misopt;
        let meridiem = false, hour12 = false, omitZeroMinute = false;
        if (timeFormat !== '24hour') {
            meridiem = 'shot';
            hour12 = true;
            omitZeroMinute = true;
        }
        return {
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: omitZeroMinute,
            meridiem: meridiem,
            hour12: hour12
        }
    }, [])

    useResizeWindow((e)=> {
        calendar[mode].setOption('height', getHeight())
    })   

    const {program, currentFrameId, currentChannelNo, currentContentId} = useSelector(state => ({
        currentFrameId : state.schedules.contents[mode].currentFrameId,
        currentChannelNo : state.schedules.contents[mode].currentChannelNo,
        program : state.schedules.contents[mode].program,
        currentContentId: state.menu.currentContentId
    }));
    

    const {deviceType, deviceTypeVersion} = program;
    const channel = program.channels.find(channel => channel.channelNo === currentChannelNo);

    const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
    const events = frame.events;

     //[SF00201520] Frame edit authority check
     const hasPermissionForFrameCheck = useCallback(() =>{
        
        const channel = program.channels.find(channel => channel.channelNo === currentChannelNo);
        if (channel === undefined) return true;
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        if (frame === undefined) return true; 
        //hasPermissionForFrame is undefined when user creates new schedule, so returning true in that case.
        return frame.hasPermissionForFrame === undefined ? true: frame.hasPermissionForFrame; 
    }, [frame])

    const [scheduleInfoPopup, setScheduleInfoPopup] = useState({
        show: false,
        scheduleId: undefined,
        removeSchedule: undefined,
        type : undefined
    });

    const [saveContentSchedulePopup, setSaveContentSchedulePopup] = useState({
        show: false,
        mode: undefined
    });


    const [associateTagPopup, setAssociateTagPopup] = useState({
        show: false,
        associateTagPlaylistId: undefined,
        tags:[],
        devices:[],
        scheduleId: undefined
    })

    const [contextMenu, setContextMenu] = useState({
        show: false,
        position: {}
    })

    const [eventThumbnail, setEventThumbnail] = useState({
        show: false,
        position: {}
    })

    const [totalFileSize, setTotalFileSize] = useState(0);

    const isSubFrames = useCallback(()=> {
        return channel.frame.frameId !== currentFrameId;
    }, [currentFrameId])

    const getBackgroundColor = () => {
        const rand  = Math.floor(Math.random() * 6);
        return colors[rand];
    }

    const getMaxSchedulePriority = useCallback(()=> {
        const channel = program.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        const events = frame.events;

        let maxSchedulePriority = 0;
        for (const event of events) {
            if (event.priority >= maxSchedulePriority) {
                maxSchedulePriority = event.priority;
            }
        }
        return maxSchedulePriority+1;
    }, [currentChannelNo, currentFrameId, events]);


    const getMinSchedulePriority = useCallback(()=> {
        const channel = program.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        const events = frame.events;

        let minSchedulePriority = 999999;
        for (const event of events) {
            if (event.priority < minSchedulePriority) {
                minSchedulePriority = event.priority;
            }
        }
        return minSchedulePriority;
    }, [currentChannelNo, currentFrameId, events]);

    const generateDefaultScheduleInfo = (channelNo, frameId, scheduleId, contentType, start, end) => {
        return ({
            id: scheduleId,
            title: '',
            scheduleId: scheduleId,

            scheduleType: '00',
            priority: getMaxSchedulePriority(),

            contentId: '',
            contentName: '',
            contentType: contentType,
            start: start,
            end: end,
            repeatType: 'ONCE',
            repeatedDateOfMonthList: [],
            repeatedDayOfWeekList: [],
            playerMode: 'single',
            isHW: false,
            isAllDayPlay: false,
            isInfinitePlay: false,
            isSafetyLockSet: false,
            cifsSlideTransitionTime: 0,
            backgroundColor: getBackgroundColor()
        });
    }

    const generateDefaultScheduleForHW = (channelNo, frameId, scheduleId, contentType, start, end, inputSource) => {
        return ({
            id: scheduleId,
            title: '',
            scheduleId: scheduleId,
            scheduleType: inputSource === 'PANEL_OFF' ? '01' : '03',
            priority: getMaxSchedulePriority(),
            contentId: '',
            contentName: '',
            contentType: contentType,
            start: start.toDate(),
            end: end.toDate(),
            repeatType: 'WEEKLY',
            repeatedDateOfMonthList: [],
            repeatedDayOfWeekList: ['MON','TUE','WED','THU','FRI','SAT','SUN'],
            playerMode: 'single',
            isHW: true,
            inputSource: inputSource,
            isAllDayPlay: false,
            isInfinitePlay: true,
            isSafetyLockSet: false,
            cifsSlideTransitionTime: 0,
            backgroundColor: getBackgroundColor(),
            startRecur: start.format('YYYY-MM-DD'),
            endRecur: '3000-01-01',
            startTime: start.format('HH:mm:ss'),
            endTime: end.format('HH:mm:ss'),
            daysOfWeek: [0,1,2,3,4,5,6],
            ranges: {
                start: start,
                end: Moment('2999-12-31 '+end.format('HH:mm:ss'))
            },
            groupId: scheduleId
        });

    }

    const {misOptionSchedule, scheduleMaxChannelCount} = useMISOpt();

    const getScheduleInfo = useCallback(()=> {
        return misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion).contentSchedule;
    }, [])

    const isLFDContent = (contentType) => {
        return contentType === 'LFD' || contentType === 'DLK';
    }

    const externalEventDrop = (dropInfo) => {
	//[SF00201520] Frame edit authority check
        const isUserAuthorized = hasPermissionForFrameCheck();
        if (!isUserAuthorized) {
            toastr.error(t('ERROR_NO_ROLE'));
            return false;
        }
        const contentName = dropInfo.draggedEl.getAttribute('title');
        const id = dropInfo.draggedEl.getAttribute('id');
        const type = dropInfo.draggedEl.getAttribute('type');
        const mediaType = dropInfo.draggedEl.getAttribute('data-media-type');
        const contentType = dropInfo.draggedEl.getAttribute('data-content-type');
        const expirationDate = dropInfo.draggedEl.getAttribute('data-expiration-date');
        const fileSize = dropInfo.draggedEl.getAttribute('data-file-size');


        if (deviceType === 'SPLAYER' && isLFDContent(contentType) && isSubFrames()) {
            toastr.error(t('MIS_MESSAGE_SCHEDULE_DO_NOT_PLAY_LFD_P'));
            return false;
        }

        if (Moment().startOf('day') > Moment(expirationDate,'YYYYMMDD')) {
            toastr.error(t('MIS_SID_EXPIRED_CONTETN_NOT_ADDED'));
            return false;
        }

        let thumbnailFileId = dropInfo.draggedEl.getAttribute('data-thumbnail');

        if (contentType === 'PLAYLIST') {
            const thumbs = thumbnailFileId.split('|');
            if (thumbs !== undefined && thumbs.length > 1) {
                thumbnailFileId = thumbs[0];
            }
        }

        let event;
        const scheduleId = createUUID();
        const {contents}  = getScheduleInfo();

        if ((contentType === 'HW_IS' || contentType === 'RULESET') && isSubFrames()) {
            toastr.error(t("TEXT_NOT_APPLY_P"));
            return false;
        }

        if (contentType !== undefined && contentType === 'HW_IS' &&
            contents !== undefined && contents.inputSource && contents.isHW) {
            event = generateDefaultScheduleForHW(currentChannelNo, currentFrameId, scheduleId, contentType, Moment(dropInfo.date), Moment(dropInfo.date).add(7199, 'seconds'), id);
        } else {
            event = generateDefaultScheduleInfo(currentChannelNo, currentFrameId, scheduleId, contentType, Moment(dropInfo.date).toDate(), Moment(dropInfo.date).add(7199, 'seconds').toDate());
        }
        event.contentId = id;
        event.title = event.contentName = contentName;
        event.thumbnailFileId = thumbnailFileId;
        event.fileSize = fileSize;

        const updatePrograms =  Object.assign({}, program);
        const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        frame.events = frame.events.concat(event);
        dispatch(scheduleAction.updateContentSchedule(mode, {program: updatePrograms, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));

        if (program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC && type !== undefined && type === scheduleConstants.CONTENT_SCHEDULE_TYPE_PLAYLIST &&
            mediaType !== undefined && mediaType === '3') {
            setAssociateTagPopup({...associateTagPopup, show: true, associateTagPlaylistId: id, tags: program.deviceTags, devices: program.deviceGroups, scheduleId: scheduleId});
        }

    }

    const isSupportFrame = useCallback(()=> {
        const scheduleInfo = getScheduleInfo();
        if (scheduleInfo !== undefined && scheduleInfo.frame !== undefined) {
            return scheduleInfo.frame;
        }
        return false;
    }, [])

    const isSupportChannel = useCallback(()=> {
        const scheduleInfo = getScheduleInfo();
        if (scheduleInfo !== undefined && scheduleInfo.channel !== undefined) {
            return scheduleInfo.channel;
        }
        return false;
    }, [])

    const dateDiff = (_date1, _date2) => {
        const diffDate_1 = _date1 instanceof Date ? _date1 : new Date(_date1);
        const diffDate_2 = _date2 instanceof Date ? _date2 : new Date(_date2);

        let diff = Math.abs(diffDate_2.getTime() - diffDate_1.getTime());
        diff = Math.ceil(diff / (1000 * 3600 * 24));
        return diff;
    }

    const handleEventDrop = (info) => {
        const {scheduleId} = info.event.extendedProps
        const updatePrograms = program;
        const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        const event = frame.events.find(e => e.scheduleId === scheduleId);

        if (event !== undefined) {

            if (event.repeatType === 'ONCE') {
                event.start = info.event.start;
                event.end = info.event.end;
            } else {
                const startTime = event.startTime = Moment(info.event.start).format('HH:mm:ss');
                const endTime = event.endTime = Moment(info.event.end).format('HH:mm:ss');
                const days = dateDiff(info.event.extendedProps.ranges.start.toDate(), info.event.extendedProps.ranges.end.toDate());
                if (!event.isInfinitePlay) {
                    const start = new Date(Moment(info.event.start).format('YYYY-MM-DD') +' '+ startTime);
                    let end;
                    if (days > 0) {
                        end = new Date(Moment(start).add(days-1, 'days').format('YYYY-MM-DD')+' '+endTime);
                    } else {
                        end = new Date(Moment(start).format('YYYY-MM-DD')+' '+endTime);
                    }
                    //const end = new Date(Moment(start).add(days-1, 'days').format('YYYY-MM-DD')+' '+endTime);

                    event.ranges = {start: Moment(start), end: Moment(end)};
                    event.startRecur = Moment(start).format('YYYY-MM-DD');
                    event.endRecur = Moment(end).add(1, 'days').format('YYYY-MM-DD');

                    event.startTime = startTime;
                    event.endTime = endTime;
                } else {
                    event.ranges = {...event.ranges, start: Moment(info.event.start)};
                    event.startRecur = Moment(info.event.start).format('YYYY-MM-DD')
                }
            }
            const updatePrograms = program;
            const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
            const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
            frame.events = frame.events.map(e=> e.scheduleId === scheduleId ? event : e);
            dispatch(scheduleAction.updateContentSchedule(mode, {program: updatePrograms, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));

        } else {
            toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
            return false;
        }
    }

    const handleEventResize = (info) => {
        const {scheduleId} = info.event.extendedProps
        const updatePrograms = program;
        const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        const event = frame.events.find(e => e.scheduleId === scheduleId);

        if (event !== undefined) {
            if (event.repeatType === 'ONCE') {
                event.end = info.event.end;
            } else {
                event.endTime = Moment(info.event.end).format('HH:mm:ss');
            }
            const updatePrograms = program;
            const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
            const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
            frame.events = frame.events.map(e=> e.scheduleId === scheduleId ? event : e);
            dispatch(scheduleAction.updateContentSchedule(mode, {program: updatePrograms, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));

        }
    }

    const showContextMenu = (e, id) => {
        if (e) {
            e.preventDefault();
            if (!contextMenu.show) {
                const x = e.pageX;
                const y = e.pageY;
                setContextMenu({...contextMenu, show: true, position: {x: x, y: y}, id: id});
            } else {
                setContextMenu({...contextMenu, show: false});
            }
        } else {
            setContextMenu({...contextMenu, show: false});
        }
    }

    const updateSchedulePriority = (type) => {
        const {id} = contextMenu;
        const updatePrograms = program;
        const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        if (type === 'top') {
            const maxSchedulePriority = getMaxSchedulePriority();
            frame.events = frame.events.map(e => e.scheduleId === id ? {...e, priority: maxSchedulePriority} : e);
        } else if (type === 'bottom') {
            const minSchedulePriority = getMinSchedulePriority();
            frame.events = frame.events.map(e => e.scheduleId === id ? {...e, priority: minSchedulePriority} : {...e, priority: e.priority+1});
        }
        dispatch(scheduleAction.updateContentSchedule(mode, {program: updatePrograms, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));
        showContextMenu();
    }

    const eventRender = (info) => {
        const {repeatType, repeatedDateOfMonthList, expirationDate} = info.event.extendedProps;
        if (repeatType === 'MONTHLY') {
            const {start, end}  = info.event;
            const date = start.getDate();
            if (repeatedDateOfMonthList.find(day => day === date) == undefined) {
                return false;
            }
        }

        if (ReactDOM.findDOMNode(info.el) !== undefined) {
            const {thumbnailFileId} = info.event.extendedProps;
            if (validThumbnailId(thumbnailFileId)) {
                if (ReactDOM.findDOMNode(info.el).querySelector(".thumbnail")) {
                    const childTree = ReactDOM.findDOMNode(info.el).querySelector(".thumbnail");
                    ReactDOM.render(<ContentThumbnail id={thumbnailFileId} width={70}
                                                      height={39}/>, childTree);
                }
                ReactDOM.findDOMNode(info.el).addEventListener("contextmenu", (e)=>showContextMenu(e, info.event.id));

            }
        }

        //expirationDate
        if (expirationDate !== undefined && expirationDate !== '') {
            if (Moment().startOf('day') > Moment(expirationDate,'YYYYMMDD')) {
                ReactDOM.findDOMNode(info.el).classList.add('expired')
            }
        }
    }


    const showScheduleInfoPopup = (id, scheduleInfo, type) => {
        if (scheduleInfoPopup.show) {
            setScheduleInfoPopup({...scheduleInfoPopup, show: false, scheduleId: undefined, scheduleInfo: undefined, type: undefined})
        } else {
            setScheduleInfoPopup({...scheduleInfoPopup, show: true, scheduleId: id, scheduleInfo: scheduleInfo, type: type})
        }
    }

    

    const viewSkeletonRender = ({el}) => {
        const title = el.parentNode.parentNode.querySelector('.fc-header-toolbar .fc-left h2');
        title.addEventListener('click', showMoveCalendar)
    }

    const handleSelect = (info) => {
        const scheduleId = createUUID();

        const {view: {type}} = calendar[mode];
        const scheduleInfo  = generateDefaultScheduleInfo(currentChannelNo, currentFrameId, scheduleId, info.start, info.end);
        let {start : infoStart, end : infoEnd} = info;

        const infoEndMomentObj = Moment(infoEnd);

        if(infoEndMomentObj.format('HH:mm:ss') === '00:00:00') {
            infoEnd = infoEndMomentObj.set({'hour': 23, 'minute': 59, 'second': 59}).subtract(1, 'days').toDate();
        }
        else {
            infoEnd = infoEndMomentObj.subtract(1, 'seconds').toDate();
        }

        if (infoStart.getDate() !== infoEnd.getDate()) {
            const start = Moment(infoStart);
            const end = Moment(infoEnd);

            scheduleInfo.repeatType = 'DAILY';
            scheduleInfo.ranges = {start: start.clone(), end: end.clone()}

            scheduleInfo.startTime = start.format('HH:mm:ss');
            if (type === 'dayGridMonth') {
                scheduleInfo.endTime = '23:59:59';
            } else {
                scheduleInfo.endTime = end.format('HH:mm:ss');
            }
            scheduleInfo.startRecur = start.format('YYYY-MM-DD');
            scheduleInfo.endRecur = end.add(1, 'days').format('YYYY-MM-DD');
            scheduleInfo.daysOfWeek = [0,1,2,3,4,5,6];
            scheduleInfo.groupId = scheduleId;

            scheduleInfo.start = undefined;
            scheduleInfo.end = undefined;

        } else {

            if (type === 'dayGridMonth') {
                scheduleInfo.start = infoStart;
                scheduleInfo.end = Moment(infoEnd).set({'hour': 23, 'minute': 59, 'second': 59}).toDate();
            } else {
                scheduleInfo.start = infoStart;
                scheduleInfo.end = infoEnd;
            }
        }

        showScheduleInfoPopup(scheduleId, scheduleInfo, 'ADD');
    }

    const handleEventClick = (info, updateEvents) => {
        const channel = program.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        const event = frame.events.find(e => e.scheduleId === info.event.id)
        const newEvent = Object.assign({}, event);
        
	    //[SF00201520] Frame edit authority check
        const isUserAuthorized = hasPermissionForFrameCheck();
        if (!isUserAuthorized){
            toastr.error(t('ERROR_NO_ROLE'));    
        }
        else if (newEvent !== undefined) {
            showScheduleInfoPopup(newEvent.id, newEvent, 'UPDATE');
        }
    }

    const updateSchedule = (scheduleInfo, type) => {
        const updatePrograms = program;
        
        const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);

        if (deviceType === 'SPLAYER' && isLFDContent(scheduleInfo.contentType) && isSubFrames()) {
            toastr.error(t("MIS_MESSAGE_SCHEDULE_DO_NOT_PLAY_LFD_P"));
        } else {
            if (type === 'UPDATE') {
                frame.events = frame.events.map(e => e.scheduleId === scheduleInfo.scheduleId ? scheduleInfo : e);
            } else if (type === 'ADD') {
                frame.events = frame.events.concat(scheduleInfo);
            }
            dispatch(scheduleAction.updateContentSchedule(mode, {program: updatePrograms, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));
            showScheduleInfoPopup();

            if (program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC && scheduleInfo.contentType === 'PLAYLIST' && scheduleInfo.playlistType === '3') {
                setAssociateTagPopup({...associateTagPopup, show: true, associateTagPlaylistId: scheduleInfo.contentId, tags: program.deviceTags, devices: program.deviceGroups, scheduleId: scheduleInfo.scheduleId});
            }

            if (type === 'ADD') {
                calendar[mode].addEvent(scheduleInfo);
            }
        }
    }

    const removeSchedule = (scheduleId, close = true) => {
        const channel = program.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        const event = frame.events.find(e => e.scheduleId === scheduleId)

        if (event !== undefined) {
            const updatePrograms = program;
            const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
            const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
            frame.events = frame.events.filter(e=> e.scheduleId !== scheduleId);
            dispatch(scheduleAction.updateContentSchedule(mode, {program: updatePrograms, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));

            if (close) {
                showScheduleInfoPopup();
            }
        }
    }

    const saveProgram = () => {
        showSaveContentSchedulePopup();
    }

    const saveAsProgram = () => {
        setSaveContentSchedulePopup({
            ...saveContentSchedulePopup,
            show: true,
            mode: scheduleConstants.CONTENT_SCHEDULE_SAVE_AS_MODE
        })
    }

    const closeTab = () => {
        if (mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) {
            dispatch(menuAction.removeTab('NEW_SCHEDULE'))
        } else {
            dispatch(menuAction.removeTab('EDIT_SCHEDULE'))
        }
        dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
    }

    const cancelProgram = () => {
        closeTab();
    }

    const addChannel = () => {
        if (program !== undefined) {
            if (program.channels.length > scheduleMaxChannelCount - 1) {
                toastr.error(t('COM_MIV_CAN_NOT_ADD_ANY_MORE'));
            } else {
                //program.channels.sort((a, b) => (a.channelNo > b.channelNo) ? 1 : -1);
                let channelNo = getUsableChannelId();
                const channelName = t('COM_TV_SID_NEW_CHANNEL');
                const channel = {channelName: channelName, channelNo: channelNo, frame: {frameIndex: 0, frameId: createUUID(), isMainFrame: true, x: 0, y: 0, width: 100, height: 100, lineData: 'ZeroFrameOnly', frames: [], events: []}};
                const updateProgram = Object.assign({}, program);
                updateProgram.channels = updateProgram.channels.concat(channel);
                dispatch(scheduleAction.updateContentSchedule(mode, {program: updateProgram, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));
            }
        }
    }

    const getUsableChannelId = () => {
        let usedids = [];
        let fullset = [];
        for(let i=1; i<100; i++){
            fullset.push(i);
        }
        program.channels.map(channel => {
            usedids.push(channel.channelNo);
        })

        fullset = fullset.filter(num => !usedids.includes(num));
        return fullset[0];
    }

    const getCurrentFrameId = (channelNo) => {
        for (const channel of program.channels) {
            if (channel.channelNo === channelNo) {
                return channel.frame.frameId
            }
        }
    }

    const getCurrentChannelId = (channelNo) => {
        for (const channel of program.channels) {
            if (channel.channelNo !== channelNo) {
                return channel.channelNo;
            }
        }
    }

    const updateChannelName = (e, channel) => {
        const value = e.target.value;
        const updateProgram = Object.assign({}, program);
        const updateChannel = updateProgram.channels.find(c=>c.channelNo === channel.channelNo);
        if (updateChannel !== undefined) {
            updateChannel.channelName = value;
            updateProgram.channels = updateProgram.channels.map(c => c.channelNo === channel.channelNo ? {...c, ...channel} : c);
            dispatch(scheduleAction.updateContentSchedule(mode, {program: updateProgram, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));
        }
    }
    const updateChannel = (oldChannel, newChannelNo) => {
        let valid = true;
        const updateProgram = Object.assign({}, program);
        updateProgram.channels.map(
            channel=> {
                if (channel.channelNo === newChannelNo) {
                    valid = false;
                    toastr.error(t('MIS_MESSAGE_CHANNEL_OCCUPIED_CHANNEL_NO_P'))
                }
            }
        )
        if (valid) {
            let updateCurrentChannelNo = currentChannelNo;
            if (currentChannelNo === oldChannel) {
                updateCurrentChannelNo = newChannelNo;
            }
            updateProgram.channels = updateProgram.channels.map(channel => channel.channelNo === oldChannel ? {...channel, channelNo: newChannelNo} : channel);
            dispatch(scheduleAction.updateContentSchedule(mode, {program: updateProgram, currentFrameId: currentFrameId, currentChannelNo: updateCurrentChannelNo}));
        }
    }

    //SF00201520: To disallow unauthorized user from removing a protected channel
    const removeChannel = (channelNo) => {
        const channel_temp = program.channels.find(channel => channel.channelNo === channelNo);
        if (program.channels.length < 2) {
            toastr.error(t("MIS_MESSAGE_CHANNEL_KEEP_LAST_CHANNEL_P"))
        } 
        else if (channel_temp.hasPermissionForFrameProperties !== undefined 
                && channel_temp.hasPermissionForFrameProperties === false) {
            toastr.error(t('ERROR_NO_ROLE'));
        }
        else {
            let updateCurrentChannelNo = currentChannelNo, updateCurrentFrameId = currentFrameId;
            if (channelNo === currentChannelNo) {
                updateCurrentChannelNo = getCurrentChannelId(currentChannelNo);
                updateCurrentFrameId = getCurrentFrameId(updateCurrentChannelNo);
            }
            const updateProgram = Object.assign({}, program);
            updateProgram.channels = updateProgram.channels.filter(channel => channel.channelNo !== channelNo)
            updateProgram.currentChannelNo = updateCurrentChannelNo;
            updateProgram.currentFrameId = updateCurrentFrameId;

            dispatch(scheduleAction.updateContentSchedule(mode, {program: updateProgram, currentFrameId: updateCurrentFrameId, currentChannelNo: updateCurrentChannelNo}));
        }
    }

    const selectChannel = (channelNo) => {
        const frameId = getCurrentFrameId(channelNo);
        const updateProgram = program;
        updateProgram.currentChannelNo = channelNo;
        updateProgram.currentFrameId = frameId;
        dispatch(scheduleAction.updateContentSchedule(mode, {program: updateProgram, currentFrameId: frameId, currentChannelNo: channelNo}));
    }

    const selectFrame = (frameId) => {
        const updateProgram = program;
        updateProgram.currentFrameId = frameId;
        dispatch(scheduleAction.updateContentSchedule(mode, {program: updateProgram, currentFrameId: frameId, currentChannelNo: currentChannelNo}));
    }

    const showSaveContentSchedulePopup = () => {
        setSaveContentSchedulePopup({...saveContentSchedulePopup, show: !saveContentSchedulePopup.show, mode: undefined});
    }

    const showAssociateTagPopup = () => {
        const {scheduleId} = associateTagPopup;
        removeSchedule(scheduleId, false);

        setAssociateTagPopup({...associateTagPopup, show: !associateTagPopup.show});
    }

    const saveTagMapping = (tagMapping) => {
        const {scheduleId} = associateTagPopup;
        const updatePrograms = program;
        const channel = updatePrograms.channels.find(channel => channel.channelNo === currentChannelNo);
        const frame = channel.frame.frameId === currentFrameId ? channel.frame : channel.frame.frames.find(f => f.frameId === currentFrameId);
        frame.events = frame.events.map(
            event => event.scheduleId === scheduleId ? {...event, syncGroupDeviceTagMaps : tagMapping} : event
        );
        dispatch(scheduleAction.updateContentSchedule(mode, {program: updatePrograms, currentFrameId: currentFrameId, currentChannelNo: currentChannelNo}));
        setAssociateTagPopup({...associateTagPopup, show: false})
    }   
   

    const getTotalFileSize = () => {      
        let totalSize = 0;        
        frame.events.forEach((event) => {
            if(event.fileSize !== undefined && event.fileSize !== null){
                totalSize += parseInt(event.fileSize)
            }
        });
        setTotalFileSize(totalSize);               
    }
    const [showDatePicker, setShowDatePicker] = useState({
        show: false,
        init: false,
        date: new Date()
    });
    useCalendarOutsideClick(()=> {
        if (showDatePicker.show) {
            setShowDatePicker({...showDatePicker, show: false});
        }
    })
    const showMoveCalendar = (e) => {        
        setShowDatePicker({...showDatePicker, show: !showDatePicker.show,date:calendar[mode].getDate()});
    }
    const handleScheduleDate = (date) => {                                
        calendar[mode].gotoDate(date);                           
        setShowDatePicker({...showDatePicker, show: false});    
    }

    useEffect(()=> {
        const calendarEl = document.getElementById('content_schedule_calendar_'+mode);
        if (calendar !== undefined && calendar[mode] !== undefined) {
            calendar[mode].destroy();
        }
	    //[SF00201520] Frame edit authority check
        const isUserAuthorized = hasPermissionForFrameCheck();
        calendar[mode] = new Calendar(calendarEl, {
            timeZone: 'local',
            defaultView: "timeGridWeek",
            height: getHeight(),
            firstDay:getFirstDayOfWeek(),
            header:{
                left: 'prev, title, next, today',
                center: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                right: ''
            },
            views: {
                dayGridMonth: {
                    titleFormat: { year: 'numeric', month: 'short', day: 'numeric' }
                },
                timeGridWeek: {
                    titleFormat: { year: 'numeric', month: 'short', day: 'numeric' }
                },
                timeGridDay: {
                    titleFormat: { year: 'numeric', month: 'short', day: 'numeric' }
                },
                listWeek: {
                    titleFormat: { year: 'numeric', month: 'short', day: 'numeric' }
                }
            },
            slotLabelFormat : getTimeFormat(),
            buttonText : {
                today: t("TEXT_TODAY_P"),
                month: t("TEXT_MONTHLY_P"),
                week: t("TEXT_WEEKLY_P"),
                day: t("TEXT_DAILY_P"),
                list: t("BUTTON_LIST")
            },
            dayNamesShort: [
                t('COM_TEXT_DAY_SUNDAY_P'),
                t('COM_TEXT_DAY_MONDAY_P'),
                t('COM_TEXT_DAY_TUESDAY_P'),
                t('COM_TEXT_DAY_WEDNESDAY_P'),
                t('COM_TEXT_DAY_THURSDAY_P'),
                t('COM_TEXT_DAY_FRIDAY_P'),
                t('COM_TEXT_DAY_SATURDAY_P')
            ],
            columnHeaderText : (date) => {
                switch (date.getDay()) {
                    case 0:
                        return date.getDate()+' '+t('COM_TEXT_DAY_SUNDAY_P');
                    case 1:
                        return date.getDate()+' '+t('COM_TEXT_DAY_MONDAY_P');
                    case 2:
                        return date.getDate()+' '+t('COM_TEXT_DAY_TUESDAY_P');
                    case 3:
                        return date.getDate()+' '+t('COM_TEXT_DAY_WEDNESDAY_P');
                    case 4:
                        return date.getDate()+' '+t('COM_TEXT_DAY_THURSDAY_P');
                    case 5:
                        return date.getDate()+' '+t('COM_TEXT_DAY_FRIDAY_P');
                    case 6:
                        return date.getDate()+' '+t('COM_TEXT_DAY_SATURDAY_P');
                }
            },
            eventConstraint:{start: '00:00', end: '24:00'},
            eventTimeFormat: getTimeFormat(),
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
            displayEventTime: true,
	    //[SF00201520] Frame edit authority check
            editable: isUserAuthorized,
            droppable: isUserAuthorized,
            selectable: isUserAuthorized,
            allDaySlot: false,

            eventOrder: 'priority',

            viewSkeletonRender : viewSkeletonRender,

            select: handleSelect,
            eventClick: handleEventClick,
            eventDrop: handleEventDrop,
            eventResize:handleEventResize,
            eventRender: eventRender,

            drop: externalEventDrop
        });
        calendar[mode].render();
    }, [currentContentId, currentChannelNo, currentFrameId, mode, currentContentId])

    useEffect(()=> {
        calendar[mode].removeAllEvents();
        calendar[mode].addEventSource(events);
        calendar[mode].refetchEvents();
        getTotalFileSize();

    }, [events, mode, currentContentId])

    useEffect(()=> {
        calendar[mode].setOption('drop', externalEventDrop);
    }, [currentFrameId, program.channels])

    return (
        <div className={"content_schedule_manager_warp"}
             style ={{display: (currentContentId === 'NEW_SCHEDULE' && mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) || (currentContentId === 'EDIT_SCHEDULE' && mode === scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE) ? 'block':'none'}}>
            <input type={'hidden'} value={currentChannelNo} ref={currentChannelNoRef} />
            <input type={'hidden'} value={currentFrameId} ref={currentFrameIdRef}/>
            {
                scheduleInfoPopup !== undefined && scheduleInfoPopup.show &&
                <SelectContentsPlaylistsPopup
                    mode={scheduleInfoPopup.type}
                    deviceType={deviceType}
                    deviceTypeVersion={deviceTypeVersion}
                    scheduleInfo={scheduleInfoPopup.scheduleInfo}
                    programType={program.programType}
                    closePopup={showScheduleInfoPopup}
                    updateSchedule={updateSchedule}
                    removeSchedule={removeSchedule}
                    scheduleId={scheduleInfoPopup.scheduleId}
                    isSubFrames={isSubFrames()}
                />
            }
            {
                saveContentSchedulePopup.show &&
                <SaveContentSchedulePopup
                    mode={saveContentSchedulePopup.mode !== undefined ? saveContentSchedulePopup.mode : mode}
                    stateProgram={program}
                    closePopup={showSaveContentSchedulePopup}/>
            }
            {
                associateTagPopup.show &&
                <AssociateTagPopup
                    playlistId={associateTagPopup.associateTagPlaylistId}
                    devices={associateTagPopup.devices}
                    tags={associateTagPopup.tags}
                    close={showAssociateTagPopup}
                    save={saveTagMapping}
                />
            }
            {
                contextMenu.show &&
                    <ContextMenu position={contextMenu.position} close={()=>showContextMenu()}
                                 menu={[{id:'bring_to_top', name: "TEXT_BRING_ON_TOP_P", onClick: ()=> updateSchedulePriority('top')},
                                        {id:'send_to_bottom', name: "MIS_SID_20_SEND_TO_BOTTOM", onClick: ()=> updateSchedulePriority('bottom')}]}
                                zIndex={100}/>
            }
            {
                eventThumbnail.show &&
                    <TooltipThumbnail thumbnailId={eventThumbnail.id} width={120} height={100} position={eventThumbnail.position} zIndex={100}/>

            }
            {
                showDatePicker.show &&
                    <DatePicker
                        onChange={date => handleScheduleDate(date)}
                        calendarClassName={'moveCalendar'}
                        inline
                        ref={moveCalendarRef}
                        selected={showDatePicker.date}                         
                    />
            }
            <div style={{margin: 20, height: 30}}>
                <div style={{float: 'left'}} className={"schedule_manager_buttonsWrap"}>
                    <WhiteButton name={t("COM_BUTTON_SAVE")} onClick={()=>saveProgram()}/>
                    {
                        mode === scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE &&
                            <WhiteButton name={t("BUTTON_SAVE_AS_P")} onClick={()=>saveAsProgram()}/>
                    }

                    <WhiteButton name={t("BUTTON_CANCEL_P")} onClick={()=>cancelProgram()}/>
                </div>
                <div style={{float: 'right'}} className={"schedule_manager_iconWrap"}>
                    <div>
                        <span title={program.programName} style={{width: '300px', cursor: 'unset'}} className={"name"}>{program.programName}</span>
                        <span>{bytesToSize(totalFileSize)}</span>
                        <SupportedDeviceIcon deviceType={program !== undefined && program.deviceType.toUpperCase()} deviceTypeVersion={program !== undefined && program.deviceTypeVersion} />
                    </div>
                    <div style={{marginRight:'80px'}}>
                    {
                        isSupportChannel() &&
                            <Channels currentChannel={currentChannelNo} channels={program.channels} addChannel={addChannel} removeChannel={removeChannel} selectChannel={selectChannel} updateChannel={updateChannel} updateChannelName={updateChannelName}/>
                    }
                    {
                        isSupportFrame() &&
                            <Frames currentFrame={currentFrameId} currentChannel={currentChannelNo} selectFrame={selectFrame} mode={mode} resolution={program.resolution} deviceType={program.deviceType} deviceTypeVersion={program.deviceTypeVersion}/>
                    }
                    </div>
                </div>
            </div>
            <div ref={calendarRef} id={'content_schedule_calendar_'+mode}></div>
        </div>
    )

}
export default ScheduleManager;;
