import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {popupAction} from '../actions';
import {commonConstants} from '../constants';
import DeviceNameCell from '../components/device/DeviceNameCell';

export const useFilter = (initialFilter) => {
    const [filter, updateFilter] = useState({
        ...initialFilter,
        isFetched: false
    });

    const setFilter = f => updateFilter({...f, isFetched : true})

    const onPageChange = page => {
        setFilter({...filter, page});
    };

    const onPageSizeChange = (pageSize, page) => {
        setFilter({...filter, page, pageSize});
    };

   const onSortedChange = (newSorted) =>{
       const [{id, desc}] = newSorted;
       setFilter({...filter, sorted: [{id, desc}]});
   }

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0});
    };


    return [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange];
};

const setButtonDisable = (ref, disable) => {
    if(disable) {
        ref.current.disabled = true;
        ref.current.className = 'whiteButton disabled';
    } else {
        ref.current.disabled = false;
        ref.current.className = 'whiteButton';
    }
};

const setButtonsDisable = (refs, disable) => {
    refs.forEach(ref => setButtonDisable(ref, disable));
};

const setAllTableRowClassNameSelected = (element, selected) => {
    try {
        element.closest('.rt-table').querySelectorAll('.rt-tr-group').forEach(trGroup => {
            if(selected) {
                trGroup.classList.add('selected');
            }
            else {
                trGroup.classList.remove('selected');
            }
            
        });
    } catch {
    }
};

const setTableRowClassNameSelected = (element, selected) => {
    try {
        if(selected) {
            element.closest('.rt-tr-group').classList.add('selected');
        }
        else {
            element.closest('.rt-tr-group').classList.remove('selected');
        }
    } catch {
    }
};

export const useCheckRef = (items, ...buttons) => {
    const checkAllRef = useRef();
    const checkBoxRefs = useRef([]);
    const selected = useRef([]);

    if(selected.current.length === 0 && checkAllRef.current) {
        setAllTableRowClassNameSelected(checkAllRef.current, false);
    }

    const toggleSelectAll = useCallback(e => {
        if(!checkAllRef.current.checked) {
            checkBoxRefs.current.forEach(checkBox => {
                checkBox.checked = false;
            });
            selected.current = [];
            setAllTableRowClassNameSelected(e.target, false);
        } else {
            selected.current = [];
            checkBoxRefs.current.forEach((checkBox, i) => {
                selected.current.push(i);
                checkBox.checked = true;
            });
            setAllTableRowClassNameSelected(e.target, true);
        }
    }, [items]);

    const toggleRow = useCallback((rowIndex) => {
        if(checkBoxRefs.current[rowIndex].checked) {
            selected.current.push(rowIndex);
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = true;
            }
            setTableRowClassNameSelected(checkBoxRefs.current[rowIndex], true);
            setButtonsDisable(buttons, false);
        } else {
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = false;
            }
            setTableRowClassNameSelected(checkBoxRefs.current[rowIndex], false);
            selected.current.splice(selected.current.findIndex(idx => idx === rowIndex), 1);
            if(selected.current.length === 0) {
                setButtonsDisable(buttons, true);
            }
        }
    }, [items]);

    const toggleRowIndex = useCallback(e => {
        const index = e.target.getAttribute('data-index');
        toggleRow(parseInt(index));
    }, [items]);

    const setCheckBoxRefs = useCallback(ref => {
        if(ref) {
            const index = ref.getAttribute('data-index');
            checkBoxRefs.current[parseInt(index)] = ref;
        }
    }, [items]);

    useEffect(() => {
        selected.current = [];
    }, [items]);

    return [checkAllRef, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, toggleRowIndex, selected];
};

export const useCheckRefWithSelectedCnt = (items) => {
    const checkAllRef = useRef();
    const checkBoxRefs = useRef([]);
    const selected = useRef([]);
    const [selectedCnt, setSelectedCnt] = useState(0);
    
    if(selected.current.length === 0 && checkAllRef.current) {
        setAllTableRowClassNameSelected(checkAllRef.current, false);
    }

    const toggleSelectAll = useCallback(e => {
        if(!checkAllRef.current.checked) {
            checkBoxRefs.current.forEach(checkBox => {
                checkBox.checked = false;
            });
            selected.current = [];
            setAllTableRowClassNameSelected(e.target, false);
            setSelectedCnt(0);
        } else {
            selected.current = [];
            checkBoxRefs.current.forEach((checkBox, i) => {
                selected.current.push(i);
                checkBox.checked = true;
            });
            setAllTableRowClassNameSelected(e.target, true);
            setSelectedCnt(selected.current.length);
        }
    }, [items]);

    const toggleRow = useCallback(e => {
        const index = e.target.getAttribute('data-index');
        const rowIndex = parseInt(index);

        if(checkBoxRefs.current[rowIndex].checked) {
            selected.current.push(rowIndex);
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = true;
            }
            setTableRowClassNameSelected(e.target, true);
            setSelectedCnt(selected.current.length);
        } else {
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = false;
            }
            setTableRowClassNameSelected(e.target, false);
            selected.current.splice(selected.current.findIndex(idx => idx === rowIndex), 1);
            setSelectedCnt(selected.current.length);
        }
    }, [items]);

    const setCheckBoxRefs = useCallback(ref => {
        if(ref) {
            const index = ref.getAttribute('data-index');
            checkBoxRefs.current[parseInt(index)] = ref;
        }
        if(ref==null){ //When left some page call setCheckBoxRefs again. in this case, init cheBoxRefs.
            checkBoxRefs.current = [];
        }

    }, []);

    useEffect(() => {
        selected.current = [];
        /*checkBoxRefs.current = [];*/
        setSelectedCnt(0);
    }, [items]);

    return [checkAllRef, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt];
};

export const useCheckRefForDeviceLog = (items) => {
    const checkAllRef = useRef();
    const checkBoxRefs = useRef([]);
    const selected = useRef([]);
    const [selectedCnt, setSelectedCnt] = useState(0);

    if(selected.current.length === 0 && checkAllRef.current) {
        setAllTableRowClassNameSelected(checkAllRef.current, false);
    }

    const toggleSelectAll = useCallback(e => {
        if(!checkAllRef.current.checked) {
            checkBoxRefs.current.forEach(checkBox => {
                checkBox.checked = false;
            });
            selected.current = [];
            setAllTableRowClassNameSelected(e.target, false);
            setSelectedCnt(0);
        } else {
            selected.current = [];
            checkBoxRefs.current.forEach((checkBox, i) => {
                selected.current.push(i);
                checkBox.checked = true;
            });
            setAllTableRowClassNameSelected(e.target, true);
            setSelectedCnt(selected.current.length);
        }
    }, [items]);

    const toggleRow = useCallback(e => {
        const index = e.target.getAttribute('data-index');
        const rowIndex = parseInt(index);

        if(checkBoxRefs.current[rowIndex].checked) {
            selected.current.push(rowIndex);
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = true;
            }
            setTableRowClassNameSelected(e.target, true);
            setSelectedCnt(selected.current.length);
        } else {
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = false;
            }
            setTableRowClassNameSelected(e.target, false);
            selected.current.splice(selected.current.findIndex(idx => idx === rowIndex), 1);
            setSelectedCnt(selected.current.length);
        }
    }, [items]);

    const setCheckBoxRefs = useCallback(ref => {
        if(ref) {
            const index = ref.getAttribute('data-index');
            checkBoxRefs.current[parseInt(index)] = ref;
        }
    }, [items]);

    useEffect(() => {
        selected.current = [];
        setSelectedCnt(0);
    }, [items]);

    return [checkAllRef, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt];
};

export const useCheckRefForContent = (items, contentCard) => {
    const checkAllRef = useRef();
    const checkBoxRefs = useRef([]);
    const selected = useRef([]);
    const [selectedCnt, setSelectedCnt] = useState(0);

    if(selected.current.length === 0 && checkAllRef.current) {
        setAllTableRowClassNameSelected(checkAllRef.current, false);
    }

    const toggleSelectAll = useCallback(e => {
        if(items.length > 0){
            if(!checkAllRef.current.checked) {
                checkBoxRefs.current.forEach(checkBox => {
                    checkBox.checked = false;
                });
                selected.current = [];
                setAllTableRowClassNameSelected(e.target, false);
                setSelectedCnt(0);
            } else {
                selected.current = [];
                items.forEach((item, i) => {
                    selected.current.push(i);
                    checkBoxRefs.current[i].checked = true;
                });
                setAllTableRowClassNameSelected(e.target, true);
                setSelectedCnt(selected.current.length);
            }
        }
    }, [items]);

    const toggleSelectAllForCardView = useCallback(e => {
        if(contentCard.length > 0){
            if(checkAllRef.current.checked) {
                selected.current = [];
                contentCard.map((item, index) => {
                    item.setSelect(true);
                    selected.current.push(index);
                });
                setSelectedCnt(selected.current.length);
            }else {
                contentCard.map((item) => item.setSelect(false));
                selected.current = [];
                setSelectedCnt(0);
            }
        }
    }, [contentCard]);

    const toggleRow = useCallback(e => {
        const index = e.target.getAttribute('data-index');
        const rowIndex = parseInt(index);

        if(checkBoxRefs.current[rowIndex].checked) {
            selected.current.push(rowIndex);
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = true;
            }
            setTableRowClassNameSelected(e.target, true);
            setSelectedCnt(selected.current.length);
        } else {
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = false;
            }
            setTableRowClassNameSelected(e.target, false);
            selected.current.splice(selected.current.findIndex(idx => idx === rowIndex), 1);
            setSelectedCnt(selected.current.length);
        }
    }, [items]);

    const toggleRowWithIndex = useCallback(rowIndex => {
        if(!checkBoxRefs.current[rowIndex].checked) {
            selected.current.push(rowIndex);
            checkBoxRefs.current[rowIndex].checked = true;
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = true;
            }
            setTableRowClassNameSelected(checkBoxRefs.current[rowIndex], false);
            setSelectedCnt(selected.current.length);
        } else {
            checkBoxRefs.current[rowIndex].checked = false;
            if(selected.current.length === items.length) {
                checkAllRef.current.checked = false;
            }
            setTableRowClassNameSelected(checkBoxRefs.current[rowIndex], false);
            selected.current.splice(selected.current.findIndex(idx => idx === rowIndex), 1);
            setSelectedCnt(selected.current.length);
        }
    }, [items]);

    const toggleCard = useCallback(index => {
        if(contentCard[index].getSelect()) {
            if(selected.current.length === contentCard.length) {
                checkAllRef.current.checked = false;
            }
            contentCard[index].setSelect(false);
            selected.current.splice(selected.current.findIndex(idx => idx === index), 1);
        } else {
            contentCard[index].setSelect(true);
            selected.current.push(index);
            if(selected.current.length === contentCard.length) {
                checkAllRef.current.checked = true;
            }
        }

        setSelectedCnt(selected.current.length);
    }, [contentCard]);

    const setCheckBoxRefs = useCallback(ref => {
        if(ref) {
            const index = ref.getAttribute('data-index');
            checkBoxRefs.current[parseInt(index)] = ref;
        }
        if(ref==null){ //When left some page call setCheckBoxRefs again. in this case, init cheBoxRefs.
            checkBoxRefs.current = [];
        }
    }, []);

    const initData = () => {
        selected.current = [];
        setSelectedCnt(0);
    };

    useEffect(() => {
        selected.current = [];
        setSelectedCnt(0);
        if(checkAllRef !== undefined && checkAllRef.current !== undefined && checkAllRef.current !== null){
            checkAllRef.current.checked = false;
        }
    }, [items,contentCard]);

    return [checkAllRef, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt, initData, toggleSelectAllForCardView, toggleCard, toggleRowWithIndex];
}


export const useDeviceDetail = (showIcon = true, hasDeviceSecurity = false, showPowerIcon = true) => {
    const dispatch = useDispatch();

    const onClickDevice = (deviceId) => {
        dispatch(popupAction.openDetailView({
            type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'DEVICE', id: deviceId, hasDeviceSecurity: hasDeviceSecurity
        }));
    };

    const renderDeviceNameCell = ({original}) => <DeviceNameCell device={original} showIcon={showIcon} onClick={onClickDevice} showPowerIcon={showPowerIcon} />;

    const renderMACCell = data => {
        return (
            <span className='mac_address_cell' onClick={() => onClickDevice(data.original.deviceId)}>{data.value}</span>
        );
    };

    return [renderDeviceNameCell, renderMACCell, onClickDevice];
};

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(()=>{
        ref.current = value;
    })
    return ref.current;
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const useCalendarOutsideClick = (callback) => {
    useOutsideClickByCssSelector(callback,'.content_schedule_manager_warp .react-datepicker.moveCalendar');    
}
export const useOutsideClickByCssSelector = (callback,cssSelector) => {
    const handleClick = e => {
        const elm = document.querySelector(cssSelector);
        if (elm !== null && !elm.contains(e.target)) {
            callback();
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
}

export const useOutsideClick = (ref, callback, useCapture = false) => {
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback(e);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick, useCapture);

        return () => {
            document.removeEventListener("click", handleClick, useCapture);
        };
    });
};

export const useOutsideMouseUp = (callback) => {
    const handleMouseUp = e => {
        callback(e);
    };

    useEffect(() => {
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    });
};

export const useResizeWindow = (callback) => {
    const handleResize = e => {
        callback(e)
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    },[]);
}

export const useHookWithRefCallback = () => {
    const ref = useRef(null)
    const setRef = useCallback(node => {
        ref.current = node
    }, [])
    return [setRef]
}

export let globalZIndex = 1000;
export const useZIndexPopup = () => {
    const [zIndex, setZIndex] = useState(globalZIndex);

    const updateZIndex = () => {
        globalZIndex = globalZIndex + 2;
        setZIndex(globalZIndex++);
    }


    return [zIndex, updateZIndex];
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export const usePermission = (initPermission) => {
    const [permission, setPermission] = useState({});

    const getPermission = useCallback((id)=> {
        return permission[id] || false;
    }, [permission]);

    useEffect(()=> {
        setPermission(initPermission)
    }, [])

    return [getPermission]
}