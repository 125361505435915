export  function isValidUrl(str) {
    let url = encodeURI(str);
    let urlPattern = new RegExp("^https?\\:\\/\\/[^\\/\\s]+(\\/.*)?$");

    return urlPattern.test(url);
}

export function validateSpecialChar(str){
    return (!/[\/?,;:|*~`!^@\#$%&\\\"<>]/gi.test(str));
}

export function validateSpecialCharForExternalServerName(str){
    return (!/[^a-zA-Z0-9()-]/gi.test(str));
}

export function validateAlphaNumericDash(str){
    return (!/[^a-zA-Z0-9-_]/gi.test(str));
}

export function isNumber(str){
    return /[0-9]/g.test(str);
}

export function validateEventName(str){
    return (!/[\]\[\/\\]/gi.test(str));   
}