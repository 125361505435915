import React from 'react';
import classNames from 'classnames';
import './Pagination.css'
import {getPageSize} from "../../helper";

const Pagination = ({totalCount, page, pageSizeOptions, style, onPageChange, onPageSizeChange, divide = 'default', defaultPageSize}) => {

    const pageSize = getPageSize(divide, [
        defaultPageSize, 
        ((pageSizeOptions && pageSizeOptions.length > 0) ? pageSizeOptions[0] : undefined), 
        25
    ]);

    const pg = page + 1;
    const totalPage = Math.ceil(totalCount / pageSize);
    const currentPage = ((page >= totalPage)?(page-1):page);
    const start = totalCount === 0 ? totalCount : (currentPage * pageSize) + 1;
    const end = totalCount === 0 ? totalCount : ((currentPage + 1) === totalPage ? totalCount : (currentPage + 1) * pageSize);
    let pages = [];

    const onChangePageSize = e => {
        const changed = parseInt(e.target.value);
        const userId = JSON.parse(localStorage.getItem('user')).id;
        divide && localStorage.setItem(`${divide.toUpperCase()}_pageSize_${userId}`, changed );
        onPageSizeChange(changed, 0);
    };

    const onChangePage = e => {
        const changed = e - 1;
        if(page !== changed) {
            onPageChange(changed);
        }
    };

    /*if(page > 0 && page >= totalPage){
        onPageChange(page - 1);
    }*/

    if(totalPage < 8) {
        for(let i = 1; i <= totalPage; i++) {
            pages.push(i);
        }
    } else {
        if(pg < 5) {
            for(let i = 1; i <= Math.min(totalPage, 5); i++) {
                pages.push(i);
            }
            pages = pages.concat([-1, totalPage - 1, totalPage]);
        } else if((totalPage - pg + 1) < 5) {
            pages = pages.concat([1, 2, -1]);
            
            for(let i = totalPage - 4; i <= totalPage; i++) {
                pages.push(i);
            }
        } else {
            pages = pages.concat([1, -1, pg - 1, pg, pg + 1, -1, totalPage]);
        }
    }

    return totalCount&&(
        <div className='pagination_wrap' style={style}>
            <div className='pagination_info'>{`${start} - ${end} / ${totalCount}`}</div>
            <div className='pagination_size'>
                {
                    pageSizeOptions && pageSizeOptions.length > 0 &&
                    <select onChange={onChangePageSize} value={pageSize}>
                    {
                        pageSizeOptions.map(ps => <option key={ps} value={ps}>{ps}</option>)
                    }
                    </select>
                }
            </div>
            <ul className='pagination_pages'>
                {
                    pages.map((e, i) => {
                        if(e === -1) {
                            return <li key={i}>...</li>;
                        }
                        return (
                            <li className={classNames({'active': e === page + 1})} key={`page_${e}`} data-value={e} onClick={() => onChangePage(e)}>{e}</li>
                        );
                    })

                }
            </ul>
        </div>
    );
};

Pagination.defaultProps = {
    totalCount: 0,
    onPageSizeChange: () => {}
};

export default Pagination;