import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './CreateStreaming.css';
import {useDispatch, useSelector} from "react-redux";
import {contentAction, popupAction} from "../../../actions";
import TextInput from "../../input/TextInput";
import {contentService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import SPLAYER_3 from '../../../images/icon/SPLAYER3.0.png';
import SPLAYER_4 from '../../../images/icon/SPLAYER4.0.png';
import SPLAYER_5 from '../../../images/icon/SPLAYER5.0.png';
import SPLAYER_6 from '../../../images/icon/SPLAYER6.0.png';
import SPLAYER_7 from '../../../images/icon/SPLAYER7.0.png';
import SPLAYER_9 from '../../../images/icon/SPLAYER9.0.png';
import iPLAYER from '../../../images/icon/iPLAYER.png';
import {validateSpecialChar} from "../../../helper/contentValidator";
import {contentConstants} from "../../../constants";

const CreateStreaming = (props) => {
    const {t} = useTranslation();
    const {id} = props;
    const dispatch = useDispatch();
    const myContentGroupId = useSelector(({content}) => content.myContentGroupId);
    const [streaming, setStreaming] = useState({
        userId : localStorage.getItem('user').id,
        groupId: myContentGroupId === "" ? 0 : myContentGroupId,
        urlContentName: '',
        urlAddress: '',
        type: contentConstants.CONTENT_TYPE_STREAMING
    });

    const {urlContentName, urlAddress} = streaming;

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const saveStreamingContent = () => {
        if(urlContentName.trim() === '' || urlAddress.trim() === ''){
            toastr.error(t('MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P'));
        }else if(!validateSpecialChar(urlContentName)) {
            toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
        }else{
            contentService.createStreamingContent(streaming)
                .then(res => {
                    if(res.status == 'Success'){
                        toastr.success(t('MESSAGE_SCHEDULE_SUCCESS_ADD_P'));
                        dispatch(contentAction.reloadContent(true));
                    }
                    closePopup(id);
                }).catch(error => {
                    toastr.error(t('MIS_MESSAGE_CONTENT_FAIL_TO_ADD_CONTENT_P'));
            });
        }
    };

    return (
        <div>
            <MISDialog dialog={{
                title: t('TEXT_IS_STREAMING_P'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 550,
                height: 180,
                position: {x: -150, y: -200},
                onClose: () => closePopup(id),
            }}
                       buttons={{
                           rightButtons: [
                               {
                                   id: 'SAVE',
                                   title: t('COM_BUTTON_SAVE'),
                                   onClick: () => saveStreamingContent(),
                               },
                               {
                                   id: 'CANCEL',
                                   title: t('BUTTON_CANCEL_P'),
                                   onClick: () => closePopup(id),
                               }
                           ],
                       }}>
                <div className="upload_streaming_content_popup">
                    <table>
                        <colgroup>
                            <col width="139px"/>
                            <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t('TEXT_CONTENT_NAME_P')}</th>
                            <td>
                                <TextInput width={300} maxLength={100} value={urlContentName} onChange={(e) => setStreaming({...streaming, urlContentName: e.target.value})} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_MIS_TEXT_ADDRESS_P')}</th>
                            <td>
                                <TextInput width={300} value={urlAddress} maxLength={200} onChange={(e) => setStreaming({...streaming, urlAddress: e.target.value})} />
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='no_padding'>
                                <span className="device_icon">
                                    <img src={iPLAYER} />  : MMS, RTP, RTSP, HTTP
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='no_padding'>
                                <span className="device_icon">
                                    <img src={SPLAYER_9} />
                                    <img src={SPLAYER_7} />
                                    <img src={SPLAYER_6} />
                                    <img src={SPLAYER_5} />
                                    <img src={SPLAYER_4} />
                                    <img src={SPLAYER_3} />: MMS, RTP, HLS, HTTP
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <span className='warring'>{t('MIS_SID_STREAMING_PROTOCOLS_NOT_SUPPORTED_PROPERLY')}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};

export default CreateStreaming;