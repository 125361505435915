import React from 'react';
import SPLAYER_3 from '../../../images/icon/SPLAYER3.0.png';
import SPLAYER_4 from '../../../images/icon/SPLAYER4.0.png';
import SPLAYER_5 from '../../../images/icon/SPLAYER5.0.png';
import SPLAYER_6 from '../../../images/icon/SPLAYER6.0.png';
import SPLAYER_7 from '../../../images/icon/SPLAYER7.0.png';
import SPLAYER_9 from '../../../images/icon/SPLAYER9.0.png';
import SIGNAGE from '../../../images/icon/SIGNAGE.png';

const SignagePlayer = ({deviceTypeVersion, text, disabled = false}) => {
    return(
        <span className={"device_icon"}>
            {(
                ()=>{
                    switch(deviceTypeVersion) {
                        case 1:
                        case 2:
                        case 3:
                            return <img src={SPLAYER_3} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                        case 4:
                            return <img src={SPLAYER_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                        case 5:
                            return <img src={SPLAYER_5} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                        case 6:
                            return <img src={SPLAYER_6} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                        case 7:
                            return <img src={SPLAYER_7} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                        case 9:
                            return <img src={SPLAYER_9} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVersion={deviceTypeVersion}/>
                    }
                }
            )()}
            <img src={SIGNAGE} data-deviceType={'SIGNAGE'} data-deviceTypeVersion={deviceTypeVersion}/>
            {text ? 'Signage Player' : ''}
        </span>
    )
}
export default SignagePlayer