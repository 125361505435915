import React from 'react';
import './TabContainer.css';
import Tab from '../components/tab/Tab';
import {connect} from "react-redux";
import {menuAction, popupAction} from "../actions";
import WhiteButton from "../components/button/WhiteButton";
import LanguageButton from "../language/LanguageButton";
import classNames from 'classnames';
import {withTranslation} from "react-i18next";
import {commonConstants, userConstants} from "../constants";
import {settingService, systemService, userService} from "../services";
import TutorialPopup from "../components/popup/common/TutorialPopup";
import ICON_DEVICE from "../images/icon/icon_device.png";
import {toastr} from 'helper/toastrIntercept';
import {getQueryVariable, history} from "../helper";
import {setLocale} from "../helper/utils";

import {openPrivacyPolicySettingPopup} from './setting/ServerSettings';
import isEmpty from "lodash/isEmpty";
import {getErrorMessage} from "../helper/responseHandler";

class TabContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            style : {
                width: 200
            },
            userId : '',
            isMultiGroupUser : false,
            isDisplayOptions : false,
            mngOrgList: [],
            multiGroupTitle : '',
            signUpDate: 0,
            passwordChangeDate: 0
        }
    }

    componentDidMount() {
        const {addPopup, closePopup, t} = this.props;
        this._updateDimensions();
        let user = JSON.parse(localStorage.getItem('user'));

        Promise.all([
            userService.fetchUserLoginInformation(),
            settingService.fetchServerSettings()
        ]).then(res => {
            if(user) {
                user.serverAdmin = res[0].items.serverAdmin;
                user.groupId = res[0].items.groupId;
                user.isMultiOrganizationManager = res[0].items.isMultiOrganizationManager;
                user.roleName = res[0].items.roleName;
                if(res[0].items.userInfo !== undefined){
                    user.id = res[0].items.userInfo.userId;
                }
                user.lastLoginDate = res[0].items.lastLoginDate;
                localStorage.setItem('user', JSON.stringify(user));
                const locale = getQueryVariable('locale') || res[0].items.locale;
                setLocale(locale);
            }
            let userId = res[0].items.userInfo.userId;
            let signUpDate = res[0].items.signUpDate;
            let passwordChangeDate = res[0].items.passwordChangeDate;
            if (res[0].items.isMultiOrganizationManager === true) {
                if (res[0].items.multiOrganizationInfo !== undefined) {
                    let resMngOrgList = res[0].items.multiOrganizationInfo.organizationList;
                    const mngOrgList = resMngOrgList.map(item => {
                        return {title: item.organizationName, value: item.organizationId};
                    });

                    this.setState({mngOrgList: mngOrgList, isMultiGroupUser: true,
                        multiGroupTitle: mngOrgList.filter(item => item.value === res[0].items.multiOrganizationInfo.currentOrganizationId)[0].title});
                }
            }

            this.setState({userId: userId, signUpDate: signUpDate, passwordChangeDate: passwordChangeDate});

            let isOpenPrivacyPolicySettingPopup = true;

            if (res[0].items.needPasswordReset && res[0].items.ldapId === '') {
                if (res[0].items.passwordChangeAfterLogin !== undefined &&  res[0].items.passwordChangeAfterLogin ===  true) {
                    isOpenPrivacyPolicySettingPopup = false;
                    addPopup({
                        type: commonConstants.PASSWORD_RESET,
                        id: commonConstants.PASSWORD_RESET,
                        userId: this.state.userId,
                        isExpiredPassword: res[0].items.isExpiredPassword,
                        onChangePassword: (currentPassword, password, passwordConfirm) => {
                            this.handlePasswordReset(currentPassword, password, passwordConfirm, closePopup, (rrr) => {
                                rrr && this.checkAdminPrivacyPolicy(res) && openPrivacyPolicySettingPopup(this.props);
                            });
                        },
                        onClose: () => {
                            closePopup(userConstants.RESET_PASSWORD)

                            // check privacy policy - (admin)
                            if(this.checkAdminPrivacyPolicy(res)){
                                openPrivacyPolicySettingPopup(this.props);
                            }
                        }
                    });
                } else {
                    let message = '';
                    if( res[0].items.isExpiredPassword !== undefined && res[0].items.isExpiredPassword){
                        message = t("MIS_SID_SERVER_PASSWORD_EXPIRED_CHANGE_PASSWORD");
                    } else {
                        message = t("MIS_MESSAGE_USER_PASSWORD_CHANGE_REQUEST_P");
                    }
                    addPopup({
                        type: commonConstants.COMMON_ALERT_POPUP,
                        id: 'PASSWORD_RESET_WARNING',
                        title: t("TEXT_TITLE_WARNING_P"),
                        message: message,
                        width: 340,
                        height: 120,
                        onClose: () => {
                            closePopup('PASSWORD_RESET_WARNING');

                            // check privacy policy - (admin)
                            if(this.checkAdminPrivacyPolicy(res)){
                                openPrivacyPolicySettingPopup(this.props);
                            }
                        }
                    });
                }
            }

            // check privacy policy - (admin) first login
            if(isOpenPrivacyPolicySettingPopup && this.checkAdminPrivacyPolicy(res)){
                openPrivacyPolicySettingPopup(this.props);
            }
        }).catch(e=> toastr.error(getErrorMessage(e)));

        systemService.fetchAlerts().then(res => {
            if(res.length <= 0)
                return;
            res.forEach(item => {
                switch(item.level){
                    case 'ALERT':
                        let message = '';

                        item.multiLanguage && item.multiLanguage.forEach(info => {
                            let temp = info.multiLanguageId ? t(info.multiLanguageId) : info.text;
                            temp && info.multiLanguageParams && info.multiLanguageParams.length > 0 && info.multiLanguageParams.forEach((param, i) => {
                                const indexText = ['<<A>>','<<B>>','<<C>>','<<D>>','<<E>>'][i];
                                const paramKey = Object.keys(param)[0];
                                const paramValue = (t(paramKey) === paramKey) ? param[paramKey] : t(paramKey);
                                temp.includes(indexText) && (temp = temp.replace(indexText, paramValue));
                            });
                            temp && (message += temp + ' ');
                        });

                        message = (
                            <>
                                <div style={{textAlign:'left'}}>
                                    {message}
                                </div>
                            </>
                        )
                        setTimeout(() => {
                            this._openAlertPopup(item.name, message);
                        }, 500);
                }
            });
        });

        user && user.serverAdmin && systemService.fetchDatabaseCheckingItems().then(res => {
            const checkingResult = res && res.items && res.items.filter(item => item.checkResult === false);
            if(checkingResult && checkingResult.length > 0){
                setTimeout(() => {
                    this._openAlertPopup("DatabaseCheckingResult", (
                        <>
                            <div style={{textAlign:'left'}}>
                                {t('MIS_SID_CBFEB_PROBLEMS_FOUND_DATABASE_VISIT_ADDRESS_BELOW_AND_CHECK_DETAILS')}
                            </div>
                            <div style={{textAlign:'left', paddingLeft:10, marginTop:5}}>
                                <a href="recovery">[Recovery Page]</a>
                            </div>                            
                        </>
                    ));
                }, 1000);
            }
        })
            .catch(e=> console.log(e));
        window.addEventListener("resize", this._updateDimensions.bind(this));
    }

    _openAlertPopup(alertId, contents) {
        const {addPopup, closePopup, t} = this.props;
        addPopup({
            id: commonConstants.COMMON_ALERT_POPUP + alertId,
            type: commonConstants.COMMON_ALERT_POPUP,
            height: '120px',
            title: t('COM_TV_NOTIFICATIONS_KR_VOICE'),
            message: contents,
            onClose: () => closePopup(commonConstants.COMMON_ALERT_POPUP + alertId)
        });
    }

    componentWillUnmount() {
        const {closePopup} = this.props;
        closePopup(commonConstants.PASSWORD_RESET);
        closePopup(commonConstants.PASSWORD_RESET_WARNING);
        window.removeEventListener('resize', this._updateDimensions, false);
    }

    _updateDimensions() {
        const {updateTab} = this.props;
        updateTab();
    }

    showSubmenu() {
        const {submenu} = this.props.menu;
        const {showSubmenu} = this.props;
        showSubmenu(submenu.id);
    }

    openLogOutPopup() {
        const {openPopup, t} = this.props;
        openPopup({type: commonConstants.LOGOUT_POPUP, viewType: 'LOGOUT_POPUP', id: commonConstants.LOGOUT_POPUP})
    }

    handleChangeOrganization(organizationId, multiGroupTitle) {
        const {t} = this.props;

        userService.updateUserCurrentManagedOrganization({organizationId: organizationId}).then(res => {
        }).catch(err => {
            err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
            this.setState({multiGroupTitle: multiGroupTitle});
        }).finally(
            ()=>history.go('/')
        )
    }

    checkAdminPrivacyPolicy(res){
        let privacyPolicySettings = res[1].items.privacyPolicy;
        let isNotExist = privacyPolicySettings.privacyPolicyLocation === null || isEmpty(privacyPolicySettings.privacyPolicyLocation);
        return isNotExist && res[0].items.userInfo.userId === userConstants.USER_ID_ADMIN;

    }

    toggleDisplayOptions() {
        this.setState({isDisplayOptions: !this.state.isDisplayOptions});
    }

    offDisplayOptions() {
        this.setState({isDisplayOptions: false});
    }

    onSelect(e, selectedOrganizationId) {
        const {addPopup, closePopup, t} = this.props;
        const {multiGroupTitle, } = this.state;
        this.setState({multiGroupTitle: e.currentTarget.innerText});

        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: 'CHANGE_ORGANIZATION',
            useMessageLine: false,
            title: t("COM_BUTTON_CONFIRM"),
            message: (
                <div style={{height: '190px'}}>{t('MIS_SID_CHANGE_ORGANIZTION_PROCEED')}</div>
            ),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('COM_BUTTON_CLOSE_P'),
            width: 360,
            height: 170,
            onClickYes: (e) => {
                this.handleChangeOrganization(selectedOrganizationId, multiGroupTitle);
                closePopup('CHANGE_ORGANIZATION');
            },
            onClose: () => {
                this.setState({multiGroupTitle: multiGroupTitle});
                closePopup('CHANGE_ORGANIZATION')
            }
        });
    };

    handlePasswordReset(currentPassword, password, passwordConfirm, closePopup, complete) {
        const {addPopup, t} = this.props;

        settingService.updateMyAccount({currentPassword:currentPassword, password: password, passwordConfirm: passwordConfirm, userId: this.state.userId}).then(res => {
            closePopup(commonConstants.PASSWORD_RESET);
            complete && complete(true);
            addPopup({
                type: commonConstants.COMMON_CONFIRM_POPUP,
                id: 'RESET_PASSWORD_CONFIRM',
                useMessageLine: false,
                title: t("TEXT_RESET_PASSWORD_P"),
                message: (
                    <div style={{height: '190px', marginTop: '30px'}}>{t('MESSAGE_USER_SUCCESS_CHANGE_PASS_P')}</div>
                ),
                useNoButton: false,
                width: 360,
                height: 100,
                onClickYes: (e) => {
                    closePopup('RESET_PASSWORD_CONFIRM');
                },
                onClose: () => {
                    closePopup('RESET_PASSWORD_CONFIRM')
                }
            });
        }).catch(error => {
            complete && complete(false);
            if(!error.errorCode && !error.body)
                return;

            let errorCode = error.errorCode !== undefined ? error.errorCode : error.body.errorCode;
            if (errorCode === '400706') {
                toastr.error(t('COM_SID_NEW_PASSWORD_NOT_SAME_OLD'));
            } else if (errorCode === '400708') {
                toastr.error(t('MIS_SID_SERVER_RECENTLY_PASSWORDS_NOT_BE_USED'));
            } else if(errorCode === '400745') {
                toastr.error(t('MESSAGE_COMMON_WRONG_PASSWORD_P'));
            } else {
                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
            }            
        });
    }

    render() {
        const {tabs, tabWidth, submenu, submenuWidth, menus} = this.props.menu;
        const {mngOrgList, isMultiGroupUser, isDisplayOptions, multiGroupTitle} = this.state;
        const tab = tabs.find(tab => tab.active === true);
        const {t} = this.props;
        let hasSubMenu = false;
        let active = true;

        if (submenu.id === 'INSIGHT' && menus.length > 0) {
            const found = menus.find(m => m.id === submenu.id);
            if (found) {
                active = found.active;
            }
        }

        if (submenu != null && submenu.hasSubmenu) {
            hasSubMenu = true;
        }

        return (
            <div className="tabWrap">
                <div className={classNames('menu_title', {insightTitle : submenu.id === 'INSIGHT'}, {hasSubMenu : hasSubMenu && submenu.showSubmenu }, {hasSubMenu : (submenu.id === 'INSIGHT' && active) || submenu.id === 'RULE_MANAGER'})} style={{width: submenuWidth}}>
                    <h1>{tab != null && tab.title != null ? tab.title : ''}</h1>
                    <button type="button" className={classNames('lnb_control', {on : hasSubMenu && !submenu.showSubmenu})} style={{display: hasSubMenu ? '' : 'none', cursor: 'pointer'}} onClick={()=>this.showSubmenu()}><span></span></button>
                </div>
                <div className="tabArea">
                    <div className="tabMenu">
                        <ul>
                            {
                                tabs.map(
                                    (tab, index) => <Tab key={index} index={index} close={tab.close} title={tab.tabTitle ? tab.tabTitle : tab.title} active={tab.active} id={tab.id} width={tabWidth}/>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="loginWrap">
                    <ul style={{display: 'flex', alignItems:'center', justifyContent: 'center', float: 'right'}}>
                        <li style={{marginRight: 10}}>
                            <div className="user_name" id="user_login_id">{this.state.userId}</div>
                        </li>
                        <li>
                            <WhiteButton id={'btn_sign_out'} name={t("COM_IDWS_MOIP_POPUP_EXIT_SIGN_OUT")} onClick={()=>this.openLogOutPopup()}/>
                        </li>
                        <li style={{paddingLeft: '7px'}}>
                            <TutorialPopup />
                        </li>
                        <li style={{padding: '5px 5px 0 0'}}>
                            <LanguageButton language={localStorage.getItem('MagicInfoPremiumLanguage')}/>
                        </li>
                    </ul>
                    {
                        isMultiGroupUser &&
                        <ul style={{display: '', float: 'right', marginRight: '20px'}}>
                            <li className="mngOrg_select_box">
                                <div className="select_box" id="selectMngOrg">
                                    <button className={classNames('select', 'base', {'on': isDisplayOptions})} id="selectMngOrgBtn"
                                            onClick={() => this.toggleDisplayOptions()} onBlur={() => this.offDisplayOptions()}>
                                        <img className="mr5" src={ICON_DEVICE}/>{multiGroupTitle}<span></span>
                                    </button>
                                    {
                                        isDisplayOptions && mngOrgList && mngOrgList.length > 0 &&
                                        <div className="option_list checklist" style={{
                                            minWidth: '110px',
                                            display: '',
                                            left: '0px',
                                            top: '37px',
                                            zIndex: 50,
                                            overflowX: 'hidden',
                                            maxHeight: '400px',
                                            overflowY:'auto'
                                        }}>
                                            <div>
                                                <span></span>
                                                <ul>
                                                    {
                                                        mngOrgList.map(e => {
                                                            return (
                                                                <li key={e.value} data-name={'multiOrgGroup'}
                                                                    data-value={e.value}
                                                                    onMouseDown={(evt) => this.onSelect(evt, e.value)}>
                                                                    <a href="#">{e.title}</a>
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </li>
                        </ul>
                    }
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        openPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        addTab: tab => dispatch(menuAction.addTab(tab)),
        activeTab: tab => dispatch(menuAction.activeTab(tab)),
        updateTab: ()=> dispatch(menuAction.updateTab()),
        showSubmenu: (id)=> dispatch(menuAction.showSubmenu(id)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })

)(withTranslation()(TabContainer));
