import {authHeader, createParams, createQuery, handleResponse, history, objToQueryString, handleBinDownloadResponse} from '../helper';
import fileDownload from 'js-file-download';
import {fetchWithToken as fetch} from '../helper/fetchIntercept.js';
import {fetchForDownload} from "../helper/fetchIntercept";
import {merge} from "lodash";
import {_axios as axios} from "../helper/axiosIntercepts";
import {setExclusive} from "../helper/fetchUtils";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const contentService = {
    fetchContents,
    fetchContentFilter,
    fetchContentById,
    fetchContentVersionById,
    fetchGroups,
    fetchGroupInfoById,
    fetchGroupById,
    moveGroup,
    deleteContentById,
    deleteAll,
    restoreContentById,
    approveContentById,
    approveContents,
    uploadContent,
    uploadContentCancel,
    fetchContentThumbnail,
    updateContentById,
    updateContentVersionByVersionId,
    updateContentCategory,
    updateContentTag,
    updateContentsAssignTags,
    updateContentsExpireDate,
    createFtpContent,
    fetchFtpSetting,
    editFtpSetting,
    createCifsContent,
    fetchCifsSetting,
    editCifsSetting,
    createStreamingContent,
    createUrlContent,
    editUrlContent,
    fetchUrlSetting,
    createAdsContent,
    editAdsContent,
    fetchAdsSetting,
    fetchAdsContentSuggestionInfo,
    deleteAdsContentSggestionInfo,
    movedContent,
    shareContent,
    releaseSharedContent,
    checkConvertibility,
    convertTemplate,
    contentDownload,
    contentExport,
    createContentGroup,
    renameContentGroup,
    deleteContentGroup,
    fetchContentAvailability,
    fetchAvailablePlaylist,
    fetchWebAuthorAuthorizedUser,
    fetchSharedGroupInfoById,
    updateSharedGroupInfo,
    fetchUserIdByOrganizationId,
    fetchFolderListByUserId,
    fetchFolderListByUserIdForByUser,
    authorityCheck,
    searchContentGroupByGroupName,
    fetchItems,
    fetchItemsByContentId,
    createAdvertisementItem,
    unAssignItemOnContent,
    assignItemOnContent,
    fetchAdvertisement,
    editAdvertisement,
    updateAdvertisement
};

function fetchContents(state) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let obj ={
        pageSize : state.pageSize,
        startIndex : state.pages
    };
    let params = objToQueryString(obj);
    return fetch(backendHost+'/restapi/v2.0/cms/contents'+params, requestOptions).then(handleResponse);
}

function fetchContentFilter(values) {
    const requestOptions = setExclusive({
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    });
    return fetch(backendHost+'/restapi/v2.0/cms/contents/filter', requestOptions).then(handleResponse);
}

function fetchGroups(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/groups?' + createQuery(params), requestOptions).then(handleResponse);
}

function fetchGroupInfoById(groupId, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(backendHost+`/restapi/v2.0/cms/contents/groups/${groupId}?` + createQuery(params), requestOptions).then(handleResponse);
}

function fetchGroupById(groupId, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/groups/'+groupId+'/sub-groups?'+ createQuery(params), requestOptions).then(handleResponse);
}

function moveGroup(groupId, destinationGroupId, requestBody) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/groups/'+groupId+'/destination-groups/'+ destinationGroupId, requestOptions).then(handleResponse);
}

function fetchContentById(contentId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/${contentId}?ts=${new Date().getTime()}`, requestOptions).then(handleResponse);
}

function fetchContentVersionById(contentId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/${contentId}/versions?ts=${new Date().getTime()}`, requestOptions).then(handleResponse);
}

function deleteContentById(params){

    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(params)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/deleted-contents', requestOptions).then(handleResponse);
}

function deleteAll() {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/recycle-bin', requestOptions).then(handleResponse);
}

function restoreContentById(contentIds){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(contentIds)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/restored-contents', requestOptions).then(handleResponse);
}

function approveContentById(data){
    const {contentId} = data;
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(data)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/unapproved-contents/'+contentId, requestOptions).then(handleResponse);
}

function approveContents(data){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(data)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/unapproved-contents', requestOptions).then(handleResponse);
}

const retrieveToken = () => {
    try {
        let user = JSON.parse(localStorage.getItem('user'));
        return user.token;
    } catch(e) {
        console.log('retrieveToken',e);
    }
    return null;
}

function uploadContent(formData, fileName, onProgress, cancelToken = null){



    // return fetch(`${backendHost}/restapi/v2.0/cms/contents/files`, {
    //     headers: authHeader('FILE'),
    //     onUploadProgress: (progressEvent) => { onProgress(fileName, Math.round( (progressEvent.loaded * 100) / progressEvent.total )) },
    //     cancelToken : cancelToken
    // }).then(handleResponse());



    const requestOptions = {
        onUploadProgress: (progressEvent) => { onProgress(fileName, Math.round( (progressEvent.loaded * 100) / progressEvent.total )) },
        headers: authHeader('FILE'),
        cancelToken : cancelToken
    };
    const token = retrieveToken();
    let optionsWithToken = requestOptions;
    if (token !== null) {
        optionsWithToken = merge({}, requestOptions, {
            headers: {
                api_key: token
            }
        })
    }

    return axios.post(backendHost+'/restapi/v2.0/cms/contents/files', formData, optionsWithToken).then(response => {
        return new Promise(resolve => {resolve(response.data)});
    }).catch(response => {
        if (response.status !== 200) {
            if (response.status === 401) {
                history.push('/login');
            }
            return Promise.reject(response);
        }
    });
}

function uploadContentCancel(contentIds){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(contentIds)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/cancel-upload-contents', requestOptions).then(handleResponse);
}


function contentDownload(contentIds){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(contentIds)
    };

    return fetchForDownload(backendHost+'/restapi/v2.0/cms/contents/download', requestOptions)
        .then(res => res.blob())
        .then(res => {
            fileDownload(res, "download.zip");
        }).catch(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(response.message);
            }
        });
}

function fetchContentThumbnail(id, queryParam) {
    const {lastLoginDate} = JSON.parse(localStorage.getItem('user'));
    queryParam = {...queryParam, lastLoginDate};
    const requestOptions = {
        method: 'GET',
        headers: authHeader('IMAGE'),
    };
    return fetch(backendHost + `/restapi/v2.0/cms/contents/thumbnails/${id}?` + createQuery(queryParam), requestOptions);
}

function updateContentById(data){
    const {contentId} = data;
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(data)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/'+contentId, requestOptions).then(handleResponse);
}

function updateContentVersionByVersionId(data){
    const {contentId, versionId} = data;
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(data)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/'+contentId+'/versions/'+versionId, requestOptions).then(handleResponse);
}

function updateContentCategory(data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(data)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/categories', requestOptions).then(handleResponse);
}

function updateContentTag(data){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(data)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/tags', requestOptions).then(handleResponse);
}

function updateContentsAssignTags(requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(requestBody)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/tags', requestOptions).then(handleResponse);
}

function updateContentsExpireDate(requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(requestBody)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/expiration-date', requestOptions).then(handleResponse);
}

function createFtpContent(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/ftp-settings', requestOptions).then(handleResponse);
}

function fetchFtpSetting(contentId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/ftp-settings/' + contentId, requestOptions).then(handleResponse);
}

function editFtpSetting(contentId, requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("PUT"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/ftp-settings/' + contentId, requestOptions).then(handleResponse);
}


function createCifsContent(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/cifs-settings', requestOptions).then(handleResponse);
}

function fetchCifsSetting(contentId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/cifs-settings/' + contentId, requestOptions).then(handleResponse);
}

function editCifsSetting(contentId, requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("PUT"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/cifs-settings/' + contentId, requestOptions).then(handleResponse);
}


function createStreamingContent(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/strm-settings', requestOptions).then(handleResponse);
}

function createUrlContent(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/url-settings', requestOptions).then(handleResponse);
}

function editUrlContent(contentId, requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("PUT"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/url-settings/' + contentId, requestOptions).then(handleResponse);
}

function fetchUrlSetting(contentId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/url-settings/' + contentId, requestOptions).then(handleResponse);
}

function createAdsContent(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/ads-settings', requestOptions).then(handleResponse);
}

function editAdsContent(contentId, requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("PUT"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/ads-settings/' + contentId, requestOptions).then(handleResponse);
}

function fetchAdsSetting(contentId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/ads-settings/' + contentId, requestOptions).then(handleResponse);
}

function fetchAdsContentSuggestionInfo(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/ads-content-suggestion-info', requestOptions).then(handleResponse);
}

function deleteAdsContentSggestionInfo(suggestionType, suggestionId){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/ads-content-delete-suggestion-info/' + suggestionType + '/' + suggestionId, requestOptions).then(handleResponse);
}

function movedContent(requestBody, groupId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/' + groupId + '/moved-contents', requestOptions).then(handleResponse);
}

function shareContent(requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/groups/shared-contents' , requestOptions).then(handleResponse);
}

function releaseSharedContent(requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/unshared-contents', requestOptions).then(handleResponse);
}

function checkConvertibility(contentId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader("GET")
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/' + contentId + '/template-convertibility', requestOptions).then(handleResponse);
}

function convertTemplate(requestBody, contentId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST")
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/' + contentId + '/templates?' + createQuery(requestBody), requestOptions).then(handleResponse);
}

function contentExport(requestBody, exportType) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    let fileName = (exportType === 'EXCEL' ? "ContentList.xls" : "ContentList.pdf");
    return fetchForDownload(backendHost+'/restapi/v2.0/cms/contents/filter/export?exportType=' + exportType, requestOptions)
        .then(res => res.blob())
        .then(res => {
            fileDownload(res, fileName);
        }).catch(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(response.message);
            }
        });
}

function createContentGroup(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/groups', requestOptions).then(handleResponse);
}

function renameContentGroup(groupId, requestBody){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("PUT"),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/groups/' + groupId, requestOptions).then(handleResponse);
}

function deleteContentGroup(groupId, requestParam){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/groups/' + groupId + "?" + createQuery(requestParam), requestOptions).then(handleResponse);
}

function fetchContentAvailability(contentIds){
    const requestOptions = {
        method: 'POST',
        headers: authHeader("POST"),
        body: JSON.stringify(contentIds)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/availability', requestOptions).then(handleResponse);
}

function fetchAvailablePlaylist(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/playlists/available-playlists', requestOptions).then(handleResponse);
}

function fetchWebAuthorAuthorizedUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/web-author/authorized-user', requestOptions).then(handleResponse);
}

function fetchSharedGroupInfoById(groupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/shared-groups/' + groupId, requestOptions).then(handleResponse);
}

function updateSharedGroupInfo(groupId, requestBody) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(requestBody)
    };

    return fetch(backendHost+'/restapi/v2.0/cms/contents/shared-groups/' + groupId, requestOptions).then(handleResponse);
}

function fetchUserIdByOrganizationId(orgId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/organizations/' + orgId + '/users', requestOptions).then(handleResponse);
}

function fetchFolderListByUserIdForByUser(userId, orgId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/users/${userId}/groups/${orgId}`, requestOptions).then(handleResponse);
}

function fetchFolderListByUserId(userId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/users/' + userId + '/groups', requestOptions).then(handleResponse);
}

function authorityCheck(requestBody){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(requestBody)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/groups/authority-check', requestOptions).then(handleResponse);
}

function searchContentGroupByGroupName(requestParam){
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/groups/searched-groups?`+createQuery(requestParam), {                                                    //SF[00207800]
        method: 'POST',
        headers: authHeader('POST')
    }).then(handleResponse);
}
function fetchItemsByContentId(contentId) {
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/${contentId}/items`, {
        method: 'GET',
        headers: authHeader()
    }).then(handleResponse);
}

function fetchItems(searchKeyword) {
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/items?searchKeyword=${searchKeyword}&startIndex=1&pageSize=10`, {
        method: 'GET',
        headers: authHeader()
    }).then(handleResponse);
}

function createAdvertisementItem(contentId, item) {
    const values = {
        contentIds: [contentId],
        value: item
    }
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/items`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }).then(handleResponse)
}

function unAssignItemOnContent(contentId, itemId) {
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/${contentId}/items/${itemId}`, {
        method: 'DELETE',
        headers: authHeader()
    });
}

function assignItemOnContent(contentId, itemId) {
    const values = {
        itemIds: [itemId]
    }
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/${contentId}/items`,{
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }).then(handleResponse)
}

function fetchAdvertisement(contentId) {
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/${contentId}/advertisement`,{
        method: 'GET',
        headers: authHeader()
    }).then(handleResponse)
}

function editAdvertisement(contentId,data) {
    return fetch(`${backendHost}/restapi/v2.0/cms/contents/${contentId}/advertisement`,{
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(data)
    }).then(handleResponse)
}

function updateAdvertisement(data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(data)
    };
    return fetch(backendHost+'/restapi/v2.0/cms/contents/advertisements', requestOptions).then(handleResponse);
}
