import React, {useCallback, useEffect, useState} from 'react';
import Size from "../utils/Size";
import {useDispatch} from "react-redux";
import {deviceAction, menuAction, popupAction} from "../../actions";
import {useTranslation} from "react-i18next";
import {APLAYER, commonConstants, FLIP, IPLAYER, RSPLAYER, SIGNAGE, WPLAYER, SIGNAGE_CHILD} from "../../constants";
import classNames from 'classnames';
import DeviceIcons from './DeviceIcons';
import PowerThumb from '../../images/img/thumb_img_power.png';
import ScreenMonitoringLockThumb from '../../images/img/status_monitoring_lock.svg';
import './Device.css';
import {useDeviceMouseHook, getDeviceVersion, getImageUrls} from "../../helper/device/deviceUtils";

const ratio = 0.5625;

const Device = ({device, showScreenCapture, showInformation, showTagInformation, onClick, index, width, onClickMemo, selected, hasDeviceSecurity, setRef}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [thumbUrl, setThumbUrl] = useState(PowerThumb);
    const [captureUrl, setCaptureUrl] = useState(PowerThumb);

    const [toggleHover, hovered] = useDeviceMouseHook();

    const getHeight = useCallback(()=> {
        return width * ratio;
    }, [width])

    const height = getHeight();

    const TagInfo = ({show, device}) => {
        let tagTitle = '';
        const mediaTagCondition = (device.mediaTagValueList && device.mediaTagValueList.length > 0);
        const variableTagCondition= (device.varTagValueList && device.varTagValueList.length > 0);
        const display = show && (mediaTagCondition || variableTagCondition);


        if(mediaTagCondition) {
            tagTitle += `*${t('MIS_SID_MEDIA_TAG')}*\n`;
            device.mediaTagValueList.forEach(tag => {
                tagTitle += tag.tagName + '\n';
            });
        }
        
        if(variableTagCondition) {
            tagTitle += `*${t('MIS_SID_VARIABLE_TAG')}*\n`;
            device.varTagValueList.forEach(tag => {
                tagTitle += tag.tagName += '\n';
            });
        }

        return (
            <div className="device_tag_info add" style={{textAlign:'left',lineHeight:'20px', display: display ? 'block' : 'none'}} title={tagTitle}>
                <div>
                    {
                        mediaTagCondition &&
                        <span>
                        <i>MED</i>
                            {
                                device.mediaTagValueList.map((tag, i) => (i === device.mediaTagValueList.length - 1) ? tag.tagName : tag.tagName + ',')
                            }
                    </span>
                    }
                    <br/>
                    {
                        variableTagCondition &&
                        <span>
                        <i>VAR</i>
                            {
                                device.varTagValueList.map((tag, i) => (i === device.varTagValueList.length - 1) ? tag.tagName : tag.tagName + ',')
                            }
                    </span>
                    }
                </div>
            </div>
        );
    }

    const openPopup = (e, deviceId) => {
        e.stopPropagation();
        dispatch(popupAction.openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'DEVICE', id: deviceId, hasDeviceSecurity}))
    }

    const onClickLedCabinets = () => {
        dispatch(menuAction.addTab({
            id: 'LED_CABINET',
            title: t('TEXT_TITLE_DEVICE_P'),
            tabTitle: device.deviceName,
            active: true,
            close: true,
        }))
        //dispatch(menuAction.loadContent('LED_CABINET', true))
        dispatch(deviceAction.loadLedCabinet(device.deviceId))
    };

    const renderCapture = thumb => {
        if(device.landscape !== 'landscape') {
            let rotate90deg = true;
            if(device.screenMonitoringLock === 1){
                rotate90deg = false;
            }
            return (
                <div className="dev_img_capture" style={{
                    background: `url('${thumb}') no-repeat`,
                    backgroundPosition:'center',
                    backgroundSize:'contain',
                    display:'inline-block',
                    MsTransform: rotate90deg ? 'rotate(90deg)': '',
                    WebkitTransform: rotate90deg ? 'rotate(90deg)': '',
                    transform: rotate90deg ? 'rotate(90deg)': ''
                }}></div>
            );
        } else {
            return (
                <div className="dev_img_capture" style={{
                    // background: `url('${thumb}') no-repeat center / contain`,
                    backgroundImage: `url('${thumb}')`,
                    backgroundRepeat: 'none',
                    backgroundPosition:'center',
                    backgroundSize:'contain',
                    display:'inline-block'
                }}></div>
            );
        }
    }

    const renderThumb = thumb => {
        return (
            <div className="dev_img_thumb" style={{
                // background: `url('${thumb}') no-repeat center / contain`,
                backgroundImage: `url('${thumb}')`,
                backgroundRepeat: 'none',
                backgroundPosition:'center',
                backgroundSize:'contain',
                display:'inline-block'
            }}></div>
        );
    }

    useEffect(() => {
        if (device.power && (captureUrl !== device.captureUrl || thumbUrl !== device.thumbUrl)) {
            getImageUrls(device)
                .then(
                    res => {
                        setThumbUrl(res[0]);
                        if(device.screenMonitoringLock === 1){
                            setCaptureUrl(ScreenMonitoringLockThumb);
                        } else {
                            setCaptureUrl(res[1]);
                        }
                    }
                )
        } else if (!device.power && ((captureUrl !== device.captureUrl || thumbUrl !== device.thumbUrl))) {
            setThumbUrl(PowerThumb);
            setCaptureUrl(PowerThumb);
        }
    }, [device.thumbUrl, device.captureUrl])

    return (
        <>
            {
                device.deviceType === SIGNAGE_CHILD ? '' :
                    <li className="thumbnail_view_wrap" style={{width}}>
                        <div ref={setRef} className={classNames("thumbview_box", "device_thumb", device.landscape === 'landscape' ? "landscape" : "portrait", {'on' : selected.includes(index)})} style={{width,height}} onClick={()=>onClick(index)} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
                            <div className="dev_thumb_img_wrapper">
                                {
                                    showScreenCapture ? renderCapture(captureUrl) : renderThumb(thumbUrl)
                                }
                            </div>
                            <div className="device_info add" style={{height, display: (hovered || showInformation) && width >= 150 ? 'block' : 'none'}}>
                                <div>
                                    <span><i>MAC</i>{device.deviceId}</span>
                                    <br />
                                    <span><i>IP</i>{device.ipAddress}</span>
                                    <br />
                                    <span><i>HDD</i><Size size={device.diskSpaceRepository} /></span>
                                    <br />
                                    <span style={{whiteSpace:'nowrap',overflow:'hidden',maxWidth:150}} title={getDeviceVersion(device)}>
                                        <i>VER</i>
                                        {getDeviceVersion(device)}
                                    </span>
                                </div>
                            </div>
                            <TagInfo show={showTagInformation && width >= 150} device={device} />
                            <div className="check"><span></span></div>
                            <div className="ov" style={{display: width < 150 && hovered ? 'block' : 'none'}}>
                                <div className="overlay_info_box">
                                    <span className='link_cell' onClick={e => openPopup(e, device.deviceId)}>{device.deviceName}</span>
                                    <span className="float_r">
                                        <DeviceIcons device={device} />
                                    </span>
                                </div>
                                <div className="info">
                                    <p>{t('DID_ADMIN_LICENSE_MAC')} <span>{device.deviceId}</span></p>
                                    <p>{t('TABLE_IP_ADDR_P')} <span>{device.ipAddress}</span></p>
                                    <p>{t('COM_TABLE_AVAILABLE_MEMORY_P')} <span><Size size={device.diskSpaceRepository} /></span></p>
                                    <p>{t('TABLE_FIRMWARE_VERSION_P')} <span>{getDeviceVersion(device)}</span></p>
                                </div>
                            </div>
                        </div>
                        {
                            width >= 150 &&
                            <div>
                                <div className="status_wrap clearfix" style={{position:'relative', background: device.power ? '#98c1eb' : '#afb3b8',}}>
                                    <span className="dev_name" style={device.hasChild && (device.deviceType === 'LEDBOX' || device.deviceType === 'RLEDBOX') ? {maxWidth: '70%'} : {maxWidth: '100%'}}>{device.deviceName}</span>
                                    {
                                        // device.hasChild &&
                                        device.hasChild && (device.deviceType === 'LEDBOX' || device.deviceType === 'RLEDBOX') &&
                                        <span className="child_info" style={{cursor: 'pointer'}} onClick={onClickLedCabinets}>{device.childCount || 0}</span>
                                    }
                                    <div>
                                        <span className="float_l">
                                            <DeviceIcons device={device} onClickMemo={onClickMemo} />
                                        </span>
                                        <div className="float_r info_button" style={{cursor:'pointer'}} onClick={(e)=>openPopup(e, device.deviceId)}>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </li>
            }
        </>
    )

}
export default Device;