import React from 'react';
import SPLAYER_4 from '../../../images/icon/SPLAYER4.0.png';
import SPLAYER_5 from '../../../images/icon/SPLAYER5.0.png';
import SPLAYER_6 from '../../../images/icon/SPLAYER6.0.png';
import SPLAYER_7 from '../../../images/icon/SPLAYER7.0.png';
import SPLAYER_9 from '../../../images/icon/SPLAYER9.0.png';
import LEDBOX_4 from '../../../images/icon/LEDBOX4.0.png';

const LEDPlayer = ({deviceTypeVersion, text, disabled = false}) => {
    return(
        <span className={"device_icon"}>
            {deviceTypeVersion < 5 &&
                <>
                    <img src={SPLAYER_4} style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVerison={deviceTypeVersion}/>
                    <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVerison={deviceTypeVersion}/>
                </>
            }
            {deviceTypeVersion >= 5 && deviceTypeVersion < 6 &&
                <>
                    <img src={SPLAYER_5} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVerison={deviceTypeVersion}/>
                    <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVerison={deviceTypeVersion}/>
                </>
            }
            {deviceTypeVersion >= 6 && deviceTypeVersion < 7 &&
                <>
                    <img src={SPLAYER_6} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVerison={deviceTypeVersion}/>
                    <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVerison={deviceTypeVersion}/>
                </>
            }
            {
                deviceTypeVersion >= 7 && deviceTypeVersion < 8 &&
                <>
                    <img src={SPLAYER_7} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVerison={deviceTypeVersion}/>
                    <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVerison={deviceTypeVersion}/>
                </>
            }
            {
                deviceTypeVersion >= 9 && deviceTypeVersion < 10 &&
                <>
                    <img src={SPLAYER_9} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'SPLAYER'} data-deviceTypeVerison={deviceTypeVersion}/>
                    <img src={LEDBOX_4} style={{opacity : disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'LEDBOX'} data-deviceTypeVerison={deviceTypeVersion}/>
                </>
            }
            {text ? 'LEDBOX' : ''}
        </span>
    )
}
export default LEDPlayer