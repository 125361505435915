import React, {useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../../components/button/WhiteButton";
import "../../../components/table/react-table.css";
import {scheduleService} from '../../../services';
import SearchBar from "../../../components/search/SearchBar";
import Checkbox from "../../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../../components/icon/SupportedDeviceIcon";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../../helper';
import {commonConstants, SCHEDULE_PAGE_SIZE_OPTIONS} from "../../../constants";
import {menuAction, popupAction} from "../../../actions";
import {toastr} from 'helper/toastrIntercept';
import DateToStr from "../../../components/utils/DateToStr";
import {DeviceGroups} from "../../../helper/schedule/scheduleUtils";
import {snakeCase} from "lodash";
import {getErrorMessage} from "../../../helper/responseHandler";
import MagicInfoTable from "../../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../../helper/tables";
import {updateCache} from "../../../helper/cache/tableCache";
import {useMISOpt} from "../../../components/misopt";

const MessageScheduleRecycleBin = (props) => {

    const {getAuthority} = useMISOpt();
    const [authority] = useState(getAuthority('SCHEDULE'));

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}], searchText} = filter;
        setData({...data, loading: true});
        scheduleService.fetchMessageScheduleFilter({
            startIndex: (page * pageSize) + 1,
            pageSize,
            productType: 'PREMIUM',
            groupType: 'TRASH',
            searchText: searchText,
            sortColumn: snakeCase(id),
            sortOrder: desc === true ? 'desc' : 'asc'
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
            updateCache('SCHEDULE', {items: res.items, filter: filter, totalCount: res.totalCount})
        });
    }


    const getCheckedId = () => {
        return selected.current.map(s => items[s].messageId);
    }

    const permanentlyDeleteMessage = () => {
        const messageIds = getCheckedId();
        const params = {
            deleteMethod: "DELETE_FORCE",
            ids: messageIds
        }
        scheduleService.deleteMessageByIds(params).then(
            res=> {
                toastr.success(t("COM_TEXT_SUCCESS_P"));
            }
        ).catch(
            err=> toastr.error(err)
        ).finally(()=>{
            dispatch(popupAction.closePopup(messageIds[0]));
            setFilter({...filter, reload: !filter.reload});
        })
    }

    const openRestorePopup = () => {
        const messageId = getCheckedId();
        if (messageId.length > 0) {
            const popup = {
                type: commonConstants.COMMON_GROUP_POPUP,
                id: messageId[0],
                mode: 'message_schedule',
                save: (groups)=>restoreMessage(groups),
                close: ()=>dispatch(popupAction.closePopup(messageId[0]))
            }
            dispatch(popupAction.addPopup(popup));
        } else {
            toastr.error(t("MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P"));
        }
    }

    const restoreMessage = (groups) => {
        if (groups !=undefined && groups.length > 0) {
            const messageId = getCheckedId();
            const group = groups[0];
            scheduleService.restoreMessageSchedule(messageId, group.groupId).then(
                res=>{
                    toastr.success(t("COM_TEXT_SUCCESS_P"));
                }
            ).catch(
                err=> {
                    toastr.error(err);
                }
            ).finally(()=>{
                dispatch(popupAction.closePopup(messageId[0]));
                dispatch(menuAction.updateSubMenuCounter('SCHEDULE'));
                dispatch(menuAction.reloadGroup('MESSAGE_SCHEDULE_BY_GROUP'));
                setFilter({...filter, reload: !filter.reload});
            })
        }
    }

    const openDeletePopup = () => {
        const messageId = getCheckedId();
        const popup = {
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: messageId[0],
            title: t("COM_BUTTON_DELETE"),
            message: <>{t('MIS_SID_CBFEB_IF_DELETE_SELECTED_ITEMS_DATCANNOT_RESTORED')}<br/>{t('MIS_SID_CBFEB_SURE_WANT_DELETE_SELECTED_ITEMS')}</>,
            useMessageLine: false,
            height: 90,
            onClickYes: ()=>permanentlyDeleteMessage(),
            onClose: ()=>dispatch(popupAction.closePopup(messageId[0]))
        }
        dispatch(popupAction.addPopup(popup));
    }

    const openEmptyRecyclePopup = () => {
        const id = "MESSAGE_SCHEDULE_EMPTY_RECYCLE";
        const popup = {
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("BUTTON_EMPTY_RECYCLE_BIN_P"),
            message: t("MESSAGE_CONTENT_CONFIRM_EMPTY_RECYCLE_BIN_P"),
            onClickYes: ()=>emptyRecycle(),
            onClose: ()=>dispatch(popupAction.closePopup(id))
        }
        dispatch(popupAction.addPopup(popup));
    }

    const emptyRecycle = () => {
        const id = "MESSAGE_SCHEDULE_EMPTY_RECYCLE";
        scheduleService.emptyMessageScheduleRecycleBin().then(
            res=> {
                if (res) {
                    toastr.success(t("COM_TEXT_SUCCESS_P"));
                }
            }
        ).catch(e=>toastr.error(getErrorMessage(e)))
        .finally(()=>{
            dispatch(popupAction.closePopup(id));
            setFilter({...filter, reload: !filter.reload});
        })
    }

    const onChangeScheduleName = value => {
        setFilter({...filter, searchText: value, page: 0})
    }

    const columns = useMemo(()=>[
        {
            accessor: "messageId",
            show: false
        },
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllMessageScheduleRecycle_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].messageId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                );
            },
        },
        {
            Header: t("TEXT_SCHEDULE_NAME_P"),
            accessor: "messageName",
            width: 500
        },
        {
            Header: t("COM_MIS_TEXT_SUPPORTED_DEVICE_TYPE_P"),
            Cell: props => <SupportedDeviceIcon deviceType={props.original.deviceType} deviceTypeVersion={props.original.deviceTypeVersion}/>,
            sortable: false,
            width: 200
        },
        {
            Header: t("TEXT_ORGANIZATION_P"),
            accessor: "messageGroupName",
            sortable: false,
            width: 150
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifiedDate",
            Cell: ({value}) => <DateToStr date={value} />

        }
    ], [items])

    useEffect(() => {
        setStyle({height: window.innerHeight - 205});
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%', display:props.currContent === 'MESSAGE_SCHEDULE_RECYCLE_BIN' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_SCHEDULE_RESTORE"} name={t("BUTTON_RESTORE_P")} disable={selectedCnt < 1} onClick={openRestorePopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={openDeletePopup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_EMPTY_RECYCLE"} name={t("BUTTON_EMPTY_RECYCLE_BIN_P")} onClick={openEmptyRecyclePopup} authority={authority.CREATE || authority.MANAGE}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="scheduleSearch"
                               placeholder={t("COM_TEXT_MESSAGE_NAME_P")}
                               onClickSearch={onChangeScheduleName}/>
                </div>
            </div>
            <MagicInfoTable
                data={items}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                columns={columns}
                style={style}
                usePagination={true}
                getTrGroupProps={getTrGroupPropsType2}
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    pageSize: pageSize,
                    pageSizeOptions: SCHEDULE_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: props.divide
                }}
            />
        </div>
    )

}
export default MessageScheduleRecycleBin;