import React from 'react';
import Tree, {TreeNode} from 'rc-tree';
import './GroupContainer.css';
import {connect} from "react-redux";
import {
    contentService,
    deviceService,
    playlistService,
    rulesetService,
    scheduleService,
    userService
} from "../../services";
import {commonConstants, contentConstants, TREE_KEY_SEPARATOR} from "../../constants";
import {menuAction, popupAction} from "../../actions";
import ContextMenu from "../../components/tooltip/ContextMenu";
import classNames from "classnames";
import * as ReactDOM from "react-dom";
import {withTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept'
import {getElementById} from "../../helper";
import {checkContextMenu} from "../../helper/groups";
import {getErrorMessage} from "../../helper/responseHandler";
import {get, isNil, set, union} from 'lodash';
import {MISOptContext} from "../../components/misopt";
import {getMixString} from "../../language/languageUtils";
import PerfectScrollbar from "perfect-scrollbar";
import {Loading} from "../../components/loading/Loading";
import {getCache, isCacheTable, updateCache} from "../../helper/cache/tableCache";
import {getAllItemsLinkIdFromMenu} from "../../constants/MenuConstants";

const MAX_GROUP_NAME_LENGTH = 64;
const Title = (node) => {

    if( node.responseDataType === 'CONTENT_GROUP'  ||
        node.responseDataType === 'PLAYLIST_GROUP' ||
        node.responseDataType === 'CONTENT_SCHEDULE_GROUP' ||
        node.responseDataType === 'MESSAGE_SCHEDULE_GROUP' ||
        node.responseDataType === 'EVENT_SCHEDULE_GROUP' ||
        node.responseDataType === 'TEMPLATE_GROUP' ||
        node.responseDataType === 'TEMPLATE_ORGANIZATION' ||

        node.responseDataType === 'RULESET_GROUP'
    ) {
        return (
            <>
                {node.name}
                {   node.resourceCount !== undefined &&
                <span className={'group_count'}>{node.resourceCount !== undefined ? node.resourceCount : 0}</span>
                }
            </>
        )
    } else  if( node.responseDataType === 'USER_ORGANIZATION' ||
                node.responseDataType === 'USER_GROUP'
    ) {
        return (
            <>
                {node.name}
                {node.userCount !== undefined &&
                <span className={'group_count'}>{node.userCount !== undefined ? node.userCount : 0}</span>
                }
            </>
        )
    } else if (
        node.responseDataType === 'CONTENT_SCHEDULE_ORGANIZATION' ||
        node.responseDataType === 'MESSAGE_SCHEDULE_ORGANIZATION' ||
        node.responseDataType === 'EVENT_SCHEDULE_ORGANIZATION'
    ) {
        return (
            <>
                {node.name}
                {node.resourceCountIncludeSubGroup !== undefined &&
                <span className={'group_count'}>{node.resourceCountIncludeSubGroup !== undefined ? node.resourceCountIncludeSubGroup : 0}</span>
                }
            </>
        )
    } else {
        return (
            <>
                {node.name}
            </>
        )
    }
}

const Icon = (group ) => {

    if( group.groupType === 'MY_CONTENT_GROUP'){
        return (<span
            className={classNames(
                'customize-icon',
                {
                    'empty': group.parentGroupId > 0
                }
            )}
        />)
    }else if (group.groupType === 'SHARED_GROUP'){
        return (<span
            className={classNames(
                'customize-icon'
            )}
        />)
    } else if (group.responseDataType === 'TEMPLATE_ORGANIZATION'
        || group.responseDataType === 'CONTENT_ORGANIZATION'
        || group.responseDataType === 'PLAYLIST_ORGANIZATION'
        || group.responseDataType === 'USER_ORGANIZATION'){
        return (<span
            className={classNames(
                'customize-icon', 'organization'
            )}
        />)
    } else if (group.responseDataType === 'PLAYLIST_GROUP'){
        return (<span
            className={classNames(
                'customize-icon',
                {
                    'empty': group.parentGroupId > 0
                }
            )}
        />)
    } else if ( group.userId === '' ) {
        return (<span
            className={classNames(
                'customize-icon',
                {
                    'organization': group.index === null,
                    'empty': group.parentGroupId > 0
                }
            )}
        />)
    } else {
        return (<span
            className={classNames(
                'customize-icon',
                {
                    'organization': group.description !== undefined && group.description === 'Organization',
                    'empty': group.parentGroupId > 0
                }
            )}
        />)
    }
}

const findNode = (treeData, groupId) => {
    for(let i = 0; i < treeData.length; i++) {
        const treeNode = treeData[i];
        if(treeNode.groupId == groupId) {
            return treeNode;
        } else {
            if(treeNode.children != undefined){
                const found = findNode(treeNode.children, groupId);
                if(found) {
                    return found;
                }
            }
        }
    }

    return null;
};

class GroupContainer extends React.Component {

    static contextType = MISOptContext;

    contextMenus = {
        ['RULESET_BY_GROUP']: [
            {id:'create_rule_set_group', name: 'MIS_BUTTON_ADD_GROUP_P', onClick: ()=> this.createGroup(), icon: '', disabled: false},
            {id:'rename_rule_set_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_rule_set_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['MY_CONTENT']: [
            {id:'create_my_content_group', name: 'COM_TV_SID_NEW_FOLDER', onClick: ()=> this.createGroup(), icon: '', disabled: false},
            {id:'rename_my_content_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_my_content_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['SHARED_CONTENT']: [
            {id:'create_shared_content_group', name: 'COM_TV_SID_NEW_FOLDER', onClick: ()=> this.createGroup(), icon: '', disabled: false},
            {id:'shared_content_group', name: 'COM_SID_SHARE_KR_SETUP', onClick: ()=> this.shareFolder(), icon: '', disabled: false},
            {id:'rename_shared_content_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_shared_content_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['CONTENT_TEMPLATE_BY_FOLDER']: [
            {id:'create_content_template_group', name: 'COM_TV_SID_NEW_FOLDER', onClick: ()=> this.createGroup(), icon: '', disabled: false},
            {id:'rename_content_template_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_content_template_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['BY_USER']: [
            {id:'create_by_user_group', name: 'COM_TV_SID_NEW_FOLDER', onClick: ()=> this.createGroup(), icon: '', disabled: false},
            {id:'rename_by_user_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_by_user_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['MY_PLAYLIST']: [
            {id:'create_rule_set_group', name: 'COM_TV_SID_NEW_FOLDER', onClick: ()=> this.createGroup(), icon: '', disabled: false},
            {id:'rename_rule_set_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_rule_set_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['PLAYLIST_BY_USER']: [
            {id:'create_rule_set_group', name: 'COM_TV_SID_NEW_FOLDER', onClick: ()=> this.createGroup(), icon: ''},
            {id:'rename_rule_set_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_rule_set_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['CONTENT_SCHEDULE_BY_GROUP']: [
            {id:'create_rule_set_group', name: 'MIS_BUTTON_ADD_GROUP_P', onClick: ()=> this.createGroup(), icon: '', disabled: true},
            {id:'rename_rule_set_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: true},
            {id:'delete_rule_set_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: true},
        ],
        ['MESSAGE_SCHEDULE_BY_GROUP']: [
            {id:'create_rule_set_group', name: 'MIS_BUTTON_ADD_GROUP_P', onClick: ()=> this.createGroup(), icon: '', disabled: true},
            {id:'rename_rule_set_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_rule_set_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['EVENT_SCHEDULE_BY_GROUP']: [
            {id:'create_rule_set_group', name: 'MIS_BUTTON_ADD_GROUP_P', onClick: ()=> this.createGroup(), icon: '', disabled: true},
            {id:'rename_rule_set_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_rule_set_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['DEVICE_BY_GROUP']: [
            {id:'create_rule_set_group', name: 'MIS_BUTTON_ADD_GROUP_P', onClick: ()=> this.createGroup(), icon: ''},
            {id:'rename_rule_set_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_rule_set_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['ORGANIZATION_GROUP']: [
            {id:'create_rule_set_group', name: 'MIS_BUTTON_ADD_GROUP_P', onClick: ()=> this.createGroup(), icon: ''},
            {id:'rename_rule_set_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_rule_set_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
        ['USER_BY_GROUP']: [
            {id:'create_by_user_group', name: 'MIS_BUTTON_ADD_GROUP_P', onClick: ()=> this.createGroup(), icon: '', disabled: false},
            {id:'rename_by_user_group', name: 'BUTTON_CHANGE_NAME_P', onClick: ()=> this.changeGroup(), icon: '', disabled: false},
            {id:'delete_by_user_group', name: 'COM_BUTTON_DELETE', onClick: ()=> this.removeGroup(), icon: '', disabled: false},
        ],
    }


    componentDidUpdate(prevProps, prevState, snapshot){

        if(prevProps.group !== this.props.group) {
            this.setState({
                treeData: this.props.group
            });
        }

        if (prevProps.loadingGroup !== this.props.loadingGroup) {
            if (this.props.id === this.props.loadingGroup.id) {
                if (this.props.loadingGroup.loading) {
                    ReactDOM.findDOMNode(this.props.loadingGroup.dom).classList.add('rc-tree-treenode-loading');
                } else {
                    ReactDOM.findDOMNode(this.props.loadingGroup.dom).classList.remove('rc-tree-treenode-loading');
                }
            }
        }

        if(prevProps.selectGroup !== this.props.selectGroup) {
            const {id, submenuId, selectGroup, activeNodeGroup} = this.props;
            if(id === selectGroup.id) {
                const {expandedKeys} = this.state;
                this.setState({
                    selectedKeys: [selectGroup.groupIds[selectGroup.groupIds.length - 1]],
                    expandedKeys: union(expandedKeys, selectGroup.groupIds),
                });
                activeNodeGroup(submenuId, id, selectGroup.groupIds[selectGroup.groupIds.length - 1]);
            }
        }

        if(this.props.updateScrollBar) {
            this.props.updateScrollBar();
        }

        if(this.ps[this.props.id]){
            this.ps[this.props.id].update();
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
            contextMenu: {
                show: false,
                position: {}
            },
            expandedKeys: [],
            loadedKeys: [],
            selectedKeys: [],
            groupLoading : undefined
        }
        this.treeRef = React.createRef();
        this.debouncedSelect = this.selectTreeNode;
        this.ps = [];
    }

    targetGroup = null;

    getAuthority(id) {
        const {authority}  = this.context;
        let auth = {};
        if (authority !== undefined && id !== undefined) {
            auth = authority[id];
        }
        return auth !== undefined ? auth : {};
    }

    updateExpandedKeys(keys, selectedKeys) {
        const {id, submenuId} = this.props;
        if (isCacheTable(submenuId)) {
            const data = getCache(submenuId)
            updateCache(submenuId, {...data, groups: {...data.groups, [id] : {expandedKeys : keys, selectedKeys: selectedKeys}}}, id);
        }
    }

    createGroupWithService(groupId, grandParantGroupId, value, removeLi, dom) {
        const {id, loadGroup, loadGroupWithExpandedKeys, requestGroup} = this.props;
        const newGroup = {groupName: value, parentGroupId: groupId.split(TREE_KEY_SEPARATOR)[1]}

        requestGroup(id, dom);

        switch (id) {
            case 'CONTENT_SCHEDULE_BY_GROUP':
                scheduleService.createContentScheduleGroup(newGroup).then(res=> {
                    loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'MESSAGE_SCHEDULE_BY_GROUP':
                scheduleService.createMessageScheduleGroup(newGroup).then(res=> {
                    loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'EVENT_SCHEDULE_BY_GROUP':
                scheduleService.createEventScheduleGroup(newGroup).then(res=> {
                    loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'DEVICE_BY_GROUP':
                deviceService.createDeviceGroup({groupName: value || 'new', parentGroupId: groupId}).then(res => {
                    loadGroup('DEVICE', id, true);
                }).finally(removeLi());
                break;
            case 'MY_CONTENT':
                contentService.createContentGroup({
                    ...newGroup,
                    groupType: 'MY_CONTENT_GROUP'
                }).then(res=> {
                    loadGroup('CONTENT', id, true);
                }).finally(removeLi());
                break;
            case 'SHARED_CONTENT':
                contentService.createContentGroup({
                    ...newGroup,
                    groupType: 'SHARED_GROUP'
                }).then(res=> {
                    loadGroup('CONTENT', id, true);
                }).finally(removeLi());
                break;
            case 'CONTENT_TEMPLATE_BY_FOLDER':
                contentService.createContentGroup({
                    ...newGroup,
                    groupType: 'TEMPLATE_GROUP'
                }).then(res=> {
                    loadGroupWithExpandedKeys('CONTENT', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'RULESET_BY_GROUP':
                rulesetService.createRulesetGroup(newGroup).then(res=> {
                    loadGroupWithExpandedKeys('RULESET', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'MY_PLAYLIST':
                playlistService.createPlaylistGroup({
                    ...newGroup,
                    groupType: 'MY_PLAYLIST_GROUP'
                }).then(res=> {
                    loadGroup('PLAYLIST', id, true);
                }).finally(removeLi());
                break;
            case 'USER_BY_GROUP':
                userService.createUserGroups({
                    ...newGroup
                }).then(res=> {
                    loadGroupWithExpandedKeys('USER', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            default :
                break;
        }
    }

    remove = false;

    handleKeyDownForCrate(e, childTree, currentGroup) {
        const removeLi = () => {
            const tempLi = ReactDOM.findDOMNode(childTree).querySelector("#temp_li");
            if (tempLi !== null) {
                this.removed = true;
                tempLi.remove();
            }
        };
        if (e.keyCode === 13) {
            const {groupId, parentGroupId} = this.state.contextMenu;
            const value = (e.target.value).trim();
            let valid = this.checkGroupNameValidation(value);
            if(valid) {
                this.createGroupWithService(groupId, parentGroupId, value, removeLi, currentGroup);
            } 
        } else if (e.keyCode === 27) {

            removeLi();
        }
    }

    updateGroupWithService(groupId, parentGroupId, value, removeLi, dom) {
        const {t, id, loadGroup, loadGroupWithExpandedKeys, requestGroup} = this.props;
        const updateTree = (res) => {
            if (res.items !== undefined && res.items.newGroupName !== undefined && res.items.newGroupName === value) {
                this.setState({
                    expandedKeys: this.state.expandedKeys.filter(key => key !== groupId && key !== parentGroupId),
                    selectedKeys: this.state.selectedKeys.filter(key => key !== groupId && key !== parentGroupId),
                    loadedKeys: this.state.loadedKeys.filter(key => key !== groupId && key !== parentGroupId)
                })
            } else {
                // toastr.error(t("COM_MIV_NOTHING_HAS_BEEN_CHANGED"));
            }
        }

        requestGroup(id, dom);

        switch (id) {
            case 'CONTENT_SCHEDULE_BY_GROUP':
                scheduleService.updateContentScheduleGroup(groupId, value).then(res=>{
                    loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'MESSAGE_SCHEDULE_BY_GROUP':
                scheduleService.updateMessageScheduleGroup(groupId, value).then(res=>{
                    loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'EVENT_SCHEDULE_BY_GROUP':
                scheduleService.updateEventScheduleGroup(groupId, value).then(res=>{
                    loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'DEVICE_BY_GROUP':
                deviceService.updateDeviceGroup(groupId, {newGroupName: value}).then(res => {
                    loadGroup('DEVICE', 'DEVICE_BY_GROUP', true);
                }).finally(removeLi());
                break;
            case 'MY_CONTENT':
                contentService.renameContentGroup(groupId, {groupName: value, groupType:'MY_CONTENT_GROUP'}).then(res=>{
                    loadGroup('CONTENT', id, true);
                }).finally(removeLi());
                break;
            case 'SHARED_CONTENT':
                contentService.renameContentGroup(groupId, {groupName: value, groupType:'SHARED_GROUP'}).then(res=>{
                    loadGroup('CONTENT', id, true);
                }).finally(removeLi());
                break;
            case 'CONTENT_TEMPLATE_BY_FOLDER':
                contentService.renameContentGroup(groupId, {groupName: value, groupType:'TEMPLATE_GROUP'}).then(res=>{
                    loadGroupWithExpandedKeys('CONTENT', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'RULESET_BY_GROUP':
                rulesetService.renameRulesetGroup(groupId, value).then(res=> {
                    loadGroupWithExpandedKeys('RULESET', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            case 'MY_PLAYLIST':
                playlistService.renamePlaylistGroup(groupId, {newGroupName: value, groupType:'MY_PLAYLIST_GROUP'}).then(res=>{
                    loadGroup('PLAYLIST', id, true);
                }).finally(removeLi());
                break;
            case 'USER_BY_GROUP':
                userService.updateUserGroupsById(groupId, {groupName: value, reason:''}).then(res => {
                    loadGroupWithExpandedKeys('USER', id, true, this.state.expandedKeys);
                }).finally(removeLi());
                break;
            default :
                break;
        }
    }

    handleKeyDownForChange(e, childTree) {
        const removeLi = () => {
            const tempLi = ReactDOM.findDOMNode(childTree).querySelector(".tempGroupName");
            if (tempLi !== null) {
                this.removed = true;
                tempLi.remove();
            }

        }


        if (e.keyCode === 13) {

            const value = (e.target.value).trim();
            let valid = this.checkGroupNameValidation(value);
            if(!valid) {
                return;
            }

            const currentGroupSpan = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-node-content-wrapper');
            currentGroupSpan.setAttribute('style', '');

            const currentGroupSpanTitle = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-title');
            currentGroupSpanTitle.setAttribute('style', '');

            const {groupId, parentGroupId} = this.state.contextMenu;
            const targetGroupId = groupId.split(TREE_KEY_SEPARATOR)[1] || groupId;

            this.updateGroupWithService(targetGroupId, parentGroupId, value, removeLi, childTree);

        } else if (e.keyCode === 27) {
            const currentGroupSpan = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-node-content-wrapper');
            currentGroupSpan.setAttribute('style', '');

            const currentGroupSpanTitle = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-title');
            currentGroupSpanTitle.setAttribute('style', '');

            removeLi();
        }
    }

    renderEleInDom(element, parentDom, append = true) {
        const { type, props } = element;
        const dom = document.createElement(type);

        const isListener = name => name.startsWith("on");
        Object.keys(props).filter(isListener).forEach(name => {
            const eventType = name.toLowerCase().substring(2);
            dom.addEventListener(eventType, props[name]);
        });

        const isAttribute = name => !isListener(name) && name != "children";
        Object.keys(props).filter(isAttribute).forEach(name => {
            dom[name] = props[name];
        });

        const childElements = props.children || [];
        childElements.forEach(childElement => this.renderEleInDom(childElement, dom));
        if (append) {
            parentDom.appendChild(dom);
        } else {
            parentDom.prepend(dom)
        }
    }

    createGroup() {
        let element;
        
        let currentGroup = this.targetGroup;
        while (!currentGroup.classList.contains('rc-tree-node-content-wrapper')) {
            currentGroup = currentGroup.parentNode;
        }
        currentGroup = currentGroup.parentNode;

        if (ReactDOM.findDOMNode(currentGroup).querySelector(".rc-tree-child-tree") !== null) {
            const childTree = ReactDOM.findDOMNode(currentGroup).querySelector(".rc-tree-child-tree");
            element = {
                type: 'li',
                props: {
                    id: 'temp_li',
                    children: [
                        {type: 'input', props: {type: 'text', className:'tempGroupName', value:'new', maxLength: MAX_GROUP_NAME_LENGTH, onKeydown: (e)=>this.handleKeyDownForCrate(e, childTree, currentGroup), onblur: (e)=>this.createGroupBlur(e, childTree, currentGroup)}}
                    ]
                }
            }
            this.renderEleInDom(element, childTree, true);
        } else {
            const childTree = ReactDOM.findDOMNode(currentGroup);
            element = {
                type: 'ul',
                props: {
                    className: 'rc-tree-child-tree rc-tree-child-tree-open',
                    children: [
                        {
                            type: 'li',
                            props: {
                                id: 'temp_li',
                                children: [
                                    {type: 'input', props: {type: 'text', className:'tempGroupName', value:'new', maxLength: MAX_GROUP_NAME_LENGTH, onKeydown: (e)=>this.handleKeyDownForCrate(e, childTree, currentGroup), onblur: (e)=>this.createGroupBlur(e, childTree, currentGroup)}}
                                ]
                            }
                        }
                    ]
                }
            }
            this.renderEleInDom(element, childTree, true);
        }
        ReactDOM.findDOMNode(currentGroup).querySelector('.tempGroupName').focus();
        ReactDOM.findDOMNode(currentGroup).querySelector('.tempGroupName').setSelectionRange(0, 3);

        this.showContextMenu();
    }

    updateGroupBlur(e, children) {
        if (e !== undefined) {
            if (this.removed) {
                this.removed = false;
                return;
            }
            const removeLi = () => {
                const tempLi = ReactDOM.findDOMNode(children).querySelector(".tempGroupName");
                if (tempLi !== null)
                    tempLi.remove();
            }
            const currentGroupSpan = ReactDOM.findDOMNode(children).querySelector('.rc-tree-title');
            currentGroupSpan.setAttribute('style', '');
            const {groupId, parentGroupId} = this.state.contextMenu;
            const targetGroupId = groupId.split(TREE_KEY_SEPARATOR)[1] || groupId;
            const value = (e.target.value).trim();
            let valid = this.checkGroupNameValidation(value);
            if(valid) {
                this.updateGroupWithService(targetGroupId, parentGroupId, value, removeLi, children);
            }
        }
    }

    createGroupBlur(e, children, currentGroup) {
        if (e !== undefined) {
            if (this.removed) {
                this.removed = false;
                return;
            }

            const removeLi = () => {
                const tempLi = ReactDOM.findDOMNode(children).querySelector("#temp_li");
                if (tempLi !== null)
                    tempLi.remove();
            };
            const {groupId, parentGroupId} = this.state.contextMenu;
            const value = (e.target.value).trim();
            
            let valid = this.checkGroupNameValidation(value);
            if(valid) {
                this.createGroupWithService(groupId, parentGroupId, value, removeLi, currentGroup);
            }
        }
    }

    moveGroup(dragKey, dropKey){
        const {submenuId, closePopup, t, id, loadGroup} = this.props;

        const targetId = dragKey.split(TREE_KEY_SEPARATOR)[1] || dragKey;
        const newParentId = dropKey.split(TREE_KEY_SEPARATOR)[1] || dropKey;
        let promise;
        switch(submenuId){
            case 'SCHEDULE':
                promise = this.moveScheduleGroup(targetId, newParentId);
                break;
            case 'PLAYLIST':
                promise = this.movePlaylistGroup(targetId, newParentId);
                break;
            case 'RULESET':
                promise = this.moveRulesetGroup(targetId, newParentId);
                break;
            case 'CONTENT':
                promise = this.moveContentGroup(targetId, newParentId);
                break;
            case 'DEVICE':
                promise = this.moveDeivceGroup(targetId, newParentId);
                break;
            case 'USER':
                promise = this.moveUserGroup(targetId, newParentId);
                break;
            default:
                break;
        }

        if(promise){
            promise.then(res => {
                if (res) {
                    if(id === 'MY_CONTENT'){
                        loadGroup('CONTENT', id, true);
                    }else if(id === 'MY_PLAYLIST'){
                        loadGroup('PLAYLIST', id, true);
                    }else{
                        let groupId = res.items.groupId || targetId;
                        let parentGroupId = res.items.parentGroupId || newParentId;
                        this.moveNode(groupId, parentGroupId);
                    }
                }else{
                    toastr(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
                }
            }).catch(e => {
                toastr.error(getErrorMessage(e));
            }).finally(
                closePopup(commonConstants.COMMON_CONFIRM_POPUP)
            );
        }

    }

    moveNode = (targetId, newParentId) => {
        const {t} = this.props;
        const {treeData} = this.state;
        const newTreeData = [...treeData];
        const targetNode = findNode(newTreeData, targetId);

        //remove node from origin tree
        const originParent = findNode(newTreeData, targetNode.parentGroupId);
        let originChildren = originParent.children.filter(child => child.groupId !== parseInt(targetId));
        set(originParent, "children", originChildren);
        //change parent id 
        set(targetNode, "parentGroupId", newParentId);
        //add to new parent
        const parentNode = findNode(newTreeData, newParentId);
        let children = parentNode.children;
        if(children == undefined){
            children = [];
        }
        children.push(targetNode);
        set(parentNode, "children", children);

        this.setState(
            {...this.state,
            treeData: newTreeData});
    }

    moveScheduleGroup = (groupId, destinationId) => {
        const {id} = this.props;
        switch(id){
            case 'CONTENT_SCHEDULE_BY_GROUP':
                return scheduleService.moveContentScheduleGroup(groupId, destinationId);
            case 'MESSAGE_SCHEDULE_BY_GROUP':
                return scheduleService.moveMessageScheduleGroup(groupId, destinationId);
            case 'EVENT_SCHEDULE_BY_GROUP':
                return scheduleService.moveEventScheduleGroup(groupId, destinationId);
        }
    }

    movePlaylistGroup = (groupId, destinationId) => {
        const {id} = this.props;
        switch(id){
            case 'MY_PLAYLIST':
                return playlistService.movePlaylistGroup(groupId, destinationId);
                break;
        }
    }

    moveDeivceGroup = (groupId, destinationId) => {
        const {id} = this.props;
        switch(id){
            case 'DEVICE_BY_GROUP':
                return deviceService.moveDeviceGroup(groupId, destinationId);
                break;
        }
    }

    moveContentGroup = (groupId, destinationId) => {
        const {id} = this.props;
        let requestBody = {};
        switch(id){
            case 'MY_CONTENT':
                requestBody = { groupType : contentConstants.MY_CONTENT_GROUP}
                break;
            case 'CONTENT_TEMPLATE_BY_FOLDER':
                requestBody = { groupType : contentConstants.TEMPLATE_GROUP}
                break;
            default:
                break;
        }

        return contentService.moveGroup(groupId, destinationId, requestBody);
    };

    moveRulesetGroup = (groupId, destinationId) => {
        return rulesetService.moveRulesetGroup(groupId, destinationId);
    }


    moveUserGroup = (groupId, destinationId) => {
        const {id} = this.props;
        switch(id){
            case 'USER_BY_GROUP':
                return userService.moveUserGroup(groupId, destinationId);
                break;
        }
    }

    checkGroupNameValidation = (name) => {
        const {t} = this.props;  
        if(name.length == 0){
            toastr.error(t("MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG"));
            return false;
        }
        if(name.length > 100){
            toastr.error(getMixString(['COM_SID_SCHEDULE_MIX_MAX_LENGTH', '100']));
            return false;
        }
        return true;
    }

    requestDelete = (targetKey, parentGroupId, parentResponseDataType, organizationId) => {
        const {t, id, closePopup, loadGroup, loadGroupWithExpandedKeys} = this.props;
        const targetGroupId = targetKey.split(TREE_KEY_SEPARATOR)[1] || targetKey;
        const targetParentGroupId = parentResponseDataType + TREE_KEY_SEPARATOR + parentGroupId + TREE_KEY_SEPARATOR + organizationId;
        let promise;
        switch (id) {
            case 'CONTENT_SCHEDULE_BY_GROUP':
                promise = scheduleService.deleteContentScheduleGroupById(targetGroupId);
                break;
            case 'MESSAGE_SCHEDULE_BY_GROUP':
                promise = scheduleService.deleteMessageScheduleGroupById(targetGroupId);
                break;
            case 'EVENT_SCHEDULE_BY_GROUP':
                promise = scheduleService.deleteEventScheduleGroupById(targetGroupId);
                break;
            case 'MY_CONTENT':
                promise = contentService.deleteContentGroup(targetGroupId, {parentGroupId: parentGroupId, groupType:"MY_CONTENT_GROUP"});
                break;
            case 'SHARED_CONTENT':
                promise = contentService.deleteContentGroup(targetGroupId, {parentGroupId: parentGroupId, groupType:"SHARED_GROUP"});
                break;
            case 'CONTENT_TEMPLATE_BY_FOLDER':
                promise = contentService.deleteContentGroup(targetGroupId, {parentGroupId: parentGroupId, groupType:"TEMPLATE_GROUP"});
                break;
            case 'MY_PLAYLIST':
                promise = playlistService.deletePlaylistGroup(targetGroupId, {parentGroupId: parentGroupId, groupType:"MY_PLAYLIST_GROUP"});
                break;
            case 'RULESET_BY_GROUP':
                promise = rulesetService.deleteRulesetGroup(targetGroupId);
                break;
            case 'USER_BY_GROUP':
                if (parentGroupId !== undefined && parseInt(parentGroupId) === 0) {
                    promise = userService.deleteUserOrganizationById(targetGroupId);
                } else {
                    promise = userService.deleteUserGroupsById(targetGroupId);
                }
                break;
            default :
                break;
        }

        if(promise) {
            promise.then(
                res=> {
                    if (res) {
                        switch (id) {
                            case 'USER_BY_GROUP':
                                this.setState({
                                    expandedKeys: this.state.expandedKeys.filter(key => key !== targetKey),
                                    selectedKeys: [],
                                    loadedKeys: []
                                });
                                break;
                            default:
                                this.setState({
                                    expandedKeys: this.state.expandedKeys.filter(key => key !== targetKey && key !== targetParentGroupId),
                                    selectedKeys: this.state.selectedKeys.filter(key => key !== targetKey && key !== targetParentGroupId),
                                    loadedKeys: this.state.loadedKeys.filter(key => key !== targetKey && key !== targetParentGroupId)
                                });
                                break;
                        }
                        let elementId='';
                        switch (id) {
                            case 'MY_CONTENT':
                                loadGroup('CONTENT', id, true);
                                elementId = getAllItemsLinkIdFromMenu('CONTENT');
                                break;
                            case 'SHARED_CONTENT':
                                toastr.success(t('ALERT_SUCCESS_DELETE'));
                                loadGroup('CONTENT', id, true);
                                elementId = getAllItemsLinkIdFromMenu('CONTENT');
                                break;
                            case 'CONTENT_TEMPLATE_BY_FOLDER':
                                loadGroupWithExpandedKeys('CONTENT', id, true, this.state.expandedKeys);
                                elementId = getAllItemsLinkIdFromMenu('CONTENT');
                                break;
                            case 'MY_PLAYLIST':
                                loadGroup('PLAYLIST', id, true);
                                elementId = getAllItemsLinkIdFromMenu('PLAYLIST');
                                break;
                            case 'USER_BY_GROUP':
                                loadGroupWithExpandedKeys('USER', id, true, this.state.expandedKeys);
                                elementId = getAllItemsLinkIdFromMenu('USER');
                                break;
                            case 'RULESET_BY_GROUP':
                                loadGroupWithExpandedKeys('RULESET', id, true, this.state.expandedKeys);
                                elementId = getAllItemsLinkIdFromMenu('RULESET');
                                break;
                            case 'CONTENT_SCHEDULE_BY_GROUP' :
                                loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                                elementId = getAllItemsLinkIdFromMenu('CONTENT_SCHEDULE');
                                break;
                            case 'MESSAGE_SCHEDULE_BY_GROUP' :
                                loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                                elementId = getAllItemsLinkIdFromMenu('MESSAGE_SCHEDULE');
                                break;
                            case 'EVENT_SCHEDULE_BY_GROUP' :
                                loadGroupWithExpandedKeys('SCHEDULE', id, true, this.state.expandedKeys);
                                elementId = getAllItemsLinkIdFromMenu('EVENT_SCHEDULE');
                                break;
                            default:
                                break;
                        }
                        if(elementId !== '') {
                            getElementById(elementId).click();
                        }
                    }
                    toastr.success(t("COM_TEXT_SUCCESS_P"));
                }
            ).catch( e => {
                let message = getErrorMessage(e);
                let errorCode = '';
                if (typeof  e.errorCode === 'undefined' ){
                    if( typeof  e.body !== 'undefined' && e.body.errorCode !== 'undefined' ){
                        errorCode = e.body.errorCode;
                    }
                } else {
                    errorCode = e.errorCode;
                }

                switch (id) {
                    case 'MY_PLAYLIST':
                        message = t('MESSAGE_CONTENT_CANNOT_DELETE_PLAYLIST_P');
                        break;
                    case 'USER_BY_GROUP':
                        switch (errorCode) {
                            case '500704':
                                message = t('MIS_SID_DELELTE_ORGANIZTION_MSG');
                                break;
                            case '400002':
                                message = t('MESSAGE_COMMON_FAIL_DEL_GROUP_P');
                                break;
                        }
                        break;
                    case 'RULESET_BY_GROUP':
                    case 'CONTENT_SCHEDULE_BY_GROUP':
                    case 'MESSAGE_SCHEDULE_BY_GROUP':
                    case 'EVENT_SCHEDULE_BY_GROUP':
                        message = t('MESSAGE_COMMON_CANT_DEL_GROUP_P');
                        break;
                    
                    //SF[00198390]
                    case 'SHARED_CONTENT':
                        if (e.items && e.items.reasonCode && e.items.reasonCode === '500307') {
                            message = t('MIS_SID_CBFEB_CANNOT_DELETE_SHARED_FOLDER_USE_ANOTHER_ORGANIZATION');
                        }
                        break;  

                    case 'MY_CONTENT':
                        if (e.items && e.items.reasonCode && e.items.reasonCode === '400035') {
                            message = t('MESSAGE_CONTENT_CANNOT_DELETE_CONTENT_P');
                        }
                        break;
                    default :
                        break;
                }
                if( typeof message === 'undefined' || message === '' ){
                    message = t('MESSAGE_COMMON_FAIL_DEL_GROUP_P');
                }
                toastr.error(message);
            }).finally(
                closePopup(commonConstants.COMMON_CONFIRM_POPUP)
            );
        }
    };

    removeContentScheduleGroup(targetKey, parentGroupId, parentResponseDataType, organizationId) {
        const {t, closePopup} = this.props;

        return {
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("TEXT_SCHEDULE_DELETE_P"),
            message: t("MESSAGE_COMMON_CONFIRM_DELETE_GROUP_P"),
            onClickYes: ()=> this.requestDelete(targetKey, parentGroupId, parentResponseDataType, organizationId),
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        };
    }

    removeContentGroup(targetKey, parentGroupId, parentResponseDataType, organizationId) {
        const {t, closePopup} = this.props;
        return {
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("MIS_SID_DELETEING_FLOSDER_SUB_FOLDER"),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('BUTTON_CANCEL_P'),
            onClickYes: () => this.requestDelete(targetKey, parentGroupId, parentResponseDataType, organizationId),
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        };
    }

    removePlaylistGroup(targetKey, parentGroupId, parentResponseDataType, organizationId) {
        const {t, closePopup} = this.props;

        return {
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_COMMON_CONFIRM_DELETE_GROUP_P"),
            onClickYes: () => this.requestDelete(targetKey, parentGroupId, parentResponseDataType, organizationId),
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        };
    }

    removeDeviceGroup(targetKey, parentGroupId) {
        const {t, closePopup, loadGroup} = this.props;
        const {treeData} = this.state;
        return {
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_COMMON_CONFIRM_DELETE_GROUP_P"),
            onClickYes: () => {
                deviceService.deleteDeviceGroup(targetKey).then(res => {
                    if (res.status === 'Success' ) {
                        // const newTreeData = [...treeData];
                        // const parentNode = findNode(newTreeData, parentGroupId);
                        // parentNode.children = parentNode.children.filter(node => node.groupId != groupId);

                        this.setState({
                            // treeData: newTreeData,
                            expandedKeys: this.state.expandedKeys.filter(key => key !== targetKey && key !== parentGroupId),
                            selectedKeys: this.state.selectedKeys.filter(key => key !== targetKey && key !== parentGroupId),
                            loadedKeys: this.state.loadedKeys.filter(key => key !== targetKey && key !== parentGroupId)
                        });
                        loadGroup('DEVICE', 'DEVICE_BY_GROUP');
                        toastr.success(t("COM_TEXT_SUCCESS_P"));
                        getElementById(getAllItemsLinkIdFromMenu('DEVICE')).click();
                    }else{
                        toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
                    }
                }).finally(() => closePopup(commonConstants.COMMON_CONFIRM_POPUP));
            },
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        };
    }

    removeRulesetGroup(targetKey, parentGroupId, parentResponseDataType, organizationId) {
        const {t, closePopup} = this.props;

        return {
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_COMMON_CONFIRM_DELETE_GROUP_P"),
            onClickYes: ()=> this.requestDelete(targetKey, parentGroupId, parentResponseDataType, organizationId),
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        };
    }

    removeUserGroup(targetKey, parentGroupId, parentResponseDataType, organizationId) {
        const {t, closePopup} = this.props;

        return {
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_COMMON_CONFIRM_DELETE_GROUP_P"),
            onClickYes: () => this.requestDelete(targetKey, parentGroupId, parentResponseDataType, organizationId),
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        };
    }

    shareFolder() {
        const {addPopup, closePopup} = this.props;
        const {groupId} = this.state.contextMenu;

        addPopup({
            type: commonConstants.FOLDER_SHARE,
            id: commonConstants.FOLDER_SHARE,
            groupId: groupId.split(TREE_KEY_SEPARATOR)[1],
        });
        this.showContextMenu();
    }

    removeGroup() {
        const {groupId, parentGroupId, parentResponseDataType, organizationId} = this.state.contextMenu;
        const {id, addPopup} = this.props;
        let confirmPopup;
        switch(id) {
            case 'CONTENT_SCHEDULE_BY_GROUP':
            case 'MESSAGE_SCHEDULE_BY_GROUP':
            case 'EVENT_SCHEDULE_BY_GROUP':
                confirmPopup = this.removeContentScheduleGroup(groupId, parentGroupId, parentResponseDataType, organizationId);
                break;
            case 'MY_CONTENT':
            case 'SHARED_CONTENT':
            case 'CONTENT_TEMPLATE_BY_FOLDER':
                confirmPopup = this.removeContentGroup(groupId, parentGroupId, parentResponseDataType, organizationId);
                break;
            case 'DEVICE_BY_GROUP':
                confirmPopup = this.removeDeviceGroup(groupId, parentGroupId, parentResponseDataType, organizationId);
                break;
            case 'MY_PLAYLIST':
                confirmPopup = this.removePlaylistGroup(groupId, parentGroupId, parentResponseDataType, organizationId);
                break;
            case 'RULESET_BY_GROUP':
                confirmPopup = this.removeRulesetGroup(groupId, parentGroupId, parentResponseDataType, organizationId);
                break;
            case 'USER_BY_GROUP':
                confirmPopup = this.removeUserGroup(groupId, parentGroupId, parentResponseDataType, organizationId);
                break;
            default:
                break;
        }

        if(confirmPopup) {
            addPopup(confirmPopup);
        }
        this.showContextMenu();
    }

    changeGroup() {
        const {groupId, parentGroupId, groupName} = this.state.contextMenu;
        const {id} = this.props;

        let currentGroup = this.targetGroup;
        while (!currentGroup.classList.contains('rc-tree-node-content-wrapper')) {
            currentGroup = currentGroup.parentNode;
        }
        currentGroup = currentGroup.parentNode;

        const element = {
            type: 'input',
            props: {
                id: 'temp_li',
                type: 'text',
                className: 'tempGroupName',
                //value: id === 'DEVICE_BY_GROUP' ? get(groupName, 'props.children[0]') : (id === 'USER_BY_GROUP' && this.targetGroup.parentNode !== undefined ? this.targetGroup.parentNode.title : groupName),
                value: get(groupName, 'props.children[0]') || groupName,
                maxLength: MAX_GROUP_NAME_LENGTH,
                onKeydown: (e)=>this.handleKeyDownForChange(e, currentGroup),
                onblur: (e)=>this.updateGroupBlur(e, currentGroup)
            }
        }

        this.renderEleInDom(element, currentGroup, false);
        ReactDOM.findDOMNode(currentGroup).querySelector('.tempGroupName').focus();
        const maxLengthGroupName = get(groupName, 'props.children[0]') !== undefined ? get(groupName, 'props.children[0]').length : groupName.length;
        ReactDOM.findDOMNode(currentGroup).querySelector('.tempGroupName').setSelectionRange(0, maxLengthGroupName);

        const currentGroupSpan = currentGroup.querySelector('.rc-tree-title');
        if (currentGroupSpan !== null) {
            currentGroupSpan.setAttribute('style', 'display: none');
        }
        this.showContextMenu();
    }

    setLeaf(treeData, curKey, level) {
        const loopLeaf = (data, lev) => {
            const l = lev - 1;
            data.forEach((item) => {
                if ((item.key.length > curKey.length) ? item.key.indexOf(curKey) !== 0 :
                    curKey.indexOf(item.key) !== 0) {
                    return;
                }
                if (item.children) {
                    loopLeaf(item.children, l);
                } else if (l < 1) {
                    item.isLeaf = true;
                }
            });
        };
        loopLeaf(treeData, level + 1);
    }

    getNewTreeData(treeData, curKey, child, level) {
        const loop = (data) => {
            data.map((item) => {
                if (curKey == (item.responseDataType + TREE_KEY_SEPARATOR + item.key + TREE_KEY_SEPARATOR + item.organizationId)) {
                    item.children = child;
                } else if (item.children) {
                    loop(item.children);
                }
            });
        };
        loop(treeData);
        this.setLeaf(treeData, curKey, level);
    }

    generateTreeNodes(groups) {
        let nodes = [];
        if(groups !== undefined) {
            groups.map( group => {
                nodes.push({...group, key: group.groupId, name: group.groupName})
            })
        }
        return nodes;
    }

    generateTreeNodesUser(groups) {
        let nodes = [];
        groups.map(
            group => {

                if (group.responseDataType !== 'PLAYLIST_USER'){
                    nodes.push({...group, key: group.groupId, name: group.groupName})
                } else {
                    nodes.push({...group, key: group.userId, name: group.userId})
                }
            })
        return nodes;
    }

    generateTreeNodesContentUser(groups) {
        let nodes = [];
        groups.map(
            group => {
                if (group.responseDataType !== 'CONTENT_USER'){
                    nodes.push({...group, key: group.groupId, name: group.groupName})
                } else {
                    nodes.push({...group, key: group.userId, name: group.userId})
                }
            })
        return nodes;
    }

    componentDidMount() {
        const {group, id, submenuId} = this.props;
        let expandedKeys = [], selectedKeys = [];
        if (isCacheTable(submenuId)) {
            const cache = getCache(submenuId);
            if (cache !== undefined && cache.groups !== undefined && cache.groups[id] !== undefined) {
                expandedKeys = cache.groups[id].expandedKeys;
                selectedKeys = cache.groups[id].selectedKeys;
            }
        }
        this.setState({
            treeData: group,
            expandedKeys : expandedKeys || [],
            selectedKeys: [selectedKeys]
        });
        this.ps[id] = new PerfectScrollbar(`#groupTree_${id}`, {
            wheelSpeed: 2,
            useBothWheelAxes: false,
            suppressScrollY: true,
        });
    }

    onLoadData = (treeNode) => {
        const {submenuId, id} = this.props;
        return new Promise((resolve) => {
            if (id === 'TAG_BY_GROUP' || id === 'INSIGHT_INDEX_BY_GROUP') {
                resolve()
            }

            const dataType = treeNode.props.eventKey.split(TREE_KEY_SEPARATOR)[0];
            const groupId = treeNode.props.eventKey.split(TREE_KEY_SEPARATOR)[1];
            const curKey = treeNode.props.eventKey;
            const treeData = [...this.state.treeData];

            if (submenuId === 'RULESET') {
                rulesetService.fetchRulesetGroupsById(groupId).then(
                    res => {
                        this.getNewTreeData(treeData, curKey, this.generateTreeNodes(res.items), 5);
                        this.setState({treeData});
                        resolve();
                    }
                );
            } else if (submenuId === 'CONTENT') {
                let params = {};
                switch(id) {
                    case 'MY_CONTENT':
                        resolve();
                        break;
                    case 'SHARED_CONTENT':
                        resolve();
                        return;
                    case 'BY_USER':
                        const {userId, organizationId, depth} = treeNode.props;
                        let promise;
                        switch(dataType) {
                            case 'CONTENT_ORGANIZATION':
                                promise = contentService.fetchUserIdByOrganizationId(organizationId);
                                break;
                            case 'CONTENT_USER':
                                promise = contentService.fetchFolderListByUserIdForByUser(userId, organizationId);
                                break;
                            case 'CONTENT_GROUP':
                                if(depth > 0) {
                                    promise = contentService.fetchGroupById(treeNode.props.groupId, {groupType: contentConstants.MY_CONTENT_GROUP});
                                }
                            default:
                                break;
                        }
                        if(promise){
                            return promise.then(res => {
                                    if (res.items !== undefined && res.items.length > 0) {
                                        this.getNewTreeData(treeData, curKey, this.generateTreeNodesContentUser(res.items), 5);
                                        this.setState({treeData});
                                    }
                                    resolve();
                                }
                            )
                        }

                        break;
                    case 'CONTENT_TEMPLATE_BY_FOLDER':
                        params = {
                            groupType : contentConstants.TEMPLATE_GROUP
                        };
                        contentService.fetchGroupById(groupId, params).then(
                            res => {
                                this.getNewTreeData(treeData, curKey, this.generateTreeNodes(res.items), 5);
                                this.setState({treeData});
                                resolve();
                            }
                        )
                        break;
                    default:
                        break;
                }
            } else if (submenuId === 'PLAYLIST') {
                switch (id) {
                    case 'MY_PLAYLIST':
                        resolve();
                        break;
                    case 'PLAYLIST_BY_USER':
                        const {userId, organizationId, dataType, depth} = treeNode.props;
                        let promise;
                        switch(dataType) {
                            case 'PLAYLIST_USER':
                                promise = playlistService.fetchPlaylistGroupsByUserIdForByUser(userId, organizationId);
                                break;
                            case 'PLAYLIST_GROUP':
                                if(depth > 0){
                                    promise =playlistService.fetchPlaylistGroups({groupId: groupId, groupType: 'SUB_GROUP'});
                                }
                                break;
                            default:
                                promise =playlistService.fetchPlaylistUsersByOrganizationId(organizationId);
                                break;
                        }
                        if(promise){
                            return promise.then(res => {
                                    if (res.items !== undefined && res.items.length > 0) {
                                        this.getNewTreeData(treeData, curKey, this.generateTreeNodesUser(res.items), 5);
                                        this.setState({treeData});
                                    }
                                    resolve();
                                }
                            )
                        }
                        break;
                }
            }
            else if (submenuId === 'SCHEDULE') {
                switch (id) {
                    case 'CONTENT_SCHEDULE_BY_GROUP':
                        scheduleService.fetchContentScheduleGroupById(groupId).then(
                            res => {
                                this.getNewTreeData(treeData, curKey, this.generateTreeNodes(res.items), 5);
                                this.setState({treeData});
                                resolve();
                            }
                        )
                        break;
                    case 'MESSAGE_SCHEDULE_BY_GROUP':
                        scheduleService.fetchMessageScheduleGroupById(groupId).then(
                            res => {
                                this.getNewTreeData(treeData, curKey, this.generateTreeNodes(res.items), 5);
                                this.setState({treeData});
                                resolve();
                            }
                        )
                        break;
                    case 'EVENT_SCHEDULE_BY_GROUP':
                        scheduleService.fetchEventScheduleGroupById(groupId).then(
                            res => {
                                this.getNewTreeData(treeData, curKey, this.generateTreeNodes(res.items), 5);
                                this.setState({treeData});
                                resolve();
                            }
                        )
                        break;
                }

            }
            else if (submenuId === 'USER') {
                switch (id) {
                    case 'USER_BY_GROUP':
                        if (groupId !== undefined && groupId !== '0' && groupId !== "0") {
                            userService.fetchUserSubGroupById(groupId).then(
                                res => {
                                    this.getNewTreeData(treeData, curKey, this.generateTreeNodes(res.items), 5);
                                    this.setState({treeData});
                                    resolve();
                                }
                            )
                        } else {
                            resolve();
                        }
                        break;
                }

            }
        }).finally(
            this.setState({
                loadedKeys: this.state.loadedKeys.concat(treeNode.props.eventKey)
            })
        )
    }

    selectTreeNode(groupIds, event) {
        event.nativeEvent.stopPropagation();
        this.showContextMenu();

        let eventGroupId = (!isNil(event.node.props.eventKey)?event.node.props.eventKey.toString():undefined);
        let selectedTreeKey = groupIds[0] || eventGroupId;

        let expanded = this.state.expandedKeys ? [...this.state.expandedKeys] : [];
        if(expanded.indexOf(selectedTreeKey) >= 0){
            expanded = expanded.filter(key =>  key != selectedTreeKey);
        }else{
            expanded = expanded.concat(selectedTreeKey);
        }

        this.updateExpandedKeys(expanded, selectedTreeKey);

        this.setState({
            selectedKeys: [selectedTreeKey],
            expandedKeys: expanded
        }, ()=> {
            const {submenuId, id} = this.props;
            const {activeNodeGroup} = this.props;
            const {organizationId, dataType}  = event.node.props;
            let userId = event.node.props.userId;
            let groupId = selectedTreeKey.split(TREE_KEY_SEPARATOR)[1] || selectedTreeKey;
            let isActiveNodeGroup = true;
            switch(dataType) {
                case 'PLAYLIST_GROUP':
                    userId = "";
                    break;
                case 'PLAYLIST_USER':
                    groupId = "";
                    break;
                case 'PLAYLIST_ORGANIZATION':
                    userId = "";
                    groupId = "";
                    break;
                case 'CONTENT_ORGANIZATION':
                    userId = "";
                    groupId = "";
                    isActiveNodeGroup = false;
                    break;
                case 'CONTENT_USER':
                    groupId = "";
                    break;
                case 'CONTENT_GROUP':
                    userId = "";
                    break;
                default:
                    break;
            }
            if (isActiveNodeGroup) {
                activeNodeGroup(submenuId, id, groupId, userId, organizationId);
            }
        });

    }

    onExpand(key, event) {
        this.updateExpandedKeys(key);
        this.showContextMenu();
        this.setState({
            expandedKeys: key
        })
        this.updateExpandedKeys(key);

        const {updateScrollbarPosition} = this.props;
        if (updateScrollbarPosition) {
            updateScrollbarPosition();
        }
    }

    onDrop = (info) => {
        const {t, addPopup, closePopup, submenuId, id} = this.props;
        let draggable = false;
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const {treeData} = this.state;
        const dragGroupId = dragKey.split(TREE_KEY_SEPARATOR)[1] || dragKey;
        const dropGroupId = dropKey.split(TREE_KEY_SEPARATOR)[1] || dropKey;
        const targetNode = findNode(treeData, dragGroupId);
        const destinationNode = findNode(treeData, dropGroupId);

        // TODO DRAG & DROP 대상 체크
        switch(submenuId) {
            case 'SCHEDULE' :
                if(targetNode.parentGroupId === undefined || targetNode.parentGroupId === 0){
                    toastr.error(t("MESSAGE_DEVICE_NOT_MOVE_ORG_P"));
                    return;
                }
                draggable = true;
                break;
            case 'PLAYLIST' :
                if(id === 'MY_PLAYLIST'){
                    if( targetNode.groupName === 'default' && targetNode.parentGroupId === -1){
                        return;
                    }
                    draggable = true;
                }
                break;
            case 'DEVICE' :
                if(id === 'DEVICE_BY_GROUP'){
                    if( targetNode.groupName === 'default' && targetNode.parentGroupId === -1){
                        toastr.error(t("MESSAGE_DEVICE_NOT_MOVE_ORG_P"));
                        return;
                    }
                    draggable = true;
                }
                break;
            case 'RULESET':
                if(targetNode.parentGroupId === undefined || targetNode.parentGroupId === 0){
                    toastr.error(t("MESSAGE_DEVICE_NOT_MOVE_ORG_P"));
                    return;
                }
                draggable = true;
                break;
            case 'CONTENT':
                if(id === 'MY_CONTENT'){
                    if( targetNode.groupName === 'default' && targetNode.parentGroupId === -1){
                        return;
                    }
                    draggable = true;
                }else if(id === 'CONTENT_TEMPLATE_BY_FOLDER'){
                    if( targetNode.groupName === 'default' && targetNode.parentGroupId === -1){
                        return;
                    }
                    draggable = true;
                }
                break;
            case 'USER':
                if(id === 'USER_BY_GROUP'){
                    if(targetNode.parentGroupId === undefined || targetNode.parentGroupId === 0){
                        toastr.error(t("MESSAGE_DEVICE_NOT_MOVE_ORG_P"));
                        return;
                    }

                    if (targetNode.organizationId !== destinationNode.organizationId) {
                        toastr.error(t("MIS_MESSAGE_COMMON_MOVE_INCORRECT_P"));
                        return;
                    }
                    draggable = true;
                }
                break;
            case 'USER':
                if(id === 'USER_BY_GROUP'){
                    if(targetNode.parentGroupId === undefined || targetNode.parentGroupId === 0){
                        toastr.error(t("MESSAGE_DEVICE_NOT_MOVE_ORG_P"));
                        return;
                    }
                    draggable = true;
                }
                break;
            default :
                break;
        }

        if(!draggable){
            return;
        }

        addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            width: 300,
            height: 100,
            title: t("TEXT_MOVE_GROUP_P"),
            message: t("COM_SID_ARE_YOU_SURE_WANT_TO_CONTINUE"),
            onClickYes: () => {
                this.moveGroup(dragKey, dropKey);
            },
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP)
        })
    }

    onRightClick = (info) => {
        //TODO split groupId
        const loadGroup = () => {
            const {submenuId, id} = this.props;
            const {activeNodeGroup} = this.props;
            const {organizationId, dataType}  = info.node.props;
            let userId = info.node.props.userId;
            let groupId = (!isNil(info.node.props.eventKey)?info.node.props.eventKey.toString():undefined);
            let targetGroupId = groupId.split(TREE_KEY_SEPARATOR)[1] || groupId;
            switch(dataType) {
                case 'PLAYLIST_GROUP':
                    userId = "";
                    break;
                case 'PLAYLIST_USER':
                    groupId = "";
                    userId = targetGroupId;
                    targetGroupId = '';
                    break;
                case 'PLAYLIST_ORGANIZATION':
                    groupId = "";
                    userId = targetGroupId;
                    targetGroupId = '';
                    break;
                case 'CONTENT_ORGANIZATION':
                    userId = "";
                    groupId = "";
                    break;
                case 'CONTENT_USER':
                    groupId = "";
                    break;
                case 'CONTENT_GROUP':
                    userId = "";
                    break;
                default:
                    break;
            }
            activeNodeGroup(submenuId, id, targetGroupId, userId, organizationId );
        }
        loadGroup();

        const {id} = this.props;
        const {top, left} = info.event.target.getBoundingClientRect();
        this.targetGroup = info.event.target;

        const {eventKey, parentGroupId, title, name, depth, dataType, resourceCount, resourceCountIncludeSubGroup, organizationId} = info.node.props;
        const contextEnable = this.contextMenus[id] == undefined ? false : true

        let rc = resourceCount;
        if(id !== 'DEVICE_BY_GROUP') {
            rc = resourceCountIncludeSubGroup;
        }

        this.setState({ 
            selectedKeys: [eventKey],
            expandedKeys: this.state.expandedKeys.includes(eventKey) ? this.state.expandedKeys : this.state.expandedKeys.concat(eventKey),
            contextMenu: {
                show: contextEnable, 
                position: {x: info.event.clientX, y: top+24}, 
                groupId: eventKey, 
                parentGroupId: String(parentGroupId), 
                groupName: name || title, 
                groupDepth: depth,
                resourceCount: rc,
                parentResponseDataType : dataType,
                organizationId: organizationId
            }
        });
    }

    onMouseEnter = ({event, node}) => {
        const {id} = this.props;
        const contextEnable = this.contextMenus[id] == undefined ? false : true
        if(contextEnable) {
            const hoverTarget = this.findParentLiNode(event.target);
            hoverTarget.classList.add('menuHover');
            const targetNode = this.findWrapNode(event.target);
            if (targetNode.querySelector('.menu') === null) {
                const {eventKey, parentGroupId, title, depth, dataType} = node.props;
                let resourceCount = node.props.resourceCount;
                if(resourceCount === undefined) {
                    resourceCount = title.props !== undefined && title.props.children !== undefined && title.props.children.length > 0 && title.props.children[1].props !== undefined && title.props.children[1].props.children || 0;
                }                
                const element = {
                    type: 'span',
                    props: {
                        className: 'menu',
                        onClick: (e) => this.showContextMenuForButton(e, {
                            groupId: eventKey,
                            parentGroupId: parentGroupId,
                            title: title,
                            depth: depth,
                            resourceCount: resourceCount,
                            responseDataType : dataType
                        })
                    },
                }
                this.renderEleInDom(element, targetNode);
            }
        }
    }

    showContextMenuForButton = (e, group, target) => {
        e.stopPropagation();

        let groupId = group.groupId.split(TREE_KEY_SEPARATOR)[1];
        if (groupId === undefined) {
           groupId = parseInt(group.groupId);
        }
        const node = findNode(this.state.treeData, groupId);
        this.targetGroup = e.target;
        const {activeNodeGroup} = this.props;
        const {top, left} = e.target.getBoundingClientRect();
        const eventKey = group.groupId;
        this.setState({
            selectedKeys: [eventKey],
            expandedKeys: this.state.expandedKeys.includes(eventKey) ? this.state.expandedKeys : this.state.expandedKeys.concat(eventKey),
            contextMenu: {
                show: true,
                position: {x: left, y: top},
                groupId: group.groupId,
                parentGroupId: String(group.parentGroupId),
                groupName: node.groupName || group.title,
                groupDepth: group.depth,
                resourceCount: group.resourceCount,
                parentResponseDataType: group.responseDataType
            }
        });
    }

    onMouseLeave = ({event, node}) =>  {
        const hoverTarget = this.findParentLiNode(event.target);
        hoverTarget.classList.remove('menuHover');
    }

    findWrapNode = (target) => {
        let node = target;
        let isWrapNode = node.classList.contains('rc-tree-node-content-wrapper');
        if (!isWrapNode) {
            node = node.parentElement;
            isWrapNode = node.classList.contains('rc-tree-node-content-wrapper');
            if (!isWrapNode) {
                node = node.parentElement;
            }
        }
        return node;
    }

    findParentLiNode = (target) => {
        let node = target.parentElement;
        while (node.nodeName !== 'LI') {
            node  = node.parentElement;
        }
        return node;
    }

    stopPropagation = (e) => {
        e.stopPropagation();
    }

    showContextMenu() {
        this.setState({
            contextMenu: {
                ...this.state.contextMenu,
                show: false,
                position: {}}
        })
    }

    setTreeRef = tree => {
        this.tree = tree;
    };

    validGroupId = (id) => {
        switch (id) {
            case 'RULESET_BY_GROUP':
            case 'MY_CONTENT':
            case 'SHARED_CONTENT':
            case 'CONTENT_TEMPLATE_BY_FOLDER':
            case 'BY_USER':
            case 'MY_PLAYLIST':
            case 'PLAYLIST_BY_USER':
            case 'CONTENT_SCHEDULE_BY_GROUP':
            case 'MESSAGE_SCHEDULE_BY_GROUP':
            case 'EVENT_SCHEDULE_BY_GROUP':
            case 'DEVICE_BY_GROUP' :
            case 'ORGANIZATION_GROUP':
            case 'USER_BY_GROUP':
            case 'TAG_BY_GROUP':
            case 'INSIGHT_INDEX_BY_GROUP':
              return true;
        }
        return false;
    }

    contextMenuAuthority = (id) => {
        let authority ;
        switch (id) {
            case 'MY_CONTENT':
                authority = this.getAuthority('CONTENT_PLAYLIST_RULESET');
                return authority.CREATE || authority.MANAGE;
                break;
            case 'MY_PLAYLIST':
                authority = this.getAuthority('CONTENT_PLAYLIST_RULESET');
                return authority.CREATE || authority.MANAGE;
                break;
            case 'RULESET_BY_GROUP':
                authority = this.getAuthority('CONTENT_PLAYLIST_RULESET');
                return authority.CREATE || authority.MANAGE;
            case 'DEVICE_BY_GROUP':
                authority = this.getAuthority('DEVICE');
                return authority.CONTROL || authority.CREATE || authority.MANAGE || authority.MOVE || authority.DELETE;
                break;
            case "CONTENT_SCHEDULE_BY_GROUP":
                authority = this.getAuthority('SCHEDULE');
                return authority.CREATE || authority.MANAGE;
                break;
            case "USER_BY_GROUP":
                authority = this.getAuthority('USER');
                return authority.CREATE || authority.MANAGE;
                break;
            default :
                return true;
        }
        return true;
    }

    isOrganization = (id) => {
        const {treeData} = this.state;
        const groupId = parseInt(id, 10);
        if (treeData !== undefined && treeData.length > 0) {
            const found = treeData.find(g => g.groupId === groupId);
            if (found !== undefined) {
                return true;
            }
        }
        return false;
    }

    onWheel = (e) => {
        const container = document.querySelector('#submenu_scroll_wrap');
        if (e.deltaY > 0) {
            if (container) {
                container.scrollTop = container.scrollTop + 60;
            }
        } else if (e.deltaY < 0) {
            if (container) {
                container.scrollTop = container.scrollTop - 60;
            }
        }

    }

    render() {
        const {submenuId, id, reloadGroup, loadGroupWithExpandedKeys, submenuWidth, loadingGroup} = this.props;
        if (reloadGroup !== undefined && reloadGroup.id !== undefined) {
            if (id === reloadGroup.id) {
                const {loadGroup, destroyReloadGroup} = this.props;
                new Promise(()=> {
                    switch(id) {
                        case 'DEVICE_BY_GROUP':
                            loadGroup(submenuId, id, true);
                            break;
                        default:
                            loadGroupWithExpandedKeys(submenuId, id, true, this.state.expandedKeys);
                            break;
                    }
                }).finally(
                    destroyReloadGroup()
                );
            }
        }

        const loop = (data) => {

            return data.map((item) => {

                let checkable = true;
                if (item.groupName === 'default' && item.parentGroupId === -1) {
                    checkable = false;
                    item.isLeaf = true;
                }

                if(item.groupType === 'SHARED_GROUP'){
                    item.isLeaf = true;
                }

                if (item.children) {
                    return <TreeNode icon={Icon(item)}
                                     title = {Title(item)}
                                     key={item.responseDataType + TREE_KEY_SEPARATOR + item.key + TREE_KEY_SEPARATOR + item.organizationId} parentGroupId={item.parentGroupId} depth={item.groupDepth} groupName={item.groupName}
                                     userId ={item.userId} groupId={item.groupId} organizationId={item.organizationId}
                                     dataType={item.responseDataType} checkable = {checkable} resourceCountIncludeSubGroup={item.resourceCountIncludeSubGroup}
                                     className={item.isOrganization !== undefined && item.isOrganization ? 'organization' : ''}
                    >{loop(item.children)}</TreeNode>;
                }
                return (
                    <TreeNode icon={Icon(item)}
                              title={Title(item)}
                              key={item.responseDataType + TREE_KEY_SEPARATOR + item.key + TREE_KEY_SEPARATOR + item.organizationId} isLeaf={item.isLeaf} parentGroupId={item.parentGroupId} depth={item.groupDepth} groupName={item.groupName}
                              userId ={item.userId} groupId={item.groupId} organizationId={item.organizationId}
                              dataType={item.responseDataType} checkable = {checkable} resourceCountIncludeSubGroup={item.resourceCountIncludeSubGroup}
                              className={item.isOrganization !== undefined && item.isOrganization ? 'organization' : ''}
                    >
                    </TreeNode>
                );
            });
        };
        const treeNodes = loop(this.state.treeData);
        const {open, currentId} = this.props;
        const paddingBottom = open ? 10 : 0 ;

        return(
            <div className={"groupTree"} id={`groupTree_${id}`} onClick={(e)=>this.stopPropagation(e)} onWheel={this.onWheel} ref={c => (this.groupRef = c)} style={{width: submenuWidth-20, position: "relative", paddingBottom:paddingBottom}}>
                {/*{
                    loadingGroup !== undefined && loadingGroup.id === id && loadingGroup.loading === true && <div style={{display: 'flex', justifyContent: 'center'}}><Loading size={8} /></div>
                }*/}
                {
                    this.state.contextMenu.show &&
                    <ContextMenu position={this.state.contextMenu.position} close={()=>this.showContextMenu()} menu={checkContextMenu(this.contextMenus[id], id, this.state.contextMenu.groupDepth, this.state.contextMenu.resourceCount)}
                        groupCode={this.props.id === 'DEVICE_BY_GROUP' && this.state.contextMenu.groupDepth > 1 && this.state.contextMenu.groupId}
                        groupId={this.state.contextMenu.groupId}
                        authority={this.contextMenuAuthority(id)}
                    />
                }
                {
                    this.props.id === 'DEVICE_BY_GROUP' &&
                        <Tree
                            showIcon={true}
                            style={{'display': open ? 'block' : 'none'}}
                            onExpand={(key, event)=>this.onExpand(key, event)}
                            onSelect={(key, event)=>this.debouncedSelect(key, event)}
                            selectedKeys={this.validGroupId(currentId) ? this.state.selectedKeys : []}
                            selectable
                            draggable
                            treeData={this.state.treeData}
                            expandedKeys={this.state.expandedKeys}
                            defaultCheckedKeys={this.state.defaultCheckedKeys}
                            onRightClick={this.onRightClick}
                            onDrop={this.onDrop}
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        />
                }
                {
                    this.props.id !== 'DEVICE_BY_GROUP' &&
                        <Tree
                            ref={this.setTreeRef}
                            style={{'display': open ? 'block' : 'none'}}
                            onCheck={this.onCheck}
                            loadData={this.onLoadData}
                            showIcon={true}
                            onRightClick={this.onRightClick}
                            onExpand={(key, event)=>this.onExpand(key, event)}
                            onSelect={(key, event)=>this.selectTreeNode(key, event)}
                            draggable
                            onDrop={this.onDrop}

                            expandedKeys={this.state.expandedKeys}
                            /*defaultExpandedKeys={this.state.expandedKeys}*/

                            selectedKeys={this.validGroupId(currentId) ? this.state.selectedKeys : []}
                            loadedKeys={this.state.loadedKeys}

                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                        >
                            {treeNodes}
                        </Tree>
                }
            </div>
        )
    }

}
export default connect(
    state => ({
        currentId : state.menu.currentContentId,
        reloadGroup: state.menu.reloadGroup,
        selectGroup: state.menu.selectGroup,
        submenuWidth: state.menu.submenuWidth,
        loadingGroup: state.menu.loadingGroup
    }),
    dispatch => ({
        activeNodeGroup: (submenuId, nodeId, groupId, userId, organizationId)=> dispatch(menuAction.activeNodeGroup(submenuId, nodeId, groupId, userId, organizationId)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        loadGroup: (submenuId, nodeId, noContentUpdate) => dispatch(menuAction.loadGroup(submenuId, nodeId, noContentUpdate)),
        reloadGroups: (submenuId, groupId) => dispatch(menuAction.reloadGroup(submenuId, groupId)),
        destroyReloadGroup: () => dispatch(menuAction.destroyReloadGroup()),
        loadGroupWithExpandedKeys: (submenuId, nodeId, noContentUpdate, expandedKeys) => dispatch(menuAction.loadGroupWithExpandedKeys(submenuId, nodeId, noContentUpdate, expandedKeys)),
        requestGroup : (id, dom) => dispatch(menuAction.requestGroup(id, dom))
    })

)(withTranslation()(GroupContainer));