import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toLower} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import {deviceOptions} from '../../../misopt';
import Checkbox from '../../../checkbox/Checkbox';
import Select from '../../../selectbox/Select';
import TextInput from '../../../input/TextInput';
import NotiIcon from '../../../../images/icon/icon_notification.png';
import OkIcon from '../../../../images/icon/icon_ok.png';
import CancelIcon from '../../../../images/icon/icon_cancel.png';
import WhiteButton from '../../../button/WhiteButton';
import {deviceService} from '../../../../services';

const getServerUrl = (protocol, address, port, path) => {
    return `${toLower(protocol)}://${address}${port ? ':' + port : ''}${path}`;
};

const ServerStatus = ({status}) => {
    return (
        status === 'init' ? <img src={NotiIcon} /> :
        status === 'success' ? <img src={OkIcon} /> : <img src={CancelIcon} />
    );
};

const getAddress = (address, port) => {
    return port ? `${address}:${port}` : address;
};

const EditPresetService = ({serverInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {popUpload, screenCapture, firmwareDownload, contentsDownload} = {}, changed} = serverInfo || {}
    const [serverStatus, setServerStatus] = useState(Array(4).fill('init'));

    const [popProtocol = '', popAddress = '', popPort = '', popPath = ''] = (popUpload || '').split(';');
    const [screenCaptureProtocol = '', screenCaptureAddress = '', screenCapturePort = '', screenCapturePath = ''] = (screenCapture || '').split(';');
    const [firmwareDownloadProtocol = '', firmwareDownloadAddress = '', firmwareDownloadPort = '', firmwareDownloadPath = ''] = (firmwareDownload || '').split(';');
    const [contentsDownloadProtocol = '', contentsDownloadAddress = '', contentsDownloadPort = '', contentsDownloadPath = ''] = (contentsDownload || '').split(';');
    const usePopUpload = false, useCaptureUpload = false;

    const [address, setAddress] = useState([
        getAddress(popAddress, popPort),
        getAddress(screenCaptureAddress, screenCapturePort),
        getAddress(firmwareDownloadAddress, firmwareDownloadPort),
        getAddress(contentsDownloadAddress, contentsDownloadPort),
    ]);

    const onChangePopUpload = index => (e, value) => {
        if(index === 0) {
            onChange(e, `${value};${popAddress};${popPort};${popPath}`);
        } else if(index === 1) {
            const newAddress = [...address];
            newAddress[0] = e.target.value;
            setAddress(newAddress);
            onChange(e, `${popProtocol};${e.target.value.replace(':', ';')};${popPath}`);
        } else if(index === 2) {
            onChange(e, `${popProtocol};${popAddress};${popPort};${e.target.value}`);
        }
    };

    const onChangeScreenCapture = index => (e, value) => {
        if(index === 0) {
            onChange(e, `${value};${screenCaptureAddress};${screenCapturePort};${screenCapturePath}`);
        } else if(index === 1) {
            const newAddress = [...address];
            newAddress[1] = e.target.value;
            setAddress(newAddress);
            onChange(e, `${screenCaptureProtocol};${e.target.value.replace(':', ';')};${screenCapturePath}`);
        } else if(index === 2) {
            onChange(e, `${screenCaptureProtocol};${screenCaptureAddress};${screenCapturePort};${e.target.value}`);
        }
    };

    const onChangeFirmwareDownload = index => (e, value) => {
        if(index === 0) {
            onChange(e, `${value};${firmwareDownloadAddress};${firmwareDownloadPort};${firmwareDownloadPath}`);
        } else if(index === 1) {
            const newAddress = [...address];
            newAddress[2] = e.target.value;
            setAddress(newAddress);
            onChange(e, `${firmwareDownloadProtocol};${e.target.value.replace(':', ';')};${firmwareDownloadPath}`);
        } else if(index === 2) {
            onChange(e, `${firmwareDownloadProtocol};${firmwareDownloadAddress};${firmwareDownloadPort};${e.target.value}`);
        }
    };

    const onChangeContentsDownload = index => (e, value) => {
        if(index === 0) {
            onChange(e, `${value};${contentsDownloadAddress};${contentsDownloadPort};${contentsDownloadPath}`);
        } else if(index === 1) {
            const newAddress = [...address];
            newAddress[3] = e.target.value;
            setAddress(newAddress);
            onChange(e, `${contentsDownloadProtocol};${e.target.value.replace(':', ';')};${contentsDownloadPath}`);
        } else if(index === 2) {
            onChange(e, `${contentsDownloadProtocol};${contentsDownloadAddress};${contentsDownloadPort};${e.target.value}`);
        }
    };

    const onCheckServer = index => e => {
        let serverUrl;
        if(index === 0) {
            serverUrl = getServerUrl(popProtocol, popAddress, popPort, popPath);
        } else if(index === 1) {
            serverUrl = getServerUrl(screenCaptureProtocol, screenCaptureAddress, screenCapturePort, screenCapturePath);
        } else if(index === 2) {
            serverUrl = getServerUrl(firmwareDownloadProtocol, firmwareDownloadAddress, firmwareDownloadPort, firmwareDownloadPath);
        } else if(index === 3) {
            serverUrl = getServerUrl(contentsDownloadProtocol, contentsDownloadAddress, contentsDownloadPort, contentsDownloadPath);
        }

        if(serverUrl) {
            deviceService.fetchServerStatus(serverUrl).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                const newServerStatus = [...serverStatus];
                newServerStatus[index] = 'success';
                setServerStatus(newServerStatus);
            }).catch(error => {
                const newServerStatus = [...serverStatus];
                newServerStatus[index] = 'fail';
                setServerStatus(newServerStatus);
            });
        }
    }

    return (
        <div className='device_edit_panel_wrap mt28'>
            <div className="devicepop setup mb26">
                <h3>{t('COM_SID_SERVICE')}</h3>
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width="105px"/>
                            <col width="165px"/>
                            <col width="165px"/>
                            <col />
                        </colgroup>
                        <tbody>
                            {   
                                usePopUpload &&
                                <tr>
                                    <th>
                                        <Checkbox id='popUploadEnable' name={t('POP_UPLOAD')} checked={changed['popUpload'] || false}
                                            propertyName='popUpload' onChange={onChangeChecked} />
                                    </th>
                                    <td>
                                        <Select width={100} propertyName='popUpload' selects={deviceOptions.httpOptions} value={popProtocol} onChange={onChangePopUpload(0)} disabled={!changed['popUpload']} multiLang={false} />
                                    </td>
                                    <td>
                                        <TextInput width={160} propertyName='popUpload' value={address[0]} onChange={onChangePopUpload(1)} disabled={!changed['popUpload']} placeholder='127.0.0.1:80' />
                                    </td>
                                    <td>
                                        <TextInput width={160} propertyName='popUpload' value={popPath} onChange={onChangePopUpload(2)} disabled={!changed['popUpload']} placeholder='/MagicInfo' />
                                    </td>
                                    <td>
                                        <ServerStatus status={serverStatus[0]} />
                                        <WhiteButton width={40} id='popUploadCheck' name={t('MIS_TEXT_CHECK_P')} onClick={onCheckServer(0)} disable={!changed['popUpload'] || false} />
                                    </td>
                                </tr>
                            }
                            {
                                useCaptureUpload && 
                                <tr>
                                    <th>
                                        <Checkbox id='screenCaptureEnable' name={t('SCREEN_CAPTURE_UPLOAD')} checked={changed['screenCapture'] || false}
                                            propertyName='screenCapture' onChange={onChangeChecked} />
                                    </th>
                                    <td>
                                        <Select width={100} propertyName='screenCapture' selects={deviceOptions.httpOptions} value={screenCaptureProtocol} onChange={onChangeScreenCapture(0)} disabled={!changed['screenCapture']} multiLang={false} />
                                    </td>
                                    <td>
                                        <TextInput width={160} propertyName='screenCapture' value={address[1]} onChange={onChangeScreenCapture(1)} disabled={!changed['screenCapture']} placeholder='127.0.0.1:80' />
                                    </td>
                                    <td>
                                        <TextInput width={160} propertyName='screenCapture' value={screenCapturePath} onChange={onChangeScreenCapture(2)} disabled={!changed['screenCapture']} placeholder='/MagicInfo' />
                                    </td>
                                    <td>
                                        <ServerStatus status={serverStatus[1]} />
                                        <WhiteButton width={40} id='screenCaptureCheck' name={t('MIS_TEXT_CHECK_P')} onClick={onCheckServer(1)} disable={!changed['screenCapture'] || false} />
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>
                                    <Checkbox id='firmwareDownloadEnable' name={t('MIS_SID_CBFEB_FIRMWARE_DOWNLOAD')} checked={changed['firmwareDownload'] || false}
                                        propertyName='firmwareDownload' onChange={onChangeChecked} />
                                </th>
                                <td>
                                    <Select width={100} propertyName='firmwareDownload' selects={deviceOptions.httpOptions} value={firmwareDownloadProtocol} onChange={onChangeFirmwareDownload(0)} disabled={!changed['firmwareDownload']} multiLang={false} />
                                </td>
                                <td>
                                    <TextInput width={160} propertyName='firmwareDownload' value={address[2]} onChange={onChangeFirmwareDownload(1)} disabled={!changed['firmwareDownload']} placeholder='127.0.0.1:80' />
                                </td>
                                <td>
                                    <TextInput width={160} propertyName='firmwareDownload' value={firmwareDownloadPath} onChange={onChangeFirmwareDownload(2)} disabled={!changed['firmwareDownload']} placeholder='/MagicInfo' />
                                </td>
                                <td>
                                    <ServerStatus status={serverStatus[2]} />
                                    <WhiteButton width={40} id='firmwareDownloadCheck' name={t('MIS_TEXT_CHECK_P')} onClick={onCheckServer(2)} disable={!changed['firmwareDownload'] || false} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Checkbox id='contentsDownloadEnable' name={t('MIS_SID_CBFEB_CONTENTS_DOWNLOAD')} checked={changed['contentsDownload'] || false}
                                        propertyName='contentsDownload' onChange={onChangeChecked} />
                                </th>
                                <td>
                                    <Select width={100} propertyName='contentsDownload' selects={deviceOptions.httpOptions} value={contentsDownloadProtocol} onChange={onChangeContentsDownload(0)} disabled={!changed['contentsDownload']} multiLang={false} />
                                </td>
                                <td>
                                    <TextInput width={160} propertyName='contentsDownload' value={address[3]} onChange={onChangeContentsDownload(1)} disabled={!changed['contentsDownload']} placeholder='127.0.0.1:80' />
                                </td>
                                <td>
                                    <TextInput width={160} propertyName='contentsDownload' value={contentsDownloadPath} onChange={onChangeContentsDownload(2)} disabled={!changed['contentsDownload']} placeholder='/MagicInfo' />
                                </td>
                                <td>
                                    <ServerStatus status={serverStatus[3]} />
                                    <WhiteButton width={40} id='contentsDownloadCheck' name={t('MIS_TEXT_CHECK_P')} onClick={onCheckServer(3)} disable={!changed['contentsDownload'] || false} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EditPresetService;