import React from 'react';
import FLIP_1 from '../../../images/icon/FLIP1.0.png';
import FLIP_2 from '../../../images/icon/FLIP2.0.png';
import FLIP_3 from '../../../images/icon/FLIP3.0.png';
import REMOTE_OFF from '../../../images/icon/status_remote_off.png';


const getSrc= deviceVersion =>{
    
    if(deviceVersion < 2)
        return FLIP_1;
    else if(deviceVersion > 1 && deviceVersion < 3)
        return FLIP_2;
    else if(deviceVersion > 2 && deviceVersion < 4)
        return FLIP_3;

    return FLIP_1;

}

const Flip = ({deviceTypeVersion, text, disabled = false, showRemoteIcon}) => {

    let imgValue= getSrc(deviceTypeVersion);

    return(
        <span className={"device_icon"}>
            {
                <img src={imgValue} style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'FLIP'} data-deviceTypeVersion={deviceTypeVersion}/>
            }
            
            {text ?
                `Flip ${deviceTypeVersion > 1 ? deviceTypeVersion : ''}`
                : ''
            }
        </span>
    )
}
export default Flip