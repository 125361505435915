import React, {useEffect, useMemo, useState} from "react";
import {useMISOpt} from "../../components/misopt";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {
    getPageSize,
    toMomentDateTimeFormat,
    useCheckRefWithSelectedCnt as useCheckRef,
    useFilter,
    usePrevious
} from "../../helper";
import {settingService} from "../../services";
import {snakeCase} from "lodash";
import {updateCache} from "../../helper/cache/tableCache";
import Checkbox from "../../components/checkbox/Checkbox";
import Moment from "moment";
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {
    commonConstants,
    SCHEDULE_FILTERS,
    SETTING_FILTERS,
    SETTING_PAGE_SIZE_OPTIONS,
    settingConstants
} from "../../constants";
import {useTrGroupProps} from "../../helper/tables";
import {popupAction} from "../../actions";
import toastr from 'toastr';
import {getErrorMessage} from "../../helper/responseHandler";

const InsightIndexManagement = (props) => {

    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize,sorted} = filter;
    const [style, setStyle] = useState({
        height: '500px'
    });

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const preGroupId = usePrevious(props.groupId);

    const getCheckedId = () => {
        return selected.current.map(s => items[s].indexId);
    }

    const editTagPopup = (indexId) => {
        dispatch(popupAction.addPopup({
            type: settingConstants.EDIT_INSIGHT_INDEX_POPUP,
            id: settingConstants.EDIT_INSIGHT_INDEX_POPUP,
            indexId: indexId,
            title : t('MIS_SID_EDIT_INDEX'),
            onClickYes : ()=> {
                fetchData();
            },
            onClose: ()=>dispatch(popupAction.closePopup(settingConstants.EDIT_INSIGHT_INDEX_POPUP))
        }));
    }

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}]} = filter;
        setData({...data, loading: true});
        settingService.fetchInsightIndexes({
            startIndex: page*pageSize + 1,
            pageSize,
            organizationId: filter.groupId,
            sortColumn: snakeCase(id),
            sortOrder: desc === true ? 'desc' : 'asc',
            searchText: filter.searchText
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
            updateCache('SETTING', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent)
        }).catch(e => toastr.error(e))
    }

    const onChangeSearchBar = (searchText) => {
        setFilter({...filter, searchText: searchText});
    }

    const addInsightIndex = () => {
        dispatch(popupAction.addPopup({
            id: settingConstants.ADD_INSIGHT_INDEX_POPUP,
            type: settingConstants.ADD_INSIGHT_INDEX_POPUP,
            title: t("COM_BUTTON_CONFIRM"),
            message: t("COM_TV_SID_DO_YOU_WANT_TO_DELETE_THE_SELECTED_ITEMS_KR_SELECTED"),
            onClickYes: ()=> {
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(settingConstants.ADD_INSIGHT_INDEX_POPUP)),
        }));
    }

    const deleteInsightIndex = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_CONFIRM"),
            message: t("COM_TV_SID_DO_YOU_WANT_TO_DELETE_THE_SELECTED_ITEMS_KR_SELECTED"),
            onClickYes: handleDelete,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }
    const activateInsightIndex = (activate) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: activate ? t("COM_SID_ACTIVATE_KR_HOTEL") : t("COM_SID_PROSERVICE_CAJUN_DEACTIVATE"),
            message: activate ? t('MIS_SID_CBFEB_DO_WANT_ACTIVATE_SELECTED_INDEXES') : t('MIS_SID_CBFEB_DO_WANT_DEACTIVATE_SELECTED_INDEXES'),
            onClickYes: ()=>handleActivate(activate),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const handleDelete = () => {
        const insightIndexIds = getCheckedId();

        if(insightIndexIds.findIndex( item => item == settingConstants.INSIGHT_INDEX_PRODUCT_CODE ) >= 0){
            toastr.error(t("MIS_SID_CBFEB_PRODUCT_CODE_CANNOT_DELETED"));
            dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
            return
        }

        settingService.deleteInsightIndexes(insightIndexIds)
            .then(res => {
                if (res) {
                    toastr.success(t("ALERT_SUCCESS_DELETE"));
                }
            }).catch(e => toastr.error(getErrorMessage(e)))
            .finally(()=>{
                setFilter({...filter, force: !filter.force});
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
            })
    }

    const handleActivate = (activate) => {
        const insightIndexIds = getCheckedId();
        settingService.updateActivateInsightIndexes(activate, insightIndexIds)
            .then(res => {
                toastr.success(t("MIS_MESSAGE_STATISTICS_SAVE_P"));
            }).catch(e => toastr.error(getErrorMessage(e)))
            .finally(()=>{
                setFilter({...filter, force: !filter.force});
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
            })
    }

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    useEffect(() => {
        if (props.currContent == 'ALL_INSIGHT_INDEX')
            setFilter({...filter, groupId: undefined, page: 0});
        else {
            if (props.groupId !== undefined && filter.groupId != props.groupId) {
                setFilter({...filter, groupId: props.groupId, page: 0});
            } else if (props.groupId === undefined && filter.groupId !== undefined) {
                setFilter({...filter, groupId: filter.groupId, page: 0});
            }
        }
    }, [props.groupId, props.currContent]);

    useEffect(() => {
        setStyle({height: window.innerHeight - 202});
    }, []);

    const columns = useMemo(() => [
        {
            accessor: "indexId",
            show: false
        },
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'insight_index_all'}
                        classname={"table"}
                        name={"check"}
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: row=> {
                return (
                    <Checkbox
                        id={items[row.index].indexId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        ref={setCheckBoxRefs}
                        onChange={toggleRow}
                    />
                )
            },
            width : 46,
            sortable: false,
            style : {"textAlign": "center"},
            resizable : false
        },
        {
            Header: t("MIS_SID_INDEX_NAME"),
            accessor: "indexName",
            Cell: (props) => <span className={"data_name"} onClick={()=>editTagPopup(props.original.indexId)}>{props.original.indexName}</span>,
            width: 200
        },
        {
            Header: t("TEXT_ORGANIZATION_P"),
            accessor: "organizationName",
            sortable: false,
            width: 200
        },
        {
            Header: t("COM_LFD_VALUE"),
            accessor: "values",
            Cell : ({value}) => <span>{value !== undefined && value.map(v => v.value).join(',')}</span>,
            width: 250
        },
        {
            Header: t("TEXT_DESCRIPTION_P"),
            accessor: "description",
            width: 200
        },
        {
            Header: t("TEXT_STATUS_P"),
            accessor: "activated",
            width: 100,
            Cell: ({value}) => <span>{value ? t("COM_SID_ACTIVATE_KR_HOTEL") : t("COM_SID_PROSERVICE_CAJUN_DEACTIVATE")}</span>
        },
        {
            Header: t("TABLE_CREATE_DATE_P"),
            accessor: "lastModifiedDate",
            Cell: ({value}) => <span>{Moment(value).format(dateTimeFormat)}</span>,
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"ADD_INSIGHT_INDEX"} name={t("COM_BUTTON_ADD")} onClick={addInsightIndex}/>
                    <WhiteButton id={"DELETE_INSIGHT_INDEX"} name={t("COM_BUTTON_DELETE")}  disable={selectedCnt < 1} onClick={deleteInsightIndex} />
                    <WhiteButton id={"ACTIVATE_INSIGHT_INDEX"} name={t('COM_SID_ACTIVATE_KR_HOTEL')}  disable={selectedCnt !== 1} onClick={()=>activateInsightIndex(true)}/>
                    <WhiteButton id={"DEACTIVATE_INSIGHT_INDEX"} name={t('COM_SID_PROSERVICE_CAJUN_DEACTIVATE')}  disable={selectedCnt !== 1} onClick={()=>activateInsightIndex(false)}/>
                </div>
                <div className={"rightButton"}>
                    <SearchBar width={250} onClickSearch={onChangeSearchBar} placeholder={``} grayBorder={true}/>
                </div>
            </div>
            <MagicInfoTable
                data={items}
                loading={loading}
                sorted={sorted}
                getTrGroupProps={getTrGroupPropsType2}
                onSortedChange={onSortedChange}
                columns= {columns}
                style={style}
                usePagination={true}
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    defaultPageSize: 30,
                    pageSizeOptions: SETTING_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: 'SETTING'
                }}
            />
        </div>
    )
}
export default InsightIndexManagement;